import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { plus } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'
import MarriageCounselingTable from '../../../../components/table/MarriageCounseling'
import LoadingComponent from '../../../../components/loading/Index'

const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.MarriageCounseling.Index(header)
        if (data) {
            setItems(data.page_marriage)
            setLoading(false)
        }
        setLoading(false)
    }, [header])


    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    if (isLoading) return <LoadingComponent />

    return (
        <div className="marriage-index pb-4">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Marriage Counselling List</h6></div>
                                    <div className="ml-auto">
                                        {/* <Link
                                            to="/dashboard/website/marriage-counselling/store"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={plus} size={22} />
                                        </Link> */}
                                    </div>
                                </div>
                            </div>

                            {/* Data table component for show data */}
                            <div className="card-body p-0">
                                <MarriageCounselingTable items={items} refetch={fetchData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;