import React from 'react'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    return (
        <div className="ghost-messages">

            {[...Array(10).keys()].map((i) => {
                return (
                    <div className="d-flex mb-4" key={i}>
                        <div><Skeleton width={30} height={30} circle={true} /></div>
                        <div className="pl-3">
                            <Skeleton width={180} height={20} />
                            <br />
                            <Skeleton width={120} height={10} />
                            <br />

                            <Skeleton width={'50vw'} height={20} count={3} />
                        </div>
                    </div>
                )
            })}

        </div>
    );
}

export default Index;
