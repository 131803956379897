import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useHistory, useParams } from 'react-router-dom'
import { chevronLeft, plus } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

import LoadingComponent from '../../../../components/loading/Index'

const Edit = () => {
    const { id } = useParams()
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [item, setItem] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.ContactUs.Show(id, header)
        if (data) {
            setItem(data)
            setLoading(false)
        }
        setLoading(false)
    }, [id, header])

    useEffect(() => {
        fetchData()
    }, [id, header, fetchData])


    // Submit Form
    const onSubmit = async (data) => {
        try {
            setUpdate(true)
            await Requests.ContactUs.Update(id, data, header)
            setUpdate(false)
            history.push('/dashboard/website/contact-us')
        } catch (error) {
            if (error) console.log(error)
        }
    }

    if (isLoading) return <LoadingComponent />

    return (
        <div className="store-contact pb-4">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Edit contact</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/contact-us"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">

                                        {/* Address part 1 */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.address_part1 && errors.address_part1.message ? (
                                                    <p className="text-danger">{errors.address_part1 && errors.address_part1.message}</p>
                                                ) : <p>Address Part 1</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="address_part1"
                                                    defaultValue={item.address_part1}
                                                    className="form-control shadow-none"
                                                    placeholder="Enter address_part1"
                                                    ref={register({ required: "address_part1 is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* address_part2 */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.address_part2 && errors.address_part2.message ? (
                                                    <p className="text-danger">{errors.address_part2 && errors.address_part2.message}</p>
                                                ) : <p>Address Part 2</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="address_part2"
                                                    defaultValue={item.address_part2}
                                                    className="form-control shadow-none"
                                                    placeholder="Enter address_part2"
                                                    ref={register({ required: "address_part2 is required" })}
                                                />
                                            </div>
                                        </div>


                                        {/* phone_number */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.phone_number && errors.phone_number.message ? (
                                                    <p className="text-danger">{errors.phone_number && errors.phone_number.message}</p>
                                                ) : <p>Phone Number</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="phone_number"
                                                    defaultValue={item.phone_number}
                                                    className="form-control shadow-none"
                                                    placeholder="Enter phone_number"
                                                    ref={register({ required: "phone_number is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* phone_call_time */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.phone_call_time && errors.phone_call_time.message ? (
                                                    <p className="text-danger">{errors.phone_call_time && errors.phone_call_time.message}</p>
                                                ) : <p>Phone call time</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="phone_call_time"
                                                    defaultValue={item.phone_call_time}
                                                    className="form-control shadow-none"
                                                    placeholder="Enter phone_call_time"
                                                    ref={register({ required: "phone_call_time is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Email */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.email && errors.email.message ? (
                                                    <p className="text-danger">{errors.email && errors.email.message}</p>
                                                ) : <p>Email</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="email"
                                                    defaultValue={item.email}
                                                    className="form-control shadow-none"
                                                    placeholder="Enter email"
                                                    ref={register({ required: "email is required" })}
                                                />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={update}>
                                            {update ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Edit;
