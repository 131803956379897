import React from 'react'
import './style.scss'
import NoDataComponent from '../../noData/Index'
import GhostTable from '../../ghostLoader/table/Index'
import { Link } from 'react-router-dom'
import { Images } from '../../../utils/Images'

const Index = ({ users }) => {
    if (!users) return (<GhostTable />)
    if (!users.length) return (<NoDataComponent />)

    return (
        <div className="profile-card-container">
          
                    {users && users.map((item, i) =>
                         <div className="card border-0 p-2" key={i}>
                             <div className="card-body shadow-sm">
                            <div className="image-container rounded-circle">
                                {/* <img src={Images.Image4} className="img-fluid" alt="..." />  ? item.images.name : Images.Avatar*/}
                                <img src={item.images && item.images.length > 0 && item.images[0] && item.images[0].name ? item.images[0].name : Images.Avatar} className="img-fluid" alt="..." />
                                {/* <img src={item.images ? item.images : Images.Avatar} className="img-fluid" alt="..." /> */}
                            </div>
                            <br />
                            <h6 className="text-capitalize">{item.name}</h6>
                            <p>Profile-ID: {item.profileId}</p>
                            <p className="text-capitalize">{item.gender}</p>
                            <Link
                                to={`/dashboard/user/${item._id}/show`}
                                type="button"
                                className="btn btn-sm shadow-none"
                            >View Profile</Link>
                        </div>
                         </div>     
                    )}
               
        </div>
    );
};

export default Index;