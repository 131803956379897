import React, { useState } from 'react'
import "./style.scss"
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios'
import { useQuery } from 'react-query'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "rsuite/dist/rsuite.css";
import { api } from '../../../../utils/api';
import { DateSpliter } from '../../../../utils/helpers';
import GhostTable from '../../../../components/ghostLoader/table/Index'
import Pagination from '../../../../components/pagination/Index'
import UserFilterForm from '../../../../components/form/UserFilterForm';
import Icon from 'react-icons-kit'
import { x, checkCircle } from 'react-icons-kit/feather'
import { Images } from '../../../../utils/Images'
import NoDataComponent from '../../../../components/noData/Index'
toast.configure({ autoClose: 2000 })


const Create = () => {
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [pageReload, setPageReload] = useState(true);
    const [profiles, setProfiles] = useState([])
    const [singleRefetch, setSigleRefetch] = useState(false);
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [queryParams, setQueryParams] = useState({
        page: query.page,
        limit: 20,
        orderBy: query.orderBy || null,
        profileType: query.profileType || null,
        materialStatus: query.materialStatus || null,
        workStatus: query.workStatus || null,
        qualification: query.qualification || null,
        gender: query.gender || null,
        status: query.status || null,
        dateRange: query.dateRange || null,
    });
    const [totalPage, setTotalPage] = useState(null);
    const [searching, setSearching] = useState(false);
    const [searchValues, setSearchValues] = useState(null);
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    });

    const getUsers = async () => {
        try {
            if (singleRefetch && pageReload) {
                setSearching(true)
                const response = await Axios.get(`${api}all-user/search?query=${searchValues}`, header)
                setTotalPage(1)
                setSearching(false)
                setPageReload(false)
                setSigleRefetch(false)
                return response.data.users
            }
            else {
                const response = await Axios.get(`${api}all-user/index${location.search}`, header)
                setTotalPage(response.data.totalPage)
                setPageReload(false)
                setSigleRefetch(false)
                return response.data.users
            }
        } catch (error) {
            if (error) {
                setSearching(false)
                toast.warn(error.message)
            }
        }

    };

    const queryData = useQuery('users', getUsers, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: 2,
        enabled: Boolean(pageReload)
    });

    // change page no
    const changePage = page => {
        setQueryParams({ ...queryParams, page: page })
        history.push(`/dashboard/marketing/sms?page=${page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`)
        setPageReload(true)
    };


    // Handle order By
    const handleOrderBy = data => {
        setQueryParams({ ...queryParams, orderBy: data })
        history.push(`/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${data}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`)
        setPageReload(true)
    };

    // Handle profile type
    const handleProfileType = data => {
        setQueryParams({ ...queryParams, profileType: data })
        history.push(`/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${data}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`)
        setPageReload(true)
    };

    // Handle material status
    const handleMaterialStatus = data => {
        setQueryParams({ ...queryParams, materialStatus: data })
        history.push(`/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${data}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`)
        setPageReload(true)
    };

    // Handle work status
    const handleWorkStatus = data => {
        setQueryParams({ ...queryParams, workStatus: data })
        history.push(`/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${data}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`)
        setPageReload(true)
    };

    // Handle work status
    const handleQualification = data => {
        setQueryParams({ ...queryParams, qualification: data })
        history.push(`/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${data}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`)
        setPageReload(true)
    };

    // Handle gender 
    const handleGender = data => {
        setQueryParams({ ...queryParams, gender: data })
        setPageReload(true)
        history.push(`/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${data}&isOnline=${queryParams.isOnline}`)
    };

    // Handle status
    const handleStatus = (data) => {
        setQueryParams({ ...queryParams, status: data });
        setPageReload(true);
        history.push(
            `/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${data}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`
        )
    };

    // handle online status
    const handleOnlineStatus = (data) => {
        setQueryParams({ ...queryParams, isOnline: data });
        setPageReload(true);
        history.push(
            `/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${data}&isOnline=${queryParams.isOnline}`
        )
    };

    //   Handle data range
    const handleRange = range => {
        if (range) {
            let from = DateSpliter(range[0])
            let to = DateSpliter(range[1])
            setQueryParams({ ...queryParams, dateRange: `${from}~${to}` });
            setPageReload(true);
            history.push(
                `/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=${`${from}~${to}`}&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`
            )
        }
    }

    //   clear date range
    const handleRangeClean = (e) => {
        e.preventDefault();
        setQueryParams({ ...queryParams, dateRange: null });
        setPageReload(true);
        history.push(
            `/dashboard/marketing/sms?page=${queryParams.page}&limit=${query.limit}&dateRange=null&status=${queryParams.status}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}&isOnline=${queryParams.isOnline}`
        )
    }

    // Search user
    const submitSearch = async () => {
        setSigleRefetch(true)
        setPageReload(true)
    }

    // clear search
    const onChangeClearSearch = event => {
        if (!event.target.value) {
            setPageReload(true)
        }
        setSearchValues(event.target.value)
    }

    const defaultValueHandler = (data, options) => {
        if (data && data !== 'null') return options.find(obj => obj.value === data)
        else return null
    }

    // check available profile
    const checkAvailable = data => {
        const findItem = profiles.find(e => e === data)
        if (findItem)
            return true
        else
            return false
    }

    // check active
    const checkActive = data => {
        if (profiles) {
            const item = profiles.find(x => x === data)
            if (item)
                return true
            return false
        }
    }

    // remove profile
    const removeProfile = data => {
        const items = profiles.filter(e => e !== data)
        setProfiles([])
        setProfiles(items)
    }

    // Select Profile
    const selectProfile = profile => {
        if (checkAvailable(profile)) {
            removeProfile(profile)
        } else {
            setProfiles([...profiles, profile])
        }
    }

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            if (profiles.length < 1) {
                toast.warning("profile required")

            } else {
                const formData = { ...data, phoneNumbers: profiles }

                const response = await Axios.post(`${api}marketing/sms/send`, formData, header)
                if (response.status === 201) {
                    setLoading(false)
                    setProfiles([])
                    toast.success(response.data.message)
                }
            }

            setTimeout(() => {
                setLoading(false)
            }, 2000);

        } catch (error) {
            if (error) {
                setLoading(false)
                console.log(error)
            }
        }
    }

    // If loading to get data
    if (queryData.isLoading) return (<GhostTable />);
    if (queryData.isError) return (<div className='text-center mt-5'>Something went wrong</div>);

    return (
        <div className="sms-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-4 bg-white  d-flex justify-content-between align-items-center">
                                <h6 className="mb-0">Send Message to Users</h6>

                                <Link to="/dashboard/marketing/sms/history">
                                    <h6 className="mb-0">history</h6>
                                </Link>
                            </div>
                            <div className="card-body p-4">

                                {/* Start */}

                                <UserFilterForm
                                    submitSearch={submitSearch}
                                    onChangeClearSearch={onChangeClearSearch}
                                    handleRange={handleRange}
                                    handleRangeClean={handleRangeClean}
                                    defaultValueHandler={defaultValueHandler}
                                    handleOrderBy={handleOrderBy}
                                    handleProfileType={handleProfileType}
                                    handleMaterialStatus={handleMaterialStatus}
                                    handleWorkStatus={handleWorkStatus}
                                    handleQualification={handleQualification}
                                    handleGender={handleGender}
                                    handleStatus={handleStatus}
                                    handleOnlineStatus={handleOnlineStatus}
                                    searching={searching}
                                />

                                {/* End */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-padding">
                <div className="card">

                    {/* Show selected profiles */}
                    <div className="selected-profiles-email-container">
                        {profiles && profiles.map((phone, i) =>
                            <div className="email-container" key={i}>
                                <p className="text-lowercase mb-0">{phone}
                                    <span>
                                        <button type="button" className="btn rounded-circle shadow-none" onClick={() => removeProfile(phone)}>
                                            <Icon icon={x} size={15} />
                                        </button>
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>

                    {/* Suggested profiles */}
                    {
                        queryData.data ?
                            <>
                                <div className="suggested-profile-container">
                                    {queryData.data.map((user, i) =>
                                        <div className="profile-card card border-0" key={i}>
                                            <div className="card-body" onClick={() => selectProfile(user.phone)}>
                                                <div className="image-container rounded-circle">
                                                    <img src={user.image ? user.image : Images.Avatar} className="img-fluid" alt="..." />
                                                </div>

                                                <div className="content-container">
                                                    <h6 className="text-capitalize mb-0">{user.name}</h6>
                                                    <p className="text-lowercase mb-0">{user.email}</p>
                                                    <p className="text-lowercase mb-0">{user.phone}</p>
                                                </div>

                                                <button type="button" className="btn shadow-none rounded-circle">
                                                    <Icon icon={checkCircle} size={18} style={{ color: checkActive(user.phone) ? '#28a745' : '#dfdfdf' }} />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="card-footer bg-white border-0 pt-0 pb-4">
                                    <Pagination totalpage={totalPage} changePage={changePage} />
                                </div>
                            </>
                            :
                            <NoDataComponent />
                    }

                    {/* message  */}
                    <form onSubmit={handleSubmit(onSubmit)} >

                        {/* SMS */}
                        <div className="form-group mb-4">

                            <textarea
                                name="message"
                                rows={4}
                                className="form-control shadow-none"
                                placeholder="Write message here..."
                                ref={register({ required: "Message is required" })}
                            ></textarea>

                            {errors.message && errors.message.message &&
                                <p className="text-danger ">{errors.message && errors.message.message}</p>}
                        </div>
                        <div className="text-right">
                            <button
                                type="submit"
                                className="btn shadow-none"
                                disabled={isLoading}
                            >{isLoading ? 'Sending...' : 'Send'}</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default Create;
