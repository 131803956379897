import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { api } from '../../../../utils/api'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useHistory,} from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { phone, mail, edit2, user, image, facebook, command,  cpu, folder, home, chevronLeft } from 'react-icons-kit/feather'

import GhostProfile from '../../../../components/ghostLoader/user/profileLoader/Index'

toast.configure({ autoClose: 2000 })

const Show = () => {
    const { id } = useParams()
    const [isloading, setLoading] = useState(true)
    const history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }

    const [state, setState] = useState({ isOpen: false });
    const [stateNID, setStateNID] = useState({ isOpen: false });
    const [stateAnyBillCopy, setStateAnyBillCopy] = useState({ isOpen: false });

    const handleShowDialog = () => {
        setState({ isOpen: !state.isOpen });
    };

    const handleNID = () => {
        setStateNID({ isOpen: !stateNID.isOpen });
    };

    const handleAnyBillCopy = () => {
        setStateAnyBillCopy({ isOpen: !stateAnyBillCopy.isOpen });
    };

    const [show, setShow] = useState('')
    const [veiw, setVeiw] = useState(false)
    const [ghotok, setGhotok] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })

    // get user 
    const getUser = useCallback(async () => {
        try {
            const response = await Axios.get(`${api}ghotok/${id}/show`, header)
            if (response.status === 200) {
                setGhotok(response.data.profile)
                setShow(response.data.profile._id)
                setLoading(false)
            }
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }, [id, header])

    useEffect(() => {
        getUser()
    }, [id, header, getUser])


    if (isloading) return (<GhostProfile />)

    return (
        <div className="profile-index pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card profile-card border-0 shadow-sm">
                            <div className="card-header bg-white card-body">
                            <Link
                                    onClick={goToPreviousPath}
                                    type="button"
                                    className="btnarrow shadow-none rounded-circle"
                                >
                                    <Icon icon={chevronLeft} size={22} />
                                </Link>
                                <div className="flex-center flex-column">

                                    <div className="profile-image-container rounded-circle">
                                        <img src={ghotok.profileImage} className="img-fluid" onClick={handleShowDialog} alt="..." />
                                        {state.isOpen && (
                                            <div
                                                className="dialog"
                                                open
                                                onClick={handleShowDialog}
                                            >
                                                <img
                                                    className="image"
                                                    src={ghotok.profileImage}
                                                    onClick={handleShowDialog}
                                                    alt="..."
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* Name */}
                                    <h5>{ghotok.name}</h5>


                                </div>
                            </div>
                            {/* Header container */}
                            <div className="card-body p-4">
                                <table className="table table-sm table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={user} size={18} className="icon" /></td>
                                            <td style={{ width: 140 }}><p>Id</p></td>
                                            <td><p>: {ghotok.ghotokMediaId ? ghotok.ghotokMediaId : "Ghotok"}</p></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={phone} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Phone</p></td>
                                            <td><p>: {ghotok.phone}</p></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={mail} size={18} /></td>
                                            <td style={{ width: 140 }}><p>E-mail</p></td>
                                            <td><p>: {ghotok.email}</p></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={user} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Gender</p></td>
                                            <td><p>: {ghotok.gender}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={facebook} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Facebook Id</p></td>
                                            <td><p>: {ghotok.fbPageUrl}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={home} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Address</p></td>
                                            <td><p>: {ghotok.address}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={phone} size={18} /></td>
                                            <td style={{ width: 140 }}><p>whatsapp / Imo</p></td>
                                            <td><p>: {ghotok.whatsappOrImo}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={command} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Reference Way</p></td>
                                            <td><p>: {ghotok.referenceWay}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={phone} size={18} /></td>
                                            <td style={{ width: 140 }}><p>{ghotok.mobileNumberFor} Number</p></td>
                                            <td><p>: {ghotok.mobileBankingNumber}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={cpu} size={18} /></td>
                                            <td style={{ width: 140 }}><p>NID No</p></td>
                                            <td><p>: {ghotok.NIDNo}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={folder} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Document</p></td>

                                            {veiw ? (<td><p>:<button onClick={() => setVeiw(false)} style={{ border: 'none', backgroundColor: "#fff", padding: '2px' }}>Hide</button></p></td>) : (<td><p>:<button onClick={() => setVeiw(true)} style={{ border: 'none', backgroundColor: "#fff", padding: '2px' }}>View</button></p></td>)}
                                        </tr>
                                        {veiw ? (

                                            <tr>
                                                <td style={{ width: 20 }}><Icon icon={image} size={18} /></td>
                                                <td style={{ width: 140 }}><p>NID Photo</p></td>
                                                <td><p><img src={ghotok.NIDImage} onClick={handleNID} alt="..." style={{ width: 100, height: 100 }} /></p>
                                                {stateNID.isOpen && (
                                                        <div
                                                            style={{ marginTop: 20 }}
                                                            className="dialog"
                                                            open
                                                            onClick={handleNID}
                                                        >
                                                            <img
                                                                className="image"
                                                                src={ghotok.NIDImage}
                                                                onClick={handleNID}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>


                                        ) : null}

                                        {veiw ? (

                                            <tr>
                                                <td style={{ width: 20 }}><Icon icon={image} size={18} /></td>
                                                <td style={{ width: 140 }}><p>Any Bill Copy</p></td>
                                                <td><p><img src={ghotok.anyBillCopy} onClick={handleAnyBillCopy} alt="..." style={{ width: 100, height: 100 }} /></p>
                                                {stateAnyBillCopy.isOpen && (
                                                        <div 
                                                            style={{ marginTop: 20 }}
                                                            className="dialog"
                                                            open
                                                            onClick={handleAnyBillCopy}
                                                        >
                                                            <img
                                                                className="image"
                                                                src={ghotok.anyBillCopy}
                                                                onClick={handleAnyBillCopy}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    )}
                                                </td>

                                            </tr>


                                        ) : null}

                                    </tbody>
                                </table>
                                <button type="button" className="btn shadow rounded-circle"
                                    onClick={() => history.push(`/dashboard/agents/${show}/update`)}
                                >
                                    <Icon icon={edit2} size={20} />
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Show;