import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })
const Create = () => {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })


    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            await Requests.TermsAndConditions.Store(data, header)
            setLoading(false)
            history.push('/dashboard/website/terms-and-conditions')
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div className="store-story pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Store story</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/terms-and-conditions"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* Introduction 1 */}
                                    <div className="form-group mb-4">
                                        {errors.introduction && errors.introduction.message ? (
                                            <p className="text-danger">{errors.introduction && errors.introduction.message}</p>
                                        ) : <p>introduction</p>}

                                        <textarea
                                            type="text"
                                            name="introduction"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short introduction"
                                            ref={register({
                                                required: "introduction is required"
                                            })}
                                        />
                                    </div>

                                    {/* Terms and Condition  */}
                                    <div className="form-group mb-4">
                                        {errors.terms_and_condition_para1 && errors.terms_and_condition_para1.message ? (
                                            <p className="text-danger">{errors.terms_and_condition_para1 && errors.terms_and_condition_para1.message}</p>
                                        ) : <p>terms_and_condition_para1</p>}

                                        <textarea
                                            type="text"
                                            name="terms_and_condition_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short terms_and_condition_para1"
                                            ref={register({
                                                required: "terms_and_condition_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* terms_and_condition_para2  */}
                                    <div className="form-group mb-4">
                                        {errors.terms_and_condition_para2 && errors.terms_and_condition_para2.message ? (
                                            <p className="text-danger">{errors.terms_and_condition_para2 && errors.terms_and_condition_para2.message}</p>
                                        ) : <p>terms_and_condition_para2</p>}

                                        <textarea
                                            type="text"
                                            name="terms_and_condition_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short terms_and_condition_para2"
                                            ref={register({
                                                required: "terms_and_condition_para2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* terms_and_condition_para3 */}
                                    <div className="form-group mb-4">
                                        {errors.terms_and_condition_para3 && errors.terms_and_condition_para3.message ? (
                                            <p className="text-danger">{errors.terms_and_condition_para3 && errors.terms_and_condition_para3.message}</p>
                                        ) : <p>terms_and_condition_para3</p>}

                                        <textarea
                                            type="text"
                                            name="terms_and_condition_para3"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short terms_and_condition_para3"
                                            ref={register({
                                                required: "terms_and_condition_para3 is required"
                                            })}
                                        />
                                    </div>

                                    {/* terms_and_condition_para4 */}
                                    <div className="form-group mb-4">
                                        {errors.terms_and_condition_para4 && errors.terms_and_condition_para4.message ? (
                                            <p className="text-danger">{errors.terms_and_condition_para4 && errors.terms_and_condition_para4.message}</p>
                                        ) : <p>terms_and_condition_para4</p>}

                                        <textarea
                                            type="text"
                                            name="terms_and_condition_para4"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short terms_and_condition_para4"
                                            ref={register({
                                                required: "terms_and_condition_para4 is required"
                                            })}
                                        />
                                    </div>

                                    {/* terms_and_condition_para5 */}
                                    <div className="form-group mb-4">
                                        {errors.terms_and_condition_para5 && errors.terms_and_condition_para5.message ? (
                                            <p className="text-danger">{errors.terms_and_condition_para5 && errors.terms_and_condition_para5.message}</p>
                                        ) : <p>terms_and_condition_para5</p>}

                                        <textarea
                                            type="text"
                                            name="terms_and_condition_para5"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short terms_and_condition_para5"
                                            ref={register({
                                                required: "terms_and_condition_para5 is required"
                                            })}
                                        />
                                    </div>

                                    {/* Privacy Policy */}
                                    <div className="form-group mb-4">
                                        {errors.privacy_policy_para1 && errors.privacy_policy_para1.message ? (
                                            <p className="text-danger">{errors.privacy_policy_para1 && errors.privacy_policy_para1.message}</p>
                                        ) : <p>privacy_policy_para1</p>}

                                        <textarea
                                            type="text"
                                            name="privacy_policy_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short privacy_policy_para1"
                                            ref={register({
                                                required: "privacy_policy_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* privacy_policy_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.privacy_policy_para2 && errors.privacy_policy_para2.message ? (
                                            <p className="text-danger">{errors.privacy_policy_para2 && errors.privacy_policy_para2.message}</p>
                                        ) : <p>privacy_policy_para2</p>}

                                        <textarea
                                            type="text"
                                            name="privacy_policy_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short privacy_policy_para2"
                                            ref={register({
                                                required: "privacy_policy_para2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* Copyright */}
                                    <div className="form-group mb-4">
                                        {errors.copyright_para1 && errors.copyright_para1.message ? (
                                            <p className="text-danger">{errors.copyright_para1 && errors.copyright_para1.message}</p>
                                        ) : <p>copyright_para1</p>}

                                        <textarea
                                            type="text"
                                            name="copyright_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short copyright_para1"
                                            ref={register({
                                                required: "copyright_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* Copyright */}
                                    <div className="form-group mb-4">
                                        {errors.copyright_para2 && errors.copyright_para2.message ? (
                                            <p className="text-danger">{errors.copyright_para2 && errors.copyright_para2.message}</p>
                                        ) : <p>copyright_para2</p>}

                                        <textarea
                                            type="text"
                                            name="copyright_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short copyright_para2"
                                            ref={register({
                                                required: "copyright_para2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* Copyright */}
                                    <div className="form-group mb-4">
                                        {errors.copyright_para3 && errors.copyright_para3.message ? (
                                            <p className="text-danger">{errors.copyright_para3 && errors.copyright_para3.message}</p>
                                        ) : <p>copyright_para3</p>}

                                        <textarea
                                            type="text"
                                            name="copyright_para3"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short copyright_para3"
                                            ref={register({
                                                required: "copyright_para3 is required"
                                            })}
                                        />
                                    </div>

                                    {/* License and Site Access */}
                                    <div className="form-group mb-4">
                                        {errors.license_and_site_access_para1 && errors.license_and_site_access_para1.message ? (
                                            <p className="text-danger">{errors.license_and_site_access_para1 && errors.license_and_site_access_para1.message}</p>
                                        ) : <p>license_and_site_access_para1</p>}

                                        <textarea
                                            type="text"
                                            name="license_and_site_access_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short license_and_site_access_para1"
                                            ref={register({
                                                required: "license_and_site_access_para1 is required"
                                            })}
                                        />
                                    </div>


                                    {/* license_and_site_access_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.license_and_site_access_para2 && errors.license_and_site_access_para2.message ? (
                                            <p className="text-danger">{errors.license_and_site_access_para2 && errors.license_and_site_access_para2.message}</p>
                                        ) : <p>license_and_site_access_para2</p>}

                                        <textarea
                                            type="text"
                                            name="license_and_site_access_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short license_and_site_access_para2"
                                            ref={register({
                                                required: "license_and_site_access_para2 is required"
                                            })}
                                        />
                                    </div>


                                    {/* license_and_site_access_para3 */}
                                    <div className="form-group mb-4">
                                        {errors.license_and_site_access_para3 && errors.license_and_site_access_para3.message ? (
                                            <p className="text-danger">{errors.license_and_site_access_para3 && errors.license_and_site_access_para3.message}</p>
                                        ) : <p>license_and_site_access_para3</p>}

                                        <textarea
                                            type="text"
                                            name="license_and_site_access_para3"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short license_and_site_access_para3"
                                            ref={register({
                                                required: "license_and_site_access_para3 is required"
                                            })}
                                        />
                                    </div>

                                    {/* license_and_site_access_para4 */}
                                    <div className="form-group mb-4">
                                        {errors.license_and_site_access_para4 && errors.license_and_site_access_para4.message ? (
                                            <p className="text-danger">{errors.license_and_site_access_para4 && errors.license_and_site_access_para4.message}</p>
                                        ) : <p>license_and_site_access_para4</p>}

                                        <textarea
                                            type="text"
                                            name="license_and_site_access_para4"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short license_and_site_access_para4"
                                            ref={register({
                                                required: "license_and_site_access_para4 is required"
                                            })}
                                        />
                                    </div>

                                    {/* license_and_site_access_para5 */}
                                    <div className="form-group mb-4">
                                        {errors.license_and_site_access_para5 && errors.license_and_site_access_para5.message ? (
                                            <p className="text-danger">{errors.license_and_site_access_para5 && errors.license_and_site_access_para5.message}</p>
                                        ) : <p>license_and_site_access_para5</p>}

                                        <textarea
                                            type="text"
                                            name="license_and_site_access_para5"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short license_and_site_access_para5"
                                            ref={register({
                                                required: "license_and_site_access_para5 is required"
                                            })}
                                        />
                                    </div>

                                    {/* license_and_site_access_para6 */}
                                    <div className="form-group mb-4">
                                        {errors.license_and_site_access_para6 && errors.license_and_site_access_para6.message ? (
                                            <p className="text-danger">{errors.license_and_site_access_para6 && errors.license_and_site_access_para6.message}</p>
                                        ) : <p>license_and_site_access_para6</p>}

                                        <textarea
                                            type="text"
                                            name="license_and_site_access_para6"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short license_and_site_access_para6"
                                            ref={register({
                                                required: "license_and_site_access_para6 is required"
                                            })}
                                        />
                                    </div>

                                    {/* membership_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.membership_para1 && errors.membership_para1.message ? (
                                            <p className="text-danger">{errors.membership_para1 && errors.membership_para1.message}</p>
                                        ) : <p>membership_para1</p>}

                                        <textarea
                                            type="text"
                                            name="membership_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short membership_para1"
                                            ref={register({
                                                required: "membership_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* membership_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.membership_para2 && errors.membership_para2.message ? (
                                            <p className="text-danger">{errors.membership_para2 && errors.membership_para2.message}</p>
                                        ) : <p>membership_para2</p>}

                                        <textarea
                                            type="text"
                                            name="membership_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short membership_para2"
                                            ref={register({
                                                required: "membership_para2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* online_conduct_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.online_conduct_para1 && errors.online_conduct_para1.message ? (
                                            <p className="text-danger">{errors.online_conduct_para1 && errors.online_conduct_para1.message}</p>
                                        ) : <p>online_conduct_para1</p>}

                                        <textarea
                                            type="text"
                                            name="online_conduct_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short online_conduct_para1"
                                            ref={register({
                                                required: "online_conduct_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* online_conduct_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.online_conduct_para2 && errors.online_conduct_para2.message ? (
                                            <p className="text-danger">{errors.online_conduct_para2 && errors.online_conduct_para2.message}</p>
                                        ) : <p>online_conduct_para2</p>}

                                        <textarea
                                            type="text"
                                            name="online_conduct_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short online_conduct_para2"
                                            ref={register({
                                                required: "online_conduct_para2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* online_conduct_para3 */}
                                    <div className="form-group mb-4">
                                        {errors.online_conduct_para3 && errors.online_conduct_para3.message ? (
                                            <p className="text-danger">{errors.online_conduct_para3 && errors.online_conduct_para3.message}</p>
                                        ) : <p>online_conduct_para3</p>}

                                        <textarea
                                            type="text"
                                            name="online_conduct_para3"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short online_conduct_para3"
                                            ref={register({
                                                required: "online_conduct_para3 is required"
                                            })}
                                        />
                                    </div>

                                    {/* online_conduct_para4 */}
                                    <div className="form-group mb-4">
                                        {errors.online_conduct_para4 && errors.online_conduct_para4.message ? (
                                            <p className="text-danger">{errors.online_conduct_para4 && errors.online_conduct_para4.message}</p>
                                        ) : <p>online_conduct_para4</p>}

                                        <textarea
                                            type="text"
                                            name="online_conduct_para4"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short online_conduct_para4"
                                            ref={register({
                                                required: "online_conduct_para4 is required"
                                            })}
                                        />
                                    </div>


                                    {/* online_conduct_para5 */}
                                    <div className="form-group mb-4">
                                        {errors.online_conduct_para5 && errors.online_conduct_para5.message ? (
                                            <p className="text-danger">{errors.online_conduct_para5 && errors.online_conduct_para5.message}</p>
                                        ) : <p>online_conduct_para5</p>}

                                        <textarea
                                            type="text"
                                            name="online_conduct_para5"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short online_conduct_para5"
                                            ref={register({
                                                required: "online_conduct_para5 is required"
                                            })}
                                        />
                                    </div>

                                    {/* online_conduct_para6 */}
                                    <div className="form-group mb-4">
                                        {errors.online_conduct_para6 && errors.online_conduct_para6.message ? (
                                            <p className="text-danger">{errors.online_conduct_para6 && errors.online_conduct_para6.message}</p>
                                        ) : <p>online_conduct_para6</p>}

                                        <textarea
                                            type="text"
                                            name="online_conduct_para6"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short online_conduct_para6"
                                            ref={register({
                                                required: "online_conduct_para6 is required"
                                            })}
                                        />
                                    </div>

                                    {/* disclaimer_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.disclaimer_para1 && errors.disclaimer_para1.message ? (
                                            <p className="text-danger">{errors.disclaimer_para1 && errors.disclaimer_para1.message}</p>
                                        ) : <p>disclaimer_para1</p>}

                                        <textarea
                                            type="text"
                                            name="disclaimer_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short disclaimer_para1"
                                            ref={register({
                                                required: "disclaimer_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* disclaimer_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.disclaimer_para2 && errors.disclaimer_para2.message ? (
                                            <p className="text-danger">{errors.disclaimer_para2 && errors.disclaimer_para2.message}</p>
                                        ) : <p>disclaimer_para2</p>}

                                        <textarea
                                            type="text"
                                            name="disclaimer_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short disclaimer_para2"
                                            ref={register({
                                                required: "disclaimer_para2 is required"
                                            })}
                                        />
                                    </div>


                                    {/* disclaimer_para3 */}
                                    <div className="form-group mb-4">
                                        {errors.disclaimer_para3 && errors.disclaimer_para3.message ? (
                                            <p className="text-danger">{errors.disclaimer_para3 && errors.disclaimer_para3.message}</p>
                                        ) : <p>disclaimer_para3</p>}

                                        <textarea
                                            type="text"
                                            name="disclaimer_para3"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short disclaimer_para3"
                                            ref={register({
                                                required: "disclaimer_para3 is required"
                                            })}
                                        />
                                    </div>

                                    {/* refund_of_fee_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.refund_of_fee_para1 && errors.refund_of_fee_para1.message ? (
                                            <p className="text-danger">{errors.refund_of_fee_para1 && errors.refund_of_fee_para1.message}</p>
                                        ) : <p>refund_of_fee_para1</p>}

                                        <textarea
                                            type="text"
                                            name="refund_of_fee_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short refund_of_fee_para1"
                                            ref={register({
                                                required: "refund_of_fee_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* no_agency_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.no_agency_para1 && errors.no_agency_para1.message ? (
                                            <p className="text-danger">{errors.no_agency_para1 && errors.no_agency_para1.message}</p>
                                        ) : <p>no_agency_para1</p>}

                                        <textarea
                                            type="text"
                                            name="no_agency_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short no_agency_para1"
                                            ref={register({
                                                required: "no_agency_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* payment_gateways_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.payment_gateways_para1 && errors.payment_gateways_para1.message ? (
                                            <p className="text-danger">{errors.payment_gateways_para1 && errors.payment_gateways_para1.message}</p>
                                        ) : <p>payment_gateways_para1</p>}

                                        <textarea
                                            type="text"
                                            name="payment_gateways_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short payment_gateways_para1"
                                            ref={register({
                                                required: "payment_gateways_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* consortium_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.consortium_para1 && errors.consortium_para1.message ? (
                                            <p className="text-danger">{errors.consortium_para1 && errors.consortium_para1.message}</p>
                                        ) : <p>consortium_para1</p>}

                                        <textarea
                                            type="text"
                                            name="consortium_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short consortium_para1"
                                            ref={register({
                                                required: "consortium_para1 is required"
                                            })}
                                        />
                                    </div>


                                    {/* indemnity_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.indemnity_para1 && errors.indemnity_para1.message ? (
                                            <p className="text-danger">{errors.indemnity_para1 && errors.indemnity_para1.message}</p>
                                        ) : <p>indemnity_para1</p>}

                                        <textarea
                                            type="text"
                                            name="indemnity_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short indemnity_para1"
                                            ref={register({
                                                required: "indemnity_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* indemnity_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.indemnity_para2 && errors.indemnity_para2.message ? (
                                            <p className="text-danger">{errors.indemnity_para2 && errors.indemnity_para2.message}</p>
                                        ) : <p>indemnity_para2</p>}

                                        <textarea
                                            type="text"
                                            name="indemnity_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short indemnity_para2"
                                            ref={register({
                                                required: "indemnity_para2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* general_provisions_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.general_provisions_para1 && errors.general_provisions_para1.message ? (
                                            <p className="text-danger">{errors.general_provisions_para1 && errors.general_provisions_para1.message}</p>
                                        ) : <p>general_provisions_para1</p>}

                                        <textarea
                                            type="text"
                                            name="general_provisions_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short general_provisions_para1"
                                            ref={register({
                                                required: "general_provisions_para1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* general_provisions_para2 */}
                                    <div className="form-group mb-4">
                                        {errors.general_provisions_para2 && errors.general_provisions_para2.message ? (
                                            <p className="text-danger">{errors.general_provisions_para2 && errors.general_provisions_para2.message}</p>
                                        ) : <p>general_provisions_para2</p>}

                                        <textarea
                                            type="text"
                                            name="general_provisions_para2"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short general_provisions_para2"
                                            ref={register({
                                                required: "general_provisions_para2 is required"
                                            })}
                                        />
                                    </div>
                                    
                                    {/* general_provisions_para3 */}
                                    <div className="form-group mb-4">
                                        {errors.general_provisions_para3 && errors.general_provisions_para3.message ? (
                                            <p className="text-danger">{errors.general_provisions_para3 && errors.general_provisions_para3.message}</p>
                                        ) : <p>general_provisions_para3</p>}

                                        <textarea
                                            type="text"
                                            name="general_provisions_para3"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short general_provisions_para3"
                                            ref={register({
                                                required: "general_provisions_para3 is required"
                                            })}
                                        />
                                    </div>
                                    
                                    {/* conclusions_para1 */}
                                    <div className="form-group mb-4">
                                        {errors.conclusions_para1 && errors.conclusions_para1.message ? (
                                            <p className="text-danger">{errors.conclusions_para1 && errors.conclusions_para1.message}</p>
                                        ) : <p>conclusions_para1</p>}

                                        <textarea
                                            type="text"
                                            name="conclusions_para1"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short conclusions_para1"
                                            ref={register({
                                                required: "conclusions_para1 is required"
                                            })}
                                        />
                                    </div>


                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Creating...' : 'Create'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Create;
