import React, { useCallback, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Icon from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'
import { dateFormate } from '../../../../utils/helpers'

import LoadingComponent from '../../../../components/loading/Index'

const Show = () => {
    const { id } = useParams()
    const [isLoading, setLoading] = useState(true)
    const [item, setItem] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.Story.Show(id, header)
        if (data) {
            setItem(data)
            setLoading(false)
        }
        setLoading(false)
    }, [id, header])


    useEffect(() => {
        fetchData()
    }, [id, header, fetchData])


    if (isLoading) return <LoadingComponent />

    return (
        <div className="store-story pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Show Story</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/story"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <div className="d-lg-flex">
                                    <div className="pr-lg-5 mb-4">
                                        <img
                                            src={item.image}
                                            className="img-fluid"
                                            alt="..."
                                            style={{ width: 200 }}
                                        />
                                    </div>
                                    <div>
                                        <table className="table table-md table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>{item.title || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Location</td>
                                                    <td>{item.location || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bride name</td>
                                                    <td>{item.brideName || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Groom name</td>
                                                    <td>{item.groomName || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Marriage date</td>
                                                    <td>{dateFormate(item.marriageDate) || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Details</td>
                                                    <td>{item.details || 'N/A'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br />

                                {item.admin ?
                                    <div className="text-right">
                                        <h6><i>Created by</i></h6>
                                        <p className="mb-0">{item.admin.name}</p>
                                        <p className="mb-0">{item.admin.email}</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;