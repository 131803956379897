import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import { formatDateWithAMPM } from '../../../utils/helpers'
import 'react-toastify/dist/ReactToastify.css'
import { Images } from '../../../utils/Images'
import { NavLink, useParams } from 'react-router-dom'
import { phone, mail } from 'react-icons-kit/feather'
import { Switch, Route } from 'react-router-dom'

import AboutPage from './user/about/Index'
import GalleryPage from './user/gallery/Index'
import ConnectionPage from './user/connection/Index'
import ProposalSendPage from './user/proposalsend/Index'
import FavouritePage from './user/favouriteList/Index'
import BlockPage from './user/blockList/Index'
import PaymentlogPage from './Payment'
import RecentViewPage from './user/recentlyView/Index'
import ReportedProfilesPage from './user/reportedProfiles/Index'
import TimelinePage from './user/timeline/Index'
import MessagePage from './user/message/Index'
import GhostProfile from '../../../components/ghostLoader/user/profileLoader/Index'

toast.configure({ autoClose: 2000 })
const Show = () => {
    const { id } = useParams()
    const [isloading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })

    // get user
    const getUser = useCallback(async () => {
        try {
            const response = await Axios.get(`${api}user/show/${id}`, header)
            if (response.status === 200) {
                setUser(response.data.user)
                setLoading(false)
            }
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }, [id, header])

    useEffect(() => {
        getUser()
    }, [id, header, getUser])


    if (isloading) return (<GhostProfile />)

    return (
        <div className="dashboard-users-show pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding pb-0">
                        {/* Header container */}
                        <div className="card border-0 header-card">

                            {/* Header background */}
                            <div
                                className="image-container"
                                style={{
                                    backgroundImage: `url(${(user.profilePicture.clearImage ? `${process.env.REACT_APP_IMAGE_URL}/${user.profilePicture.clearImage}` : Images.Avatar)})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center'
                                }}
                            ></div>

                            <div className="image rounded-circle">
                                <img src={user.profilePicture.clearImage ? `${process.env.REACT_APP_IMAGE_URL}/${user.profilePicture.clearImage}` : Images.Avatar} className="img-fluid" alt="..." />
                            </div>

                            <div className="card-body p-0 p-sm-4">
                                <div className="d-lg-flex">
                                    {/* Account information */}
                                    <div className="content-container shadow-sm flex-fill mt-4 mt-lg-0 ml-lg-4">
                                        <h5>{user.name}</h5>
                                        <p>{user.profileId}</p>
                                        <hr />
                                        <ul>
                                            <li>
                                                <h6>Account type<span>({user.profileType})</span></h6>
                                            </li>
                                            <li>
                                                <h6>Account verified<span style={{ color: user.isVerified ? "green" : "red" }}>
                                                    ({user.isVerified ? 'Yes' : 'No'})</span>
                                                </h6>
                                            </li>
                                            <li>
                                                <h6>Profile locked<span style={{ color: user.isLocked ? "red" : "green" }}>({user.isLocked ? 'Yes' : 'No'})</span>
                                                </h6>
                                            </li>
                                            <li>
                                                <h6>Last loggedin<span style={{ color: user.loggedinAt ? "green" : "red" }}>({formatDateWithAMPM(user.loggedinAt)})</span>
                                                </h6>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* User information */}
                                    <div className="content-container shadow-sm flex-fill mt-4 mt-lg-0 ml-lg-4">
                                        <p><Icon icon={phone} size={20} className="mr-2 mb-2" />{user.phone}</p>
                                        <p className="text-lowercase"><Icon icon={mail} size={20} className="mr-2 mb-2" />{user.email}</p>
                                        <hr />
                                        <ul>
                                            <li>
                                                <h6>Gender<span>({user.gender})</span></h6>
                                            </li>
                                            <li>
                                                <h6>Looking For<span>({user.lookingFor})</span></h6>
                                            </li>
                                            <li>
                                                <h6>Religion<span>({user.religion})</span></h6>
                                                <p></p>
                                            </li>
                                            <li>
                                                <h6>Profession<span>({user.professionArea})</span></h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Page Links */}
                        <div className="card border-0 shadow-sm page-links-container my-4 mt-sm-0">
                            <div className="card-body py-2">
                                <div className="btn-group">
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/edit`}>Edit</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/`}>About</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/gallery`}>Gallery</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/connections`}>Connections</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/proposalsend`}>Proposal Send</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/favourite-list`}>Favourite List</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/block-list`}>Block List</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/recently-view`}>Recently View</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/reported-profiles`}>Reported Profiles</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/timeline`}>Timeline</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none border-right" to={`/dashboard/user/${id}/show/payment-log`}>Payment Log</NavLink>
                                    <NavLink exact type="button" activeClassName="is-Active" className="btn shadow-none" to={`/dashboard/user/${id}/show/admin-messages`}>Admin Messages</NavLink>
                                </div>
                            </div>
                        </div>

                        {/* Main */}
                        <div>
                            <Switch>
                                <Route exact path="/dashboard/user/:id/show/" component={() => <AboutPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/gallery" component={() => <GalleryPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/connections" component={() => <ConnectionPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/proposalsend" component={() => <ProposalSendPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/favourite-list" component={() => <FavouritePage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/block-list" component={() => <BlockPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/recently-view" component={() => <RecentViewPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/reported-profiles" component={() => <ReportedProfilesPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/timeline" component={() => <TimelinePage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/payment-log" component={() => <PaymentlogPage user={user} />} />
                                <Route exact path="/dashboard/user/:id/show/admin-messages" component={() => <MessagePage user={user} />} />
                                <Route path="*"><h5>404</h5></Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Show;