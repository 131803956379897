
import React, { useState } from 'react'
import './style.scss'
import { useQuery } from 'react-query'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { dateFormate } from '../../../../utils/helpers'
import { api } from '../../../../utils/api'
import 'react-toastify/dist/ReactToastify.css'
import { edit2, plus} from 'react-icons-kit/feather'
import GhostPackage from '../../../../components/ghostLoader/membershipPackage/Index'

toast.configure({ autoClose: 2000 })

const Comission = () => {
    // const history = useHistory();
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const getComission = async () => {
        const res = await Axios.get(`${api}ghotok/comission/index`, header)
        return res.data.percentageList
    }

    const { data, isLoading } = useQuery("percentageList", getComission, {
        retry: false,
        cacheTime:600000
    })


    if (isLoading) return <GhostPackage />
    // if (isError) return 'Something went wrong'

    return (
        <div className="ghotok-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div className="col-6"><h6 className="mb-2 d-inline">Comission</h6></div>
                                    <div className="col-6  text-right">
                                        <Link
                                                        
                                            to="/dashboard/create/comission"
                                            type="button"
                                            className="btnplus shadow-none rounded-circle"
                                        >
                                            <Icon icon={plus} size={22} />
                                        </Link>
                                    </div>
                                </div>
                                <br/>
                                <div className="card-body p-0">
                                        <div className="mb-2 col-12" >
                                        <table className="table table-responsive" >
                                    <thead >
                                        <tr >
                                            <td style={{width: "5%"}}>SL</td>
                                            <td style={{width: "30%"}}>Date</td>
                                            <td style={{width: "30%"}}> Comission <small>(%)</small></td>
                                            <td style={{width: "30%"}}>Description</td>
                                            {/* <td>Package Price<small> tk</small></td> */}
                                            <td className="text-center" style={{width: "30%"}}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {data && data.map((item, i) =>
                                            <tr className="border-bottom" key={i}>
                                                <td className="text-center">{i + 1}</td>
                                                <td>{dateFormate(item.createdAt)}</td>
                                                <td>{item.percentage}</td>
                                                <td>{item.description}</td>
                                                <td className="text-right">
                                                    <div className="btn-group">
                                                        {/* Show user */}
                                                        <Link
                                                            to={`/dashboard/comission/${item._id}/edit`}
                                                            type="button"
                                                            className="btn btn-sm shadow-none"
                                                        ><Icon icon={edit2} size={18} /></Link>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Float button */}
            <Link
                to="/dashboard/hrm/user"
                type="button"
                className="shadow-none rounded-circle text-white float-btn"
            >
                <Icon icon={plus} size={22} />
            </Link>
        </div>
    );
}

export default Comission;