import React, { useRef } from 'react'
import './style.scss'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    const refs = useRef()
    return (
        <div className="ghost-profiles">
            {[...Array(12).keys()].map((i) => {
                return (
                    <div className="ghost-profile-card" key={i} ref={refs}>
                        <div className="card-body shadow-sm py-4">
                            <Skeleton circle={true} width={90} height={90} />
                            <br />
                            <br />
                            <Skeleton width={refs.innerWidth} height={20} />
                            <Skeleton width={refs.innerWidth} height={20} />
                            <Skeleton width={refs.innerWidth} height={20} />
                            <Skeleton width={refs.innerWidth} height={20} />
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default Index;