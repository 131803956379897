import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../../utils/Requests/User"
import { optionContext } from '../../../../utils/Context/optionContext'
import SingleSelect from '../../../form/SingleSelect'

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })


const Two = ({ step, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [Edit, setEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register, formState: { errors } } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [qualification, setQualification] = useState({
        value: null,
        error: null,
        options: []
    })
    const [workingWith, setWorkingWith] = useState({
        value: null,
        error: null,
        options: []
    })
    const [professionArea, setProfessionArea] = useState({
        value: null,
        error: null,
        options: []
    })

    const [annualIncome, setAnnualIncome] = useState({
        value: null,
        error: null,
        options: []
    })
    useEffect(() => {
        setQualification({ ...qualification, options: handleOptionToData("Qualification") })
        setWorkingWith({ ...workingWith, options: handleOptionToData("Working with") })
        setProfessionArea({ ...professionArea, options: handleOptionToData("Profession area") })
        setAnnualIncome({ ...annualIncome, options: handleOptionToData("Income") })
    }, [options])

    const onSubmit = async (data) => {
        if (!qualification.value) return setQualification({ value: null, error: "Qualificaton is required." })
        if (!professionArea.value) return setProfessionArea({ value: null, error: "Profession area is required." })
        if (!workingWith.value) return setWorkingWith({ value: null, error: "Working with required." })
        if (!annualIncome.value) return setAnnualIncome({ value: null, error: "Annual Income is required." })

        setEdit({ ...Edit, loading: true })
        const stepData = {
            ...data,
            qualification: qualification.value,
            professionArea: professionArea.value,
            workingWith: workingWith.value,
            annualIncome: annualIncome.value
        }
        const response = await User.UpdateStepByStep(step, email, stepData, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setEdit({ show: false, loading: false })
        handleRefetch()
    }


    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0"> Registration Step 2 </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEdit({ ...Edit, show: !Edit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        Edit.show ? <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group mb-4">
                                {errors.name && <span className="text-danger">This city is required</span>}
                                <p>Name*</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Enter your city name"
                                    name="name"
                                    ref={register({ required: true })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {qualification.error && <span className="text-danger">{qualification.error}</span>}
                                <p> Highest qualification* </p>
                                <SingleSelect
                                    options={qualification.options}
                                    data={''}
                                    value={(data) => setQualification({ ...qualification, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {workingWith.error && <span className="text-danger">{workingWith.error}</span>}
                                <p>Work with</p>
                                <SingleSelect
                                    options={workingWith.options}
                                    data={''}
                                    value={(data) => setWorkingWith({ ...workingWith, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {professionArea.error && <span className="text-danger">{professionArea.error}</span>}
                                <p>Profession area</p>
                                <SingleSelect
                                    options={professionArea.options}
                                    data={''}
                                    value={(data) => setProfessionArea({ ...professionArea, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {annualIncome.error && <span className="text-danger">{annualIncome.error}</span>}
                                <p>Annual income</p>
                                <SingleSelect
                                    options={annualIncome.options}
                                    data={''}
                                    value={(data) => setAnnualIncome({ ...annualIncome, value: data })}
                                />
                            </div>


                            <div className="form-group mb-4">
                                <textarea
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Enter bio"
                                    name='shortDescription'
                                    ref={register({ required: true })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={Edit.loading}
                                >
                                    {Edit.loading ? 'Saving...' : 'Save '}
                                </button>
                            </div>
                        </form>
                            :
                            <p className="text-justify"> Registration Step 2 </p>
                    }
                </div>
            </div>
        </div>
    );
};

export default Two;