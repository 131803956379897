import React, { useState} from 'react'
import Axios from 'axios'
import { api } from '../../../utils/api'
import { Images } from '../../../utils/Images'
import { useParams } from 'react-router-dom'
import { useQuery } from "react-query";
import GhostTable from "../../../components/ghostLoader/table/Index";

const AgentsUser = () => {
    const { id } = useParams()
    const [header] = useState({headers: { Authorization: "Bearer " + localStorage.getItem('token') }})
    let [pageLoad, setPageload] = useState(true)

    const getAgentUserList = async () => {
        try {
            const res = await Axios.get(`${api}/ghotok/${id}/userlist`, header)
            if (res.status === 200) {
                setPageload(false)
                return res.data.users
            }
        } catch (error) {
            setPageload(false)
        }
    }

    const { data, isLoading, isError } = useQuery("agentUserList", getAgentUserList, {
        retry: false,
        cacheTime: 2100000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageLoad)
    })

    if (isLoading || isError) return <GhostTable />
    return (
        <div className='table-container'>
        <div className="p-3"><h6 className="mb-0 d-inline">Agents Userlist</h6></div>
        { data && data.length > 0 ?
          <table className="table table-responsive table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center">SL</td>
                        <td className="text-center">Image</td>
                        <td>Profile ID</td>
                        <td>Name</td>
                        <td>Joined</td>
                        <td>Profile Type</td>
                        <td>Material Status</td>
                        <td>Gender</td>
                        <td>Status</td>
                        <td>Admin</td>
                        <td>Active</td>
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((user, i) =>
                        <tr className="border-bottom" key={i}>
                            <td className="text-center">{i + 1}</td>
                            <td className="text-center">
                                <div className="image-container rounded-circle">
                                    <img src={user.image ? user.image : Images.Avatar} className="img-fluid" alt="..." />

                                </div>
                            </td>
                            <td>{user.profileId}</td>
                            <td>{user.name}</td>
                            <td>{user.createdAt}</td>
                            <td>{user.profileType}</td>
                            <td>{user.materialStatus}</td>
                            <td className="text-capitalize">{user.gender}</td>
                            <td className={user.isOnline === 'offline' ? 'text-muted text-capitalize' : 'text-success text-capitalize'}>
                                {user.isOnline}
                            </td>
                            <td>
                                {user.assignToAdmin === 'None' ? user.assignToAdmin : user.assignToAdmin.name}
                            </td>
                            <td className={user.activeStatus === 'deactivated' ? 'text-danger text-capitalize' : 'text-success text-capitalize'}>
                                {user.activeStatus}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            : <p className='text-center mt-5'>No such user found for this agent</p> }
        </div>
    );
}

export default AgentsUser;