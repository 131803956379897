import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}package/index`, header)
        if (response.status === 200) {
            return response.data.packages
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const Package = {
    Index
}

export default Package