import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import { optionContext } from '../../../utils/Context/optionContext'
import SingleSelect from '../../form/SingleSelect'

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const LocationOfGroom = ({ user, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [Edit, setEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })


    const [birthCountry, setBirthCountry] = useState({
        value: null,
        options: []
    })
    const [livingCountry, setLivingCountry] = useState({
        value: null,
        options: []
    })

    const [city, setCity] = useState({
        value: null,
        options: [
            { value: 'Bagerhat', label: "Bagerhat" },
            { value: 'Bandarban', label: "Bandarban" },
            { value: 'Barguna', label: "Barguna" },
            { value: 'Barisal', label: "Barisal" },
            { value: 'Bhola', label: "Bhola" },
            { value: 'Bogra', label: "Bogra" },
            { value: 'Brahmanbaria', label: "Brahmanbaria" },
            { value: 'Chandpur', label: "Chandpur" },
            { value: 'Chittagong', label: "Chittagong" },
            { value: 'Chuadanga', label: "Chuadanga" },
            { value: 'Comilla', label: "Comilla" },
            { value: "Cox'sBazar", label: "Cox'sBazar" },
            { value: 'Dhaka', label: "Dhaka" },
            { value: 'Dinajpur', label: "Dinajpur" },
            { value: 'Faridpur', label: "Faridpur" },
            { value: 'Feni', label: "Feni" },
            { value: 'Gaibandha', label: "Gaibandha" },
            { value: 'Gazipur', label: "Gazipur" },
            { value: 'Gopalganj', label: "Gopalganj" },
            { value: 'Habiganj', label: "Habiganj" },
            { value: 'Jaipurhat', label: "Jaipurhat" },
            { value: 'Jamalpur', label: "Jamalpur" },
            { value: 'Jessore', label: "Jessore" },
            { value: 'Jhalokati', label: "Jhalokati" },
            { value: 'Jhenaidah', label: "Jhenaidah" },
            { value: 'Khagrachari', label: "Khagrachari" },
            { value: 'Khulna', label: "Khulna" },
            { value: 'Kishoreganj', label: "Kishoreganj" },
            { value: 'Kurigram', label: "Kurigram" },
            { value: 'Kushtia', label: "Kushtia" },
            { value: 'Lakshmipur', label: "Lakshmipur" },
            { value: 'Lalmonirhat', label: "Lalmonirhat" },
            { value: 'Madaripur', label: "Madaripur" },
            { value: 'Magura', label: "Magura" },
            { value: 'Manikganj', label: "Manikganj" },
            { value: 'Maulvibazar', label: "Maulvibazar" },
            { value: 'Meherpur', label: "Meherpur" },
            { value: 'Munshiganj', label: "Munshiganj" },
            { value: 'Mymensingh', label: "Mymensingh" },
            { value: 'Naogaon', label: "Naogaon" },
            { value: 'Narail', label: "Narail" },
            { value: 'Narayanganj', label: "Narayanganj" },
            { value: 'Narsingdi', label: "Narsingdi" },
            { value: 'Natore', label: "Natore" },
            { value: 'Nawabganj', label: "Nawabganj" },
            { value: 'Netrokona', label: "Netrokona" },
            { value: 'Nilphamari', label: "Nilphamari" },
            { value: 'Noakhali', label: "Noakhali" },
            { value: 'Pabna', label: "Pabna" },
            { value: 'Panchagarh', label: "Panchagarh" },
            { value: 'Patuakhali', label: "Patuakhali" },
            { value: 'Pirojpur', label: "Pirojpur" },
            { value: 'Rajbari', label: "Rajbari" },
            { value: 'Rajshahi', label: "Rajshahi" },
            { value: 'Rangamati', label: "Rangamati" },
            { value: 'Rangpur', label: "Rangpur" },
            { value: 'Satkhira', label: "Satkhira" },
            { value: 'Shariatpur', label: "Shariatpur" },
            { value: 'Sherpur', label: "Sherpur" },
            { value: 'Sirajganj', label: "Sirajganj" },
            { value: 'Sunamganj', label: "Sunamganj" },
            { value: 'Sylhet', label: "Sylhet" },
            { value: 'Tangail', label: "Tangail" },
            { value: 'Thakurgaon', label: "Thakurgaon" },
        ]
    })


    useEffect(() => {
        setBirthCountry({ ...birthCountry, options: handleOptionToData("Country") })
        setLivingCountry({ ...livingCountry, options: handleOptionToData("Country") })
    }, [options])

    // on submit handler
    const onSubmit = async (data) => {
        let obj = {
            birthCountry: birthCountry.value,
            stateDevision: data.stateDevision ? data.stateDevision : null,
            livingCountry: livingCountry.value,
            city: city.value ? city.value : null
        }
        setEdit({ ...Edit, loading: true })
        const response = await User.UpdateLocationOfGroom(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setEdit({ show: false, loading: false })
        handleRefetch()
    }


    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Location Details </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEdit({ ...Edit, show: !Edit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        Edit.show ? <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group mb-4">
                                <p>Birth Country</p>
                                <SingleSelect
                                    options={birthCountry.options}
                                    data={user.birthCountry}
                                    value={(data) => setBirthCountry({ ...birthCountry, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>State / Devision</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    defaultValue={user.stateDevision || ''}
                                    name='stateDevision'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Living Country</p>
                                <SingleSelect
                                    options={livingCountry.options}
                                    data={user.livingCountry}
                                    value={(data) => setLivingCountry({ ...livingCountry, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>City</p>
                                <SingleSelect
                                    options={city.options}
                                    data={user.city}
                                    value={(data) => setCity({ ...city, value: data })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={Edit.loading}
                                >
                                    {Edit.loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Birth Country</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.birthCountry ? user.birthCountry : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>State / Devision</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.stateDevision ? user.stateDevision : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Living Country</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.livingCountry ? user.livingCountry : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>City</th>
                                                <td className="pl-md-5">:</td>
                                                <td>{user.city ? user.city : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default LocationOfGroom;