import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

const Edit = () => {
    const refs = useRef()

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm" style={styles.card}>
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div>
                                        <Skeleton width={200} height={40} />
                                    </div>
                                    <div className="ml-auto">
                                        <Skeleton width={100} height={40} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 mb-4" ref={refs}>
                                        <Skeleton width={refs.innerWidth} height={40} />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-4" ref={refs}>
                                        <Skeleton width={refs.innerWidth} height={40} />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-4" ref={refs}>
                                        <Skeleton width={refs.innerWidth} height={40} />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-4" ref={refs}>
                                        <Skeleton width={refs.innerWidth} height={40} />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-4" ref={refs}>
                                        <Skeleton width={refs.innerWidth} height={40} />
                                    </div>
                                    <div className="col-12 col-lg-6 mb-4" ref={refs}>
                                        <Skeleton width={refs.innerWidth} height={40} />
                                    </div>
                                    <div className="col-12 text-right mb-4">
                                        <Skeleton width={130} height={40} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Edit;
const styles = {
    card: {
        borderRadius: 6,
        overflow: 'hidden'
    }
}