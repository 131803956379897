import React from 'react'
import './style.scss'
import NoDataComponent from '../noData/Index'
import GhostTable from '../ghostLoader/table/Index'

const Index = ({ users}) => {
    
    if (!users) return (<GhostTable />)
    if (!users.length) return (<NoDataComponent />)


    return (
        <div className="category-list">
            <table className="table table-responsive table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center">SL</td>
                        <td className="text-center" style={{width: "28%"}}>Name</td>
                        <td className="text-center" style={{width: "25%"}}>Seeking Alliance For</td>
                        <td className="text-center" style={{width: "23%"}}>Email</td>
                        <td className="text-center" style={{width: "23%"}}>Phone</td>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map((item, i) =>
                        <tr className="border-bottom" key={i}>
                            <td className="text-center">{i + 1}</td>
                            <td className="text-center">{item.name.firstName} {item.name.lastName}</td>
                            <td className="text-center">{item.seekingAllianceFor}</td>
                            <td className="text-center">{item.email}</td>
                            <td className="text-center">{item.phone}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Index;