import React, { useState } from 'react'
import './style.scss'
import { Form } from 'react-bootstrap'
import Icon from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather'
import Requests from '../../utils/Requests/Index'
import DeleteComponent from '../modal/delete/Index'

const Banner = ({ items, refetch }) => {
    const [isDelete, setDelete] = useState({ value: null, show: false, loading: false })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Handle status
    const handleStatus = async (event, id) => {
        try {
            let isActive
            const value = Boolean(event.target.value)
            isActive = !value
            await Request.Banner.Update(id, isActive, header)
        } catch (error) {
            if (error) console.log(error)
        }
    }

    const handleDelete = async id => {
        setDelete({ ...isDelete, loading: true })
        await Requests.Banner.Delete(id, header)
        refetch()
        setDelete({ value: null, status: null, loading: null })
    }

    return (
        <div className="content-image-table-container pb-4">
            <table className="table table-hover table-responsive-sm table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center">SL</td>
                        <td>Banner</td>
                        <td>Name</td>
                        <td>Created by</td>
                        <td className="text-center">Status</td>
                        <td className="text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom" key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td><img src={item.bannerImage} className="img-fluid" alt="..." /></td>
                                <td>{item.name}</td>
                                <td>
                                    {item.admin ? <p className="text-capitalize mb-0">{item.admin.name}</p> : null}
                                    {item.admin ? <small className="text-lowercase mb-0"><i>{item.admin.email}</i></small> : null}
                                </td>
                                <td className="button-td text-center">
                                    <Form.Check
                                        type="switch"
                                        id={item._id}
                                        value={item.isActive}
                                        defaultChecked={item.isActive}
                                        onChange={(event) => handleStatus(event, item._id)}
                                    />
                                </td>
                                <td className="button-td text-center">
                                    <button
                                        type="button"
                                        className="btn btn-sm shadow-none table-trash-btn"
                                        onClick={() => setDelete({ value: item, show: true, loading: false })}
                                    >
                                        <Icon icon={trash} size={20} />
                                    </button>
                                </td>
                            </tr>
                        ) : null}
                </tbody>
            </table>

            {/* Delete modal */}
            {isDelete.value && isDelete.show ?
                <DeleteComponent
                    title={'Banner'}
                    image={isDelete.value.bannerImage}
                    data={isDelete.value._id}
                    show={isDelete.show}
                    loading={isDelete.loading}
                    delete={handleDelete}
                    onHide={() => setDelete({ ...isDelete, value: null, status: false })}
                />
                : null}

        </div>
    );
};

export default Banner;