import jwt_decode from "jwt-decode"
import {api} from './api'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { userSetter } from '../utils/Redux/Slice/User/Index'

export const checkIfLoggedIn =async () => {
    const token = localStorage.getItem("token");
    if (token) {
        const dispatch = useDispatch()
        const user = jwt_decode(token);
        // if (user.role === 'super_admin') {
        //     return ({ role: user.role })
        // }
       const res =  await axios.get(`${api}auth/show/${user.id}`)
        if (user && res.status === 200) {
            dispatch(userSetter(res.data.results))
            return true
        }
        return false
    }
    return false
}

export const checkUserRole = () => {
    const token = localStorage.getItem("token");
    if (token) {
        const user = jwt_decode(token);
        if (user) {
            return user.role
        }
        return false
    }
    return false
}