import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

const Create = () => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            await Requests.Settings.Store(data, header)
            setLoading(false)
        } catch (error) {
            if (error) console.log(error.response)
        }
    }

    return (
        <div className="item-store pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Create field</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            type="button"
                                            to="/dashboard/website/settings-field"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="card-body p-3 p-lg-4">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* Title */}
                                    <div className="form-group mb-4">
                                        {errors.title && errors.title.message ? (
                                            <p className="text-danger">{errors.title && errors.title.message}</p>
                                        ) : <p>Title</p>}

                                        <input
                                            type="text"
                                            name="title"
                                            className="form-control shadow-none"
                                            placeholder="Enter field title"
                                            ref={register({ required: "Title is required" })}
                                        />
                                    </div>


                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? <span>Creating...</span> : <span>Create</span>}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;