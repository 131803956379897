import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { useHistory, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft } from 'react-icons-kit/feather'
import CustomeSelect from '../../../../components/select/CustomeSelect'
import { toast } from 'react-toastify'
import { api } from "../../../../utils/api";
import Axios from 'axios'
import jwt_decode from "jwt-decode"
import { dateFormate, StringShort } from '../../../../utils/helpers'

const Show = () => {
    const { id } = useParams()
    const [item, setItem] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const [expenseData, setExpenseData] = useState(null)


    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const [response, expense] = await Promise.all([
                Axios.get(`${api}auth/index`, header),
                Axios.get(`${api}account/expense/getone/${id}`, header)
            ])
            if (response.status === 200 && expense.status === 200) {
                const data = expense.data.expense
                setExpenseData(data)
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    if (!expenseData) return 'loading'

    return (
        <div className="sales-show pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header text-right p-3 p-lg-4 bg-white">
                                <Link
                                    to="/dashboard/accounts/expense"
                                    type="button"
                                    className="btn shadow-none rounded-circle"
                                >
                                    <Icon icon={chevronLeft} size={22} />
                                </Link>
                            </div>
                            <div className="card-body p-4">
                                <div className="d-sm-flex coupon-profile-container">
                                    {/* Name circle */}
                                    <div className="name-circle rounded-circle flex-center flex-column">
                                        <h1>{StringShort(expenseData.careOf.name)}</h1>
                                    </div>

                                    {/* Content container */}
                                    <div className="flex-fill content-container pt-sm-3 pl-sm-4">
                                        <table className="table-sm">
                                            <tbody>
                                                <tr>
                                                    <td className="title-td">Name</td>
                                                    <td>: {expenseData.careOf.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="title-td">Email</td>
                                                    <td className="text-lowercase">: {expenseData.careOf.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row coupon-items-container">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white border-0">
                                <h6 className="mb-0">Expanse Info.</h6>
                            </div>
                            <div className="card-body">
                                <div className="d-flex">
                                    <div style={{ width: 200 }}>
                                        <p>Transaction Date</p>
                                        <p>Amount</p>
                                        <p>Payment method</p>
                                        <p>Payment Type</p>
                                        <p>Comment</p>
                                        <p>Last Update Date</p>
                                    </div>
                                    <div>
                                        <p>: {dateFormate(expenseData.createdAt)}</p>
                                        <p>: {expenseData.amount} {expenseData.currency}</p>
                                        <p>: {expenseData.method ? expenseData.method : "--"}</p>
                                        <p>: {expenseData.type ? expenseData.type : "--"}</p>
                                        <p>: {expenseData.comment ? expenseData.comment : "--"}</p>
                                        <p>: {dateFormate(expenseData.updatedAt)}</p>
                                    </div>
                                </div>
                            </div>

                            {expenseData.createdBy ?
                                <div className="card-footer bg-white text-right border-0">
                                    <p className="mb-1" style={{ fontSize: 14 }}><i><b>Created By</b></i></p>

                                    <div>
                                        <p className="text-capitalize mb-0" style={{ fontSize: 13 }}>{expenseData.createdBy.name}</p>
                                        <small><i>{expenseData.createdBy.email}</i></small>
                                    </div>
                                </div>
                                : null}
                        </div>

                        {
                            expenseData.file
                                ? <object width="100%" height="400px" data={`${expenseData.file}`}></object>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;