import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useHistory, useParams } from 'react-router-dom'
import { chevronLeft, plus } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

import LoadingComponent from '../../../../components/loading/Index'

const Edit = () => {
    const { id } = useParams()
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [item, setItem] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.AboutUs.Show(id, header)
        if (data) {
            setItem(data)
            setLoading(false)
        }
        setLoading(false)
    }, [id, header])

    useEffect(() => {
        fetchData()
    }, [id, header, fetchData])


    // Submit Form
    const onSubmit = async (data) => {
        try {
            setUpdate(true)
            await Requests.AboutUs.Update(id, data, header)
            setUpdate(false)
            history.push('/dashboard/website/about-us')
        } catch (error) {
            if (error) console.log(error)
        }
    }

    if (isLoading) return <LoadingComponent />

    return (
        <div className="store-story pb-4">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Edit Story</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/about-us"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* paragraph_1 1 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_1 && errors.paragraph_1.message ? (
                                            <p className="text-danger">{errors.paragraph_1 && errors.paragraph_1.message}</p>
                                        ) : <p>paragraph_1</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_1"
                                            defaultValue={item.paragraph_1}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_1"
                                            ref={register({
                                                required: "paragraph_1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_2  */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_2 && errors.paragraph_2.message ? (
                                            <p className="text-danger">{errors.paragraph_2 && errors.paragraph_2.message}</p>
                                        ) : <p>paragraph_2</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_2"
                                            defaultValue={item.paragraph_2}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_2"
                                            ref={register({
                                                required: "paragraph_2 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_3  */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_3 && errors.paragraph_3.message ? (
                                            <p className="text-danger">{errors.paragraph_3 && errors.paragraph_3.message}</p>
                                        ) : <p>paragraph_3</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_3"
                                            defaultValue={item.paragraph_3}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_3"
                                            ref={register({
                                                required: "paragraph_3 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_4 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_4 && errors.paragraph_4.message ? (
                                            <p className="text-danger">{errors.paragraph_4 && errors.paragraph_4.message}</p>
                                        ) : <p>paragraph_4</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_4"
                                            defaultValue={item.paragraph_4}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_4"
                                            ref={register({
                                                // required: "paragraph_4 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_5 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_5 && errors.paragraph_5.message ? (
                                            <p className="text-danger">{errors.paragraph_5 && errors.paragraph_5.message}</p>
                                        ) : <p>paragraph_5</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_5"
                                            defaultValue={item.paragraph_5}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_5"
                                            ref={register({
                                            //     required: "paragraph_5 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_6 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_6 && errors.paragraph_6.message ? (
                                            <p className="text-danger">{errors.paragraph_6 && errors.paragraph_6.message}</p>
                                        ) : <p>paragraph_6</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_6"
                                            defaultValue={item.paragraph_6}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_6"
                                            ref={register({
                                                // required: "paragraph_6 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_7 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_7 && errors.paragraph_7.message ? (
                                            <p className="text-danger">{errors.paragraph_7 && errors.paragraph_7.message}</p>
                                        ) : <p>paragraph_7</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_7"
                                            defaultValue={item.paragraph_7}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_7"
                                            ref={register({
                                            //     required: "paragraph_7 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_8 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_8 && errors.paragraph_8.message ? (
                                            <p className="text-danger">{errors.paragraph_8 && errors.paragraph_8.message}</p>
                                        ) : <p>paragraph_8</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_8"
                                            defaultValue={item.paragraph_8}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_8"
                                            ref={register({
                                            //     required: "paragraph_8 is required"
                                            })}
                                        />
                                    </div>

                                    {/* paragraph_9 */}
                                    <div className="form-group mb-4">
                                        {errors.paragraph_9 && errors.paragraph_9.message ? (
                                            <p className="text-danger">{errors.paragraph_9 && errors.paragraph_9.message}</p>
                                        ) : <p>paragraph_9</p>}

                                        <textarea
                                            type="text"
                                            name="paragraph_9"
                                            defaultValue={item.paragraph_9}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short paragraph_9"
                                            ref={register({
                                            //     required: "paragraph_9 is required"
                                            })}
                                        />
                                    </div>


                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={update}>
                                            {update ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Edit;
