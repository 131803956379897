import React, { useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { chevronsRight } from 'react-icons-kit/feather'
import GhostGallery from '../../../../../components/ghostLoader/user/galleryLoader/Index'

const Index = () => {
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    return (
        <div className="gallery-container">
            <div className="card border-0 shadow-sm">
                <div className="card-header bg-white p-3 p-lg-4">
                    <div className="title-container">
                        <div className="d-flex">
                            <div>
                                <Icon icon={chevronsRight} size={25} />
                            </div>
                            <div><h5 className="mb-0">Uploaded images</h5></div>
                        </div>
                    </div>
                </div>

                <div className="card-body p-2">
                    {isLoading ?
                        <GhostGallery /> :

                        [...Array(30).keys()].map((i) => {
                            return (
                                <div className="image-container" key={i}>
                                    <img src={'https://source.unsplash.com/XtUd5SiX464'} className="img-fluid" alt="..." />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Index;