import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import Axios from 'axios'
import { useMutation } from 'react-query'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft, plus } from 'react-icons-kit/feather'
import { useHistory, useParams, } from 'react-router-dom'
import Requests from '../../../../utils/Requests/Index'


toast.configure({ autoClose: 2000 })
const EditGhotokProfile = (props) => {
    const { id } = useParams()
    const { register, handleSubmit, errors } = useForm()
    const [error] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null)
    const [item, setItem] = useState(null)
    const [name, setName] = useState("");
    const [facebook, setFacebook] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [whatsAppOrImo, setWhatsAppOrImo] = useState("");
    const [gender, setGender] = useState("");
    const [referenceWay, setReferenceWay] = useState("");
    const [mobileBankingNumber, setMobileBankingNumber] = useState("");
    const [mobileNumberFor, setMobileNumberFor] = useState("");
    const [NIDNo, setNIDNo] = useState("");
    const [NIDImage, setNIDImage] = useState("");
    const [anyBillCopy, setAnyBillCopy] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [status, setStatus] = useState("");
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    const handleProfileImage = async (event) => {
        const file = event.target.files[0]
        if (file) {
            // const size = parseInt(file.size) / 1000
            // if (size > 800) {
            //     setError({ ...error, profileImage: 'Select less than 800KB file.' })
            //     return
            // }
            setSelectedFile({ profileImage: file, preview: URL.createObjectURL(file) })

            let formData = new FormData()
            formData.append('profileImage', file)
            setItem({ ...item, profileImage: null })
            const response = await Requests.Ghotok.UpdateProfileImage(id, formData, header)
            setLoading(true)
            return response
            // if (response.status === true) {
            //     toast.success(response.message)
                
            //     getUser()
            // }
        }
    }

    const handleNIDImage = async (event) => {
        const file = event.target.files[0]
        if (file) {
            // const size = parseInt(file.size) / 1000
            // if (size > 800) {
            //     setError({ ...error, NIDImage: 'Select less than 800KB file.' })
            //     return
            // }
            setSelectedFile({ NIDImage: file, preview: URL.createObjectURL(file) })

            let formData = new FormData()
            formData.append('NIDImage', file)
            setItem({ ...item, NIDImage: null })
            const response = await Requests.Ghotok.UpdateNIDImage(id, formData, header)
            if (response.status === true) {
            //     toast.success(response.message)
            //     setLoading(true)
            //     getUser()
            }
        }
    }

    const handleAnyBillCopyImage = async (event) => {
        const file = event.target.files[0]
        if (file) {
            // const size = parseInt(file.size) / 1000
            // if (size > 800) {
            //     setError({ ...error, anyBillCopy: 'Select less than 800KB file.' })
            //     return
            // }
            setSelectedFile({ anyBillCopy: file, preview: URL.createObjectURL(file) })

            let formData = new FormData()
            formData.append('anyBillCopy', file)
            setItem({ ...item, anyBillCopy: null })
            const response = await Requests.Ghotok.UpdateAnyBillCopyImage(id, formData, header)
            if (response.status === true) {
            //     toast.success(response.message)
                // setLoading(true)
            //     getUser()
            }
        }
    }

    const GhotokProfileEdit = async (data) => {
        await Requests.Ghotok.Update(id, data, header)
    }

    const { mutate, isError } = useMutation(GhotokProfileEdit, {
        onSuccess: (data) => {
            goToPreviousPath()
            
            toast.success(data)
        }
    })

    // get user 
    const getUser = useCallback(async () => {
        try {
            const response = await Axios.get(`${api}ghotok/${id}/show`, header)
            if (response.status === 200) {
                setName(response.data.profile.name)
                setFacebook(response.data.profile.fbPageUrl)
                setEmail(response.data.profile.email)
                setPhone(response.data.profile.phone)
                setWhatsAppOrImo(response.data.profile.whatsappOrImo)
                setAddress(response.data.profile.address)
                setGender(response.data.profile.gender)
                setReferenceWay(response.data.profile.referenceWay)
                setMobileBankingNumber(response.data.profile.mobileBankingNumber)
                setMobileNumberFor(response.data.profile.mobileNumberFor)
                setNIDNo(response.data.profile.NIDNo)
                setNIDImage(response.data.profile.NIDImage)
                setAnyBillCopy(response.data.profile.anyBillCopy)
                setProfileImage(response.data.profile.profileImage)
                setStatus(response.data.profile.status)
                setLoading(false)
            }
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }, [id, header])

    useEffect(() => {
        getUser()
    }, [id, header, getUser])


    // Submit Form
    const onSubmit = async (data) => {
        try {
            mutate(data, header)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }
    if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Update Agent Profile</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            onClick={goToPreviousPath}
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Name */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.name && errors.name.message ? (
                                                    <p className="text-danger">{errors.name && errors.name.message}</p>
                                                ) : <p>Name</p>}

                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={name}
                                                    ref={register({ required: "Name is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Facebook ID */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                
                                                <p>Facebook ID</p>
                                                <input
                                                    type="text"
                                                    name="fbPageUrl"
                                                    placeholder="Enter Facebook ID"
                                                    className={errors.fbPageUrl ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={facebook}
                                                    ref={register({ })}
                                                />
                                            </div>
                                        </div>

                                        {/* Email */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.email && errors.email.message ? (
                                                    <p className="text-danger">{errors.email && errors.email.message}</p>
                                                ) : <p>Email</p>}

                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={email}
                                                    ref={register({ required: "Email is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Phone */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.phone && errors.phone.message ? (
                                                    <p className="text-danger">{errors.phone && errors.phone.message}</p>
                                                ) : <p>Phone</p>}

                                                <input
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Enter phone"
                                                    className={errors.phone ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={phone}
                                                    ref={register({ required: "phone is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* WhatsApp Or Imo */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.whatsappOrImo && errors.whatsappOrImo.message ? (
                                                    <p className="text-danger">{errors.whatsappOrImo && errors.whatsappOrImo.message}</p>
                                                ) : <p>WhatsApp Or Imo</p>}

                                                <input
                                                    type="text"
                                                    name="whatsappOrImo"
                                                    placeholder="Enter WhatsApp Or Imo Number"
                                                    className={errors.whatsappOrImo ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={whatsAppOrImo}
                                                    ref={register({ required: "whatsappOrImo is required" })}
                                                />
                                            </div>
                                        </div>
                                        {/* Address */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.address && errors.address.message ? (
                                                    <p className="text-danger">{errors.address && errors.address.message}</p>
                                                ) : <p>Address</p>}

                                                <input
                                                    type="text"
                                                    name="address"
                                                    placeholder="Enter Address"
                                                    className={errors.address ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={address}
                                                    ref={register({ required: "address is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Gender */}
                                        <div className="col-12 col-lg-6" >
                                            <div className="form-group mb-4">
                                                {errors.gender && errors.gender.message ? (
                                                    <p className="text-danger">{errors.gender && errors.gender.message}</p>
                                                ) : <p>Gender</p>}

                                                <select
                                                    name="gender"
                                                    defaultValue={gender}
                                                    className={errors.gender ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "gender is required" })}
                                                >
                                                    {gender === "Male" ? (<>
                                                        <option value="Male" > Male</option>
                                                        <option value="Female">Female</option>
                                                    </>) : (<>
                                                        <option value="Female">Female</option>
                                                        <option value="Male" > Male</option>
                                                    </>)}

                                                </select>
                                            </div>
                                        </div>


                                        {/* Reference Way */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {/* {errors.referenceWay && errors.referenceWay.message ? (
                                                    <p className="text-danger">{errors.referenceWay && errors.referenceWay.message}</p>
                                                ) : <p>Reference Way</p>} */}
                                                <p>Reference Way</p>

                                                <input
                                                    type="text"
                                                    name="referenceWay"
                                                    placeholder="Enter Reference Way"
                                                    className={errors.referenceWay ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={referenceWay}
                                                    ref={register({})}
                                                />
                                            </div>
                                        </div>

                                        {/* Mobile Banking Number */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.mobileBankingNumber && errors.mobileBankingNumber.message ? (
                                                    <p className="text-danger">{errors.mobileBankingNumber && errors.mobileBankingNumber.message}</p>
                                                ) : <p>Mobile Banking Number</p>}

                                                <input
                                                    type="text"
                                                    name="mobileBankingNumber"
                                                    placeholder="Enter Reference Way"
                                                    className={errors.mobileBankingNumber ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={mobileBankingNumber}
                                                    ref={register({ required: "mobileBankingNumber is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Mobile Number For */}
                                        <div className="col-12 col-lg-6" >
                                            <div className="form-group mb-4">
                                                {errors.mobileNumberFor && errors.mobileNumberFor.message ? (
                                                    <p className="text-danger">{errors.mobileNumberFor && errors.mobileNumberFor.message}</p>
                                                ) : <p>Method</p>}

                                                <select
                                                    name="mobileNumberFor"
                                                    defaultValue={mobileNumberFor}
                                                    className={errors.mobileNumberFor ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "mobileNumberFor is required" })}
                                                >
                                                    {mobileNumberFor === "Bkash" ? (<>
                                                        <option value="Bkash" >Bkash</option>
                                                        <option value="Nagad">Nagad</option>
                                                        <option value="Rocket">Rocket</option>
                                                    </>) : (mobileNumberFor === "Nagad" ? (<>
                                                        <option value="Nagad">Nagad</option>
                                                        <option value="Bkash" > Bkash</option>
                                                        <option value="Rocket">Rocket</option>
                                                    </>) : <>
                                                        <option value="Rocket">Rocket</option>
                                                        <option value="Bkash" > Bkash</option>
                                                        <option value="Nagad">Nagad</option>
                                                    </>)
                                                    }

                                                </select>
                                            </div>
                                        </div>

                                        {/* status */}
                                        <div className="col-12 col-lg-6" >
                                            <div className="form-group mb-4">
                                                {errors.status && errors.status.message ? (
                                                    <p className="text-danger">{errors.status && errors.status.message}</p>
                                                ) : <p>Status</p>}

                                                <select
                                                    name="status"
                                                    defaultValue={status}
                                                    className={errors.status ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "status is required" })}
                                                >
                                                    {status === "Pending" ? (<>
                                                        <option value="Pending" >Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Reject">Reject</option>
                                                    </>) : (status === "Approved" ? (<>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Pending" > Pending</option>
                                                        <option value="Reject">Reject</option>
                                                    </>) : <>
                                                        <option value="Reject">Reject</option>
                                                        <option value="Approved" > Approved</option>
                                                        <option value="Pending">Pending</option>
                                                    </>)
                                                    }

                                                </select>
                                            </div>
                                        </div>

                                        {/* NIDNo */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.NIDNo && errors.NIDNo.message ? (
                                                    <p className="text-danger">{errors.NIDNo && errors.NIDNo.message}</p>
                                                ) : <p>NIDNo</p>}

                                                <input
                                                    type="text"
                                                    name="NIDNo"
                                                    placeholder="Enter Reference Way"
                                                    className={errors.NIDNo ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={NIDNo}
                                                    ref={register({ required: "NIDNo is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Profile Image */}
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group mb-4">
                                                {error && error.profileImage ? <p className="text-danger">{error.profileImage}</p> : <p>profile Image</p>}
                                                <div className="d-flex">
                                                    {profileImage ?
                                                    <div className="img-container text-center mr-2">
                                                        <div className="image border">
                                                            <img src={profileImage} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    : selectedFile ?
                                                    <div className="img-container text-center mr-2">
                                                        <div className="image border">
                                                            <img src={selectedFile.preview} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    : null}

                                                    <div className="img-container text-center">
                                                        <div className="image border">
                                                            <input type="file" className="upload" onChange={handleProfileImage} />
                                                            <div className="flex-center flex-column">
                                                                <Icon icon={plus} size={22} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        {/* NID Photo*/}
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group mb-4">
                                                {error && error.NIDImage ? <p className="text-danger">{error.NIDImage}</p> : <p>NID Image</p>}
                                                <div className="d-flex">
                                                    {NIDImage ?
                                                        <div className="img-container text-center mr-2">
                                                            <div className="image border">
                                                                <img src={NIDImage} className="img-fluid" alt="..." />
                                                            </div>
                                                        </div>
                                                        : selectedFile ?
                                                            <div className="img-container text-center mr-2">
                                                                <div className="image border">
                                                                    <img src={selectedFile.preview} className="img-fluid" alt="..." />
                                                                </div>
                                                            </div>
                                                            : null}

                                                    <div className="img-container text-center">
                                                        <div className="image border">
                                                            <input type="file" className="upload" onChange={handleNIDImage} />
                                                            <div className="flex-center flex-column">
                                                                <Icon icon={plus} size={22} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Any Bill Copy Image */}
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group mb-4">
                                                {error && error.anyBillCopy ? <p className="text-danger">{error.anyBillCopy}</p> : <p>Any Bill Copy Image</p>}
                                                <div className="d-flex">
                                                    {anyBillCopy ?
                                                        <div className="img-container text-center mr-2">
                                                            <div className="image border">
                                                                <img src={anyBillCopy} className="img-fluid" alt="..." />
                                                            </div>
                                                        </div>
                                                        : selectedFile ?
                                                            <div className="img-container text-center mr-2">
                                                                <div className="image border">
                                                                    <img src={selectedFile.preview} className="img-fluid" alt="..." />
                                                                </div>
                                                            </div>
                                                    : null}

                                                    <div className="img-container text-center">
                                                        <div className="image border">
                                                            <input type="file" className="upload" onChange={handleAnyBillCopyImage} />
                                                            <div className="flex-center flex-column">
                                                                <Icon icon={plus} size={22} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Updating...' : 'Update'}</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default EditGhotokProfile;