import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { messageCircle } from 'react-icons-kit/feather'
import { Link } from 'react-router-dom'

const Index = ({ messages, type }) => {

    return (
        <div className="admin-message-list">

            {type === 'sms' ?
                <div>
                    {messages && messages.map((item, i) =>
                        <div className="message-container d-flex mb-4" key={i}>
                            <div><Icon icon={messageCircle} style={{ color: '#dfdfdf' }} size={30} /></div>
                            <div className="pl-3">
                                <h6>Send by. <span className="text-capitalize">{item.sender.name}</span></h6>
                                <small className="text-muted">{item.createdAt}</small>
                                <br />

                                <div className="message-content">
                                    <p>{item.message}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                : type === 'mail' ?
                    <div>
                        {messages && messages.map((item, i) =>
                            <div className="message-container d-flex mb-4" key={i}>
                                <div><Icon icon={messageCircle} style={{ color: '#dfdfdf' }} size={30} /></div>
                                <div className="pl-3">
                                    <h6>Send by. <span className="text-capitalize">{item.sender.name}</span></h6>
                                    <small className="text-muted">{item.createdAt}</small>
                                    <br />

                                    <div className="message-content">
                                        <p className="mail-subject mb-2">Subject: {item.message}</p>
                                        <p className="mail-content">{item.message}</p>
                                        <p className="profile-users">Send profiles: [ {item.profiles && item.profiles.map((mail, i) =>
                                            <Link to={`/dashboard/user/${mail}/show`} key={i}>{`${mail}, `}</Link>)} ]</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    : null}

        </div>
    );
}

export default Index;