import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft } from 'react-icons-kit/feather'

import Requests from '../../../../utils/Requests/Index'
import SingleSelect from '../../../../components/select/Single'
import LoadingComponent from '../../../../components/loading/Index'

const Edit = () => {
    const { id } = useParams()
    const { register, handleSubmit, errors } = useForm()
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const [item, setItem] = useState(null)
    const [packageId, setPackageId] = useState({ value: null, options: [], error: null })
    const [isLoading, setLoading] = useState(true)
    const [isUpdate, setUpdate] = useState(false)

    const handlePackage = event => setPackageId({ ...packageId, value: event.value, error: null })

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const itemResult = await Requests.PackageSales.Show(id, header)
            const packageResult = await Requests.Package.Index(header)

            if (itemResult) setItem(itemResult[0])
            if (itemResult && packageResult) setPackageId({
                ...packageId,
                value: itemResult[0].packageId._id,
                options: packageResult && packageResult.map(item => ({
                    label: item.title + ' - ' + item.type,
                    value: item._id,
                    currency: item.currency,
                    currencyType: item.currencyType
                }))
            })
            setLoading(false)
        } catch (error) {
            if (error) setLoading(false)
        }
    }, [header, id])

    useEffect(() => {
        fetchData()
    }, [header, id, fetchData])

    // Submit Form
    const onSubmit = async (data) => {
        try {
            if (!packageId.value) return setPackageId({ ...packageId, error: 'Package is required' })

            setUpdate(true)
            const formData = {
                ...data,
                packageId: item.packageId._id || packageId.value.value
            }

            await Requests.PackageSales.Update(id, formData, header)
            setUpdate(false)

        } catch (error) {
            if (error) console.log(error)
        }
    }

    if (isLoading) return <LoadingComponent />

    return (
        <div className="store-income pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Edit sales</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/accounts/sales"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* package */}
                                        <div className="col-12">
                                            <div className="form-group mb-4">
                                                {packageId.error ? <p className="text-danger">{packageId.error}</p> : <p>Package</p>}

                                                <SingleSelect
                                                    placeholder="package"
                                                    options={packageId.options}
                                                    value={handlePackage}
                                                    deafult={[{ _id: item.packageId._id, name: item.packageId.title + ' - ' + item.packageId.type }]}
                                                />
                                            </div>
                                        </div>

                                        {/* Amount */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.amount && errors.amount.message ? (
                                                    <p className="text-danger">{errors.amount && errors.amount.message}</p>
                                                ) : <p>Amount</p>}

                                                <input
                                                    type="number"
                                                    name="amount"
                                                    placeholder="Enter amount"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Amount is required" })}
                                                    defaultValue={item.amount}
                                                />
                                            </div>
                                        </div>

                                        {/* Currency */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.currency && errors.currency.message ? (
                                                    <p className="text-danger">{errors.currency && errors.currency.message}</p>
                                                ) : <p>Select currency</p>}

                                                <select
                                                    name="currency"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Currency is required" })}
                                                    defaultValue={item.currency}
                                                >
                                                    <option value="BDT">BDT</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>


                                        {/* Payment method */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.paymentMethod && errors.paymentMethod.message ? (
                                                    <p className="text-danger">{errors.paymentMethod && errors.paymentMethod.message}</p>
                                                ) : <p>Payment method</p>}

                                                <select
                                                    name="paymentMethod"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Payment method is required" })}
                                                    defaultValue={item.paymentMethod}
                                                >
                                                    <option value="Cash">Cash</option>
                                                    <option value="Bkash">Bkash</option>
                                                    <option value="Rocket">Rocket</option>
                                                    <option value="Bank">Bank</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Payment details */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.paymentDetails && errors.paymentDetails.message ? (
                                                    <p className="text-danger">{errors.paymentDetails && errors.paymentDetails.message}</p>
                                                ) : <p>Payment details</p>}

                                                <input
                                                    type="text"
                                                    name="paymentDetails"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter details"
                                                    ref={register({ required: "Payment details is required" })}
                                                    defaultValue={item.paymentDetails}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {/* Comment */}
                                    <div className="form-group mb-4">
                                        {errors.comment && errors.comment.message ? (
                                            <p className="text-danger">{errors.comment && errors.comment.message}</p>
                                        ) : <p>Comment</p>}

                                        <textarea
                                            name="comment"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Write comment"
                                            ref={register()}
                                            defaultValue={item.comment}
                                        />
                                    </div>


                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isUpdate}>
                                            {isUpdate ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Edit;