import React, { useState, useEffect } from 'react'
import Icon from 'react-icons-kit'
import { chevronsRight } from 'react-icons-kit/feather'
import PostLists from '../../../../../components/users/postsList/Index'
import GhostPost from '../../../../../components/ghostLoader/user/postLoader/Index'

const Index = () => {
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    return (
        <div className="title-container pl-1">
            <div className="d-flex">
                <div>
                    <Icon icon={chevronsRight} size={25} />
                </div>
                <div><h5 className="mb-3">Timeline Posts</h5></div>
            </div>

            {isLoading ?
                <GhostPost /> :
                <PostLists />
            }
        </div>
    );
}

export default Index;