import React from 'react'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    return (
        <div>
            <div className="payment-history">
                <div className="container-fluid">
                    <div className="row">

                        {/* Package information */}
                        <div className="col-12 col-padding pb-3">
                            <div className="card border-0 shadow-sm package-card">
                                <div className="card-header bg-white p-lg-4">
                                    <div className="d-flex">
                                        <div><Skeleton width={230} height={30} /></div>
                                        <div className="ml-auto">
                                            <Skeleton width={35} height={35} circle={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-lg-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <div><Skeleton width={250} height={15} /></div>
                                            <div><Skeleton width={250} height={15} /></div>
                                            <div><Skeleton width={250} height={15} /></div>
                                        </div>
                                        <div className="col-6 d-none d-sm-block">
                                            <div><Skeleton width={250} height={15} /></div>
                                            <div><Skeleton width={250} height={15} /></div>
                                            <div><Skeleton width={250} height={15} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Payment history */}
                        <div className="col-12 col-padding pb-3">
                            <div className="card border-0 shadow-sm history-card">
                                <div className="card-header bg-white p-lg-4">
                                    <div className="d-flex">
                                        <div><Skeleton width={200} height={30} /></div>
                                        <div className="ml-auto">
                                            <Skeleton width={100} height={35} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td className="text-center" style={{ width: 100 }}>
                                                    <Skeleton width={60} height={20} />
                                                </td>
                                                <td><Skeleton width={90} height={20} /></td>
                                                <td><Skeleton width={120} height={20} /></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(7).keys()].map((i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-center" style={{ width: 100 }}>
                                                            <Skeleton width={60} height={20} />
                                                        </td>
                                                        <td><Skeleton width={90} height={20} /></td>
                                                        <td><Skeleton width={120} height={20} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;