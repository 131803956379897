import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../../utils/Requests/User"
import { MaterialStatus } from "../../../OptionsData/MaterialStatus/MaterialStatus"
import { Height } from "../../../OptionsData/HeightData/Heightdata"
import InputRange from 'react-input-range'
import "react-input-range/lib/css/index.css"

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { optionContext } from '../../../../utils/Context/optionContext'
import { HeightCalculate } from '../../../../utils/helpers'
import SingleSelect from '../../../form/SingleSelect'
toast.configure({ autoClose: 2000 })

const BasicInformations = ({ user, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [Edit, setEdit] = useState({ show: false, loading: false })
    const { handleSubmit } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [religion, setReligion] = useState({
        value: null,
        options: []
    })
    const [motherTongue, setMotherTongue] = useState({
        value: null,
        options: []
    })
    const [materialStatus, setMaterialStatus] = useState({
        value: null,
        options: MaterialStatus
    })
    const [ages, setAges] = useState({
        min: 25,
        max: 35
    })
    const [height1, setHeight1] = useState({
        value: null,
        options: Height
    })
    const [height2, setHeight2] = useState({
        value: null,
        options: Height
    })
    useEffect(() => {
        setReligion({ ...religion, options: handleOptionToData("Religion") })
        setMotherTongue({ ...motherTongue, options: handleOptionToData("Language") })
    }, [options])

    // on submit handler
    const onSubmit = async () => {
        const obj = {
            materialStatus: materialStatus.value,
            age1: ages ? ages.max : null,
            age2: ages ? ages.min : null,
            height1: height1 ? height1.value : null,
            height2: height2 ? height2.value : null,
            motherTongue: motherTongue.value,
            religion: religion.value
        }
        setEdit({ ...Edit, loading: true })
        const response = await User.UpdatePartnerBasicInfo(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setEdit({ show: false, loading: false })
        handleRefetch()
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0"> Basic Informations </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEdit({ ...Edit, show: !Edit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        Edit.show ? <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group mb-4">
                                <p>Age</p>
                                <InputRange
                                    className="form-control shadow-none"
                                    maxValue={45}
                                    minValue={18}
                                    value={ages}
                                    onChange={value => setAges({ max: value.max, min: value.min })}
                                />
                            </div>
                            <div className="d-flex">
                                <p>Height</p>
                                <div className="flex-fill">
                                    <div className="form-group mx-4">
                                        <p>From</p>
                                        <SingleSelect
                                            options={Height}
                                            data={user.partnerPreference
                                                && user.partnerPreference.heightRange
                                                && user.partnerPreference.heightRange.startFrom !== null
                                                ? user.partnerPreference.heightRange.startFrom.toString()
                                                : ""}
                                            value={(data) => setHeight1({ ...height1, value: data })}
                                        />
                                    </div>
                                </div>
                                <div className="flex-fill">
                                    <div className="form-group mx-4">
                                        <p>To</p>
                                        <SingleSelect
                                            options={Height}
                                            data={user.partnerPreference
                                                && user.partnerPreference.heightRange
                                                && user.partnerPreference.heightRange.endTo !== null
                                                ? user.partnerPreference.heightRange.endTo.toString()
                                                : ""}
                                            value={(data) => setHeight2({ ...height2, value: data })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <p>Religion</p>
                                <SingleSelect
                                    options={religion.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.religion
                                        ? user.partnerPreference.religion
                                        : ""}
                                    value={(data) => setReligion({ ...religion, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Material status</p>
                                <SingleSelect
                                    options={materialStatus.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.materialStatus
                                        ? user.partnerPreference.materialStatus
                                        : ""}
                                    value={(data) => setMaterialStatus({ ...materialStatus, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Mother Tongue</p>
                                <SingleSelect
                                    options={motherTongue.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.motherTongue
                                        ? user.partnerPreference.motherTongue
                                        : ""}
                                    value={(data) => setMotherTongue({ ...motherTongue, value: data })}
                                />
                            </div>


                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={Edit.loading}
                                >
                                    {Edit.loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Age</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.partnerPreference
                                                    && user.partnerPreference.ageRange
                                                    && user.partnerPreference.ageRange.startFrom !== null
                                                    ? `${user.partnerPreference.ageRange.startFrom} - ${user.partnerPreference.ageRange.endTo} years`
                                                    : " N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Height</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.heightRange
                                                    && user.partnerPreference.heightRange.startFrom !== null
                                                    ? `${HeightCalculate(user.partnerPreference.heightRange.startFrom)} ft - ${HeightCalculate(user.partnerPreference.heightRange.endTo)} ft`
                                                    : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Religion / Community</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.religion
                                                    ? user.partnerPreference.religion
                                                    : " N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Mother Toungh</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.partnerPreference
                                                    && user.partnerPreference.motherTongue
                                                    ? user.partnerPreference.motherTongue
                                                    : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Material Status</th>
                                                <td className="pl-md-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.materialStatus
                                                    ? user.partnerPreference.materialStatus
                                                    : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default BasicInformations;