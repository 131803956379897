import React from 'react'
import Select from 'react-select'

const SingleSelect = (props) => {
    const handleSelect = (data, options) => {
        if (data) {
            if (typeof data === 'object') {
                let result = options.filter(one => one.value === data[0])
                return result[0]
            }
            else {
                let result = options.filter(one => one.value === data)
                return result[0]
            }
        }

    }

    return (
        <div>
            <Select
                classNamePrefix="custom-select"
                styles={customStyles}
                placeholder={`Select`}
                defaultValue={handleSelect(props.data, props.options) || ''}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={props.options}
                onChange={(event) => props.value(event.value)}
            />
        </div>
    );
};

export default SingleSelect;
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 45,
        fontSize: 14,
        color: '#000',
        boxShadow: 'none', '&:hover': { borderColor: '1px solid #ced4da' },
        border: state.isFocused ? '1px solid #dfdfdf' : '1px solid #ced4da',
        borderRadius: 4
    })
}