import React, { useState } from "react";
import "./style.scss";
import Axios from "axios";
import { useQuery } from "react-query";
import Select from "react-select";
import Icon from "react-icons-kit";
import queryString from "query-string";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { search, loader } from "react-icons-kit/feather";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
import UsersList from "../../../components/users/usersList/Index";
// import AdvanceSearch from '../../../components/users/modal/AdvanceSearch'
import GhostTable from "../../../components/ghostLoader/table/Index";
import Pagination from "../../../components/pagination/Index";
import { DateSpliter } from '../../../utils/helpers'

toast.configure({ autoClose: 2000 });
const Index = () => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [pageReload, setPageReload] = useState(true);
  const [workingWith, setWorkingWith] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [singleRefetch, setSigleRefetch] = useState(false);
  const [totalPage, setTotalPage] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchValues, setSearchValues] = useState(null);
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [queryParams, setQueryParams] = useState({
    page: query.page,
    limit: 50,
    assignTo: query.assignTo || null,
    orderBy: query.orderBy || null,
    profileType: query.profileType || null,
    materialStatus: query.materialStatus || null,
    workStatus: query.workStatus || null,
    qualification: query.qualification || null,
    gender: query.gender || null,
    status: query.status || null,
    dateRange: query.dateRange || null,
  });

  const [header] = useState({
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });

  // Get Admin & working and qualifications options
  const getAdminList = async () => {
    let adminOptions = [{ id: 0, value: `null`, label: `All` }]

    const [response, res] = await Promise.all([ Axios.get(`${api}auth/index`, header),  Axios.get(`${api}settingFields`, header) ])

    if (response.status === 200) {
      response.data.adminList.map(async (one, idx) => {
        adminOptions.push({
          id: idx,
          value: `${one.email}`,
          label: `${one.email}`,
        });
      });
    }

    if (res.status === 200) {
      let option = res.data.settingFiled;
      for (let i = 0; i < option.length; i++) {
        if (option[i].label === "Working with") {
          setWorkingWith(option[i].value);
        } else if (option[i].label === "Qualification") {
          setQualification(option[i].value);
        }
      }
    }
    return adminOptions;
  };

  const adminList = useQuery("admins", getAdminList, {
    retry: false,
    cacheTime: 600000,
    refetchOnWindowFocus: false,
    enabled: Boolean(pageReload),
  });

  // Order by options
  const orderByOptions = [
    { value: "1", label: "ASC" },
    { value: "-1", label: "DESC" },
  ];

  // Profile type options
  const profileTypeOptions = [
    { value: "Free", label: "Free" },
    { value: "Paid", label: "Paid" },
    { value: "null", label: "All" },
  ];

  // Material status options
  const materialStatusOptions = [
    { value: "never married", label: "Never Married" },
    { value: "divorced", label: "Divorced" },
    { value: "annulled", label: "Annulled" },
    { value: "widowed", label: "Widowed" },
    { value: "married", label: "Married" },
    { value: "null", label: "All" },
  ];

  // Work status options
  const workStatusOptions = [
    { value: "employee", label: "Employee" },
    { value: "non Employee", label: "Non Employee" },
    { value: "own Business", label: "Own Business" },
    { value: "student", label: "Student" },
  ];

  // Gender options
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "null", label: "All" },
  ];

  // Status options
  const statusOptions = [
    { value: "activated", label: "Activated" },
    { value: "deactivated", label: "Deactivated" },
    { value: "null", label: "All" },
  ];

  const getUsers = async () => {
    try {
      if (singleRefetch && pageReload) {
        setSearching(true);
        const response = await Axios.get(`${api}user/search?query=${searchValues}`, header);
        setTotalPage(1);
        setSearching(false);
        setPageReload(false);
        setSigleRefetch(false);
        return response.data.users;
      } else {
        const response = await Axios.get(`${api}user/index${location.search}`, header);
        setTotalPage(response.data.totalPage);
        setPageReload(false);
        setSigleRefetch(false);
        return response.data.users;
      }
    } catch (error) {
      if (error) {
        setSearching(false);
        toast.warn(error.message);
      }
    }
  };

  const queryData = useQuery("users", getUsers, {
    retry: false,
    cacheTime: 600000,
    refetchOnWindowFocus: false,
    enabled: Boolean(pageReload),
  });

  // change page no
  const changePage = (page) => {
    setQueryParams({ ...queryParams, page: page });
    history.push(
      `/dashboard/users?page=${page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle Assign to
  const handleAssignTo = (data) => {
    setQueryParams({ ...queryParams, assignTo: data });
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${data}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle order By
  const handleOrderBy = (data) => {
    setQueryParams({ ...queryParams, orderBy: data });
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${data}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle profile type
  const handleProfileType = (data) => {
    setQueryParams({ ...queryParams, profileType: data });
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${data}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle material status
  const handleMaterialStatus = (data) => {
    setQueryParams({ ...queryParams, materialStatus: data });
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${data}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle work status
  const handleWorkStatus = (data) => {
    setQueryParams({ ...queryParams, workStatus: data });
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${data}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle work status
  const handleQualification = (data) => {
    setQueryParams({ ...queryParams, qualification: data });
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${data}&gender=${queryParams.gender}`
    );
    setPageReload(true);
  };

  // Handle gender
  const handleGender = (data) => {
    setQueryParams({ ...queryParams, gender: data });
    setPageReload(true);
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${data}`
    );
  };

  // Handle status
  const handleStatus = (data) => {
    setQueryParams({ ...queryParams, status: data });
    setPageReload(true);
    history.push(
      `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${queryParams.dateRange}&status=${data}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
    )
  }

//   Handle data range
  const handleRange = (range) => {
      if(range){
        let from = DateSpliter(range[0])
        let to = DateSpliter(range[1])
        setQueryParams({ ...queryParams, dateRange:`${from}~${to}` });
        setPageReload(true);
        history.push(
          `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=${`${from}~${to}`}&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
        )
      }
  }

//   clear date range
  const handleRangeClean = (e) => {
    e.preventDefault();
    setQueryParams({ ...queryParams, dateRange: null});
        setPageReload(true);
        history.push(
          `/dashboard/users?page=${queryParams.page}&limit=${query.limit}&dateRange=null&status=${queryParams.status}&assignTo=${queryParams.assignTo}&orderBy=${queryParams.orderBy}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&workStatus=${queryParams.workStatus}&qualification=${queryParams.qualification}&gender=${queryParams.gender}`
        )
  }

  // update user activated & deactivated status
  const updateStatus = async (user) => {
    const response = await Axios.get(`${api}user/activestatus/update/${user.id}/${user.activeStatus}`, header)
    if (response.status === 201) {
      if (searchValues) {
        setSigleRefetch(true);
        setPageReload(true);
      } else {
        setPageReload(true);
        setSigleRefetch(false);
      }
      toast.success(response.data.message);
    }
  };

  // update user activated & deactivated status
  const updateBlockStatus = async (user) => {
    const response = await Axios.get(`${api}user/blockstatus/update/${user.id}/${user.isLocked}`, header)
    if (response.status === 201) {
      if (searchValues) {
        setSigleRefetch(true);
        setPageReload(true);
      } else {
        setPageReload(true);
        setSigleRefetch(false);
      }
      toast.success(response.data.message);
    }
  };

  // Search user
  const submitSearch = async () => {
    setSigleRefetch(true);
    setPageReload(true);
  };


  // clear search
  const onChangeClearSearch = (event) => {
    if (!event.target.value) {
      setPageReload(true);
    }
    setSearchValues(event.target.value);
  };


  const defaultValueHandler = (data, options) => {
    if (data && data !== "null")
      return options.find((obj) => obj.value === data);
    else return null;
  };

  // If loading to get data
  if (queryData.isLoading) return <GhostTable />;
  if (queryData.isError)
    return <div className="text-center mt-5">Something went wrong</div>;

  return (
    <div className="dashboard-users-index">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-padding pb-0">
            {/* User filter section */}
            <div className="justify-content-end filter-section">
              {/* Search */}
              <div className="filter-item">
                <div className="item-body">
                  <span>Search users</span>
                  <form onSubmit={handleSubmit(submitSearch)}>
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="query"
                        className={
                          errors.query
                            ? "form-control rounded-0 shadow-sm border-danger"
                            : "form-control rounded-0 shadow-sm"
                        }
                        placeholder="Search"
                        ref={register({ required: true })}
                        disabled={searching}
                        defaultValue={searchValues ? searchValues : null}
                        onChange={onChangeClearSearch}
                      />
                      <Icon
                        icon={searching ? loader : search}
                        className={
                          searching ? "icon spin primary-color" : "icon"
                        }
                        size={18}
                      />
                    </div>
                  </form>
                </div>
              </div>

              {/* Filter by date range */}
              <div className="filter-item">
                <div className="item-body shadow-sm rangepicker">
                  <span>Filter by Date</span>
                  <div className="form-group m-0">
                    <DateRangePicker
                      onChange={handleRange}
                      onClean={handleRangeClean}
                      block
                    />
                  </div>
                </div>
              </div>

              {/* Assign To */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>AdminList</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={adminList.data}
                    defaultValue={defaultValueHandler(
                      query.assignTo,
                      adminList.data
                    )}
                    onChange={(event) => handleAssignTo(event.value)}
                  />
                </div>
              </div>

              {/* Order By */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Order By</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={orderByOptions}
                    defaultValue={defaultValueHandler(
                      query.orderBy,
                      orderByOptions
                    )}
                    onChange={(event) => handleOrderBy(event.value)}
                  />
                </div>
              </div>

              {/* Profile Type */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Profile Type</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={profileTypeOptions}
                    defaultValue={defaultValueHandler(
                      query.profileType,
                      profileTypeOptions
                    )}
                    onChange={(event) => handleProfileType(event.value)}
                  />
                </div>
              </div>

              {/* Material status */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Material Status</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={materialStatusOptions}
                    defaultValue={defaultValueHandler(
                      query.materialStatus,
                      materialStatusOptions
                    )}
                    onChange={(event) => handleMaterialStatus(event.value)}
                  />
                </div>
              </div>

              {/* Work status */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Working Status</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={workingWith}
                    defaultValue={defaultValueHandler(
                      query.workStatus,
                      workStatusOptions
                    )}
                    onChange={(event) => handleWorkStatus(event.value)}
                  />
                </div>
              </div>

              {/* Qualifications */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Qualification</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={qualification}
                    defaultValue={defaultValueHandler(
                      query.workStatus,
                      qualification
                    )}
                    onChange={(event) => handleQualification(event.value)}
                  />
                </div>
              </div>

              {/* Gender */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Gender</span>
                  <Select
                    classNamePrefix="custom-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={genderOptions}
                    defaultValue={defaultValueHandler(
                      query.gender,
                      genderOptions
                    )}
                    onChange={(event) => handleGender(event.value)}
                  />
                </div>
              </div>

              {/* Status  */}
              <div className="filter-item">
                <div className="item-body shadow-sm">
                  <span>Status</span>
                  <Select
                    classNamePrefix="custome-select"
                    styles={customStyles}
                    placeholder={"Select"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={statusOptions}
                    defaultValue={defaultValueHandler(
                      query.status,
                      statusOptions
                    )}
                    onChange={(event) => handleStatus(event.value)}
                  />
                </div>
              </div>

            </div>
          </div>

          <div className="col-12 col-padding">
            {/* User list component */}
            <div className="card border-0 shadow-sm">
              <UsersList
                users={queryData.data}
                updatestatus={updateStatus}
                updateBlockStatus={updateBlockStatus}
                header={header}
                refetch={()=>setPageReload(true)}
              />
              <div className="card-footer bg-white border-0 pt-0 pb-4">
                <Pagination totalpage={totalPage} changePage={changePage} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Advance search modal */}
      {/* {show ?
                <AdvanceSearch
                    show={show}
                    onHide={() => setShow(false)}
                /> : null} */}
    </div>
  );
};

export default Index;
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 40,
    fontSize: 13,
    color: "#000",
    minWidth: 170,
    boxShadow: "none",
    "&:hover": { borderColor: "1px solid #ced4da" },
    border: state.isFocused ? "1px solid #dfdfdf" : "1px solid #ced4da",
    borderRadius: 0,
  }),
};
