import React, { useState, useRef } from 'react'
import Icon from 'react-icons-kit'
import { trash, plusCircle, x } from 'react-icons-kit/feather'
import Select from "react-select"
import User from "../../../utils/Requests/User"
import Modal from 'react-bootstrap/Modal'
import AvatarEditor from 'react-avatar-editor'
import SweetAlert from "react-bootstrap-sweetalert";
import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const Images = ({ images, email, handleRefetch }) => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [show, setshow] = useState(false);
    const [name, setName] = useState(null);
    const [previewLink, setPreviewLink] = useState(null)
    const [modalShow, setModalShow] = useState(false);
    const editor = useRef()
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [type, setType] = useState(null)

    const imageType = [
        { value: "profilePicture", label: "Profile Picture" },
        { value: "uploadImage", label: "Upload Image" }
    ];

    const showAlertDialog = (data) => {
        setName(data)
        setshow(true)
    }

    const onChange = (e) => {
        var tmppath = URL.createObjectURL(e.target.files[0])
        setFile(tmppath)
        setPreviewLink(tmppath)
    }

    const manageModal = e => {
        e.preventDefault()
        setModalShow(true)
    }

    const managePrevewImage = e => {
        e.preventDefault()
        setModalShow(false)
        setLoading(false)
        setPreviewLink(null)
        setFile(null)
    }

    const manageType = e => {
        setType(e)
    }

    const handleImage = async () => {
        try {
            let data = {
                email: email,
                name: name
            }
            const response = await User.DeleteUserImage(data, header)
            if (response.status) {
                toast.success(response.message)
            }
            setshow(false);
            handleRefetch()
        } catch (error) {
            toast.error('Something went wrong')
        }
    }

    const onClickSave = async (event) => {
        event.preventDefault()
        if (editor.current) {
            const canvasScaled = editor.current.getImageScaledToCanvas()
            const croppedImg = canvasScaled.toDataURL()
            const blob = await (await fetch(croppedImg)).blob();
            const file = new File([blob], 'fileName.jpg', { type: "image/jpg", lastModified: new Date() });
            let formData = new FormData()
            formData.append('image', file)
            formData.append('type', type)
            formData.append('email', email)
            setLoading(true)
            if(!type || !file) {
                toast.error('Select image type and image file from directory',{
                    autoClose:2500
                })
                setLoading(false)
            }
            else{
                const response = await User.UploadUserImage(formData, header)
                if (response.status) {
                    toast.success('Image upload successful')
                    setLoading(false)
                    setModalShow(false)
                    setPreviewLink(null)
                    setFile(null)
                    handleRefetch()
                }
                else {
                    setModalShow(false)
                    setLoading(false)
                }
            }
        }
        else {
            toast.error('Select image from filelocation')
        }
    }

    
    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Images </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={manageModal}
                            >
                                <Icon icon={plusCircle} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        images.length && images.length > 0 ?
                            <div className='imageContainer'>
                                {images.map((one, key) =>
                                    <div className='wrapper'>
                                        <img className='image' key={key} src={one} alt='img' />
                                        <button
                                            className="btn rounded-circle shadow"
                                            onClick={() => showAlertDialog(one)}>
                                            <Icon icon={trash} size={14} /></button>
                                    </div>
                                )}
                            </div>
                            : <p>This user did not upload any image yet</p>
                    }
                </div>
            </div>
            <SweetAlert
                showCancel
                show={show}
                style={{ fontSize: "12px" }}
                confirmBtnText="Delete"
                confirmBtnBsStyle='#ff5733'
                cancelBtnBsStyle='#ff5733'
                title="Do you want to delete this image?"
                onConfirm={() => handleImage()}
                onCancel={() => setshow(false)}
            ></SweetAlert>
            {
                modalShow ?
                    <div>
                        <Modal
                            show={modalShow}
                            size="lg"
                            centered
                            backdrop="static"
                            keyboard={false}
                            className="picture-crop-modal"
                        >
                            <Modal.Header className="border-0">
                                <div className="w-100 text-right">
                                    <button type="button" className="btn shadow-none"
                                        onClick={managePrevewImage}>
                                        <Icon icon={x} size={22} />
                                    </button>
                                </div>
                            </Modal.Header>
                            <Modal.Body className="text-center p-0">
                                <div className="form-group mb-4">
                                    <input
                                        type="file"
                                        className="form-control shadow-none"
                                        onChange={onChange}
                                    />
                                </div>
                                {
                                    file ?
                                        <AvatarEditor
                                            image={previewLink}
                                            width={300}
                                            height={300}
                                            border={50}
                                            color={[255, 255, 255, 0.6]}
                                            scale={1.1}
                                            rotate={0}
                                            ref={editor}
                                        />
                                        : null
                                }

                            </Modal.Body>
                            <Modal.Footer className="border-0">
                                <div className="filter-item">
                                    <div className="item-body shadow-sm">
                                        <p>Image Type</p>
                                        <Select
                                            classNamePrefix="custom-select"
                                            styles={customStyles}
                                            placeholder={"Select"}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            options={imageType}
                                            onChange={(event) => manageType(event.value)}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    style={{border:"1px solid green"}}
                                    className="shadow-none ml-auto btn"
                                    disabled={loading}
                                    onClick={onClickSave}>
                                    {loading ? 'Uploading...' : 'Upload'}
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    : null
            }
        </div>
    )
}

export default Images;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: "#000",
        minWidth: 170,
        boxShadow: "none",
        "&:hover": { borderColor: "1px solid #ced4da" },
        border: state.isFocused ? "1px solid #dfdfdf" : "1px solid #ced4da",
        borderRadius: 0,
    }),
};