import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useHistory, useParams } from 'react-router-dom'
import { chevronLeft, plus } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

import LoadingComponent from '../../../../components/loading/Index'

const Edit = () => {
    const { id } = useParams()
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false)
    const [item, setItem] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.MarriageCounseling.Show(id, header)
        if (data) {
            setItem(data)
            setLoading(false)
        }
        setLoading(false)
    }, [id, header])

    useEffect(() => {
        fetchData()
    }, [id, header, fetchData])

    // Handle image
    // const handleImage = async (event) => {
    //     const file = event.target.files[0]
    //     if (file) {
    //         const size = parseInt(file.size) / 1000
    //         if (size > 700) {
    //             setError({ ...error, image: 'Select less than 700KB file.' })
    //             return
    //         }
    //         setSelectedFile({ image: file, preview: URL.createObjectURL(file) })

    //         let formData = new FormData()
    //         formData.append('vip_review_image', file)
    //         setItem({ ...item, image: null })

    //         const response = await Requests.MarriageCounseling.UpdateImage(id, formData, header)
    //         if (response.status === true) {
    //             toast.success(response.message)
    //         }
    //     }
    // }

    // Handle image
    const handleImage = async (event, name) => {
        const file = event.target.files[0]
        if (file) {
            const size = parseInt(file.size) / 1000

            if (name === 'header_image') {
                setSelectedFile({ ...selectedFile, header_image: file, header_image_preview: URL.createObjectURL(file) })
                setError({ ...errors, header_image: null })

                let formData = new FormData()
                formData.append('header_image', file)
                setItem({ ...item, header_image: null })
                const response = await Requests.MarriageCounseling.UpdateImage(id, formData, header)
                if (response.status === true) {
                    toast.success(response.message)
                }
            }
            else if (name === 'counselor_1_image') {
                if (size > 1000) {
                    setError({ ...error, counselor_1_image: 'Select less than 1000KB file.' })
                    return
                } else {
                    setSelectedFile({ ...selectedFile, counselor_1_image: file, counselor_1_image_preview: URL.createObjectURL(file) })
                    setError({ ...errors, counselor_1_image: null })

                    let formData = new FormData()
                    formData.append('counselor_1_image', file)
                    setItem({ ...item, counselor_1_image: null })
                    const response = await Requests.MarriageCounseling.UpdateImage(id, formData, header)
                    if (response.status === true) {
                        toast.success(response.message)
                    }
                }
            }
            else if (name === 'counselor_2_image') {
                if (size > 1000) {
                    setError({ ...error, counselor_2_image: 'Select less than 1000KB file.' })
                    return
                } else {
                    setSelectedFile({ ...selectedFile, counselor_2_image: file, counselor_2_image_preview: URL.createObjectURL(file) })
                    setError({ ...errors, counselor_2_image: null })

                    let formData = new FormData()
                    formData.append('counselor_2_image', file)
                    setItem({ ...item, counselor_2_image: null })
                    const response = await Requests.MarriageCounseling.UpdateImage(id, formData, header)
                    if (response.status === true) {
                        toast.success(response.message)
                    }
                }
            }
            else if (name === 'counselor_3_image') {
                if (size > 1000) {
                    setError({ ...error, counselor_3_image: 'Select less than 1000KB file.' })
                    return
                } else {
                    setSelectedFile({ ...selectedFile, counselor_3_image: file, counselor_3_image_preview: URL.createObjectURL(file) })
                    setError({ ...errors, counselor_3_image: null })

                    let formData = new FormData()
                    formData.append('counselor_3_image', file)
                    setItem({ ...item, counselor_3_image: null })
                    const response = await Requests.MarriageCounseling.UpdateImage(id, formData, header)
                    if (response.status === true) {
                        toast.success(response.message)
                    }
                }
            }

        }
    }

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setUpdate(true)

            await Requests.MarriageCounseling.Update(id, data, header)
            setUpdate(false)
            history.push('/dashboard/website/marriage-counselling')
        } catch (error) {
            if (error) console.log(error)
        }
    }

    if (isLoading) return <LoadingComponent />

    return (
        <div className="marriage-story pb-4">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Edit Marriage Counseling Page</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/marriage-counselling"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <h4>Header Cover Image - 1349 × 660 px</h4>
                                    {/* Image upload Container */}
                                    <div className="form-group mb-4">
                                        {error && error.header_image ? <p className="text-danger">{error.header_image}</p> : <p>header_image</p>}
                                        <div className="d-flex">
                                            {
                                                selectedFile && selectedFile.header_image_preview ?
                                                    <div className="img-container text-center mr-2">
                                                        <div className="image border">
                                                            <img src={selectedFile.header_image_preview} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    :
                                                    item && item.header_image ?
                                                        <div className="img-container text-center mr-2">
                                                            <div className="image border">
                                                                <img src={item.header_image} className="img-fluid" alt="..." />
                                                            </div>
                                                        </div>
                                                        : null}

                                            <div className="img-container text-center">
                                                <div className="image border">
                                                    <input type="file" className="upload" onChange={(e) => handleImage(e, "header_image")} />
                                                    <div className="flex-center flex-column">
                                                        <Icon icon={plus} size={22} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Header Title  */}
                                    <div className="form-group mb-4">
                                        {errors.header_title && errors.header_title.message ? (
                                            <p className="text-danger">{errors.header_title && errors.header_title.message}</p>
                                        ) : <p>Header Title</p>}

                                        <textarea
                                            type="text"
                                            name="header_title"
                                            defaultValue={item.header_title}
                                            rows={2}
                                            className="form-control shadow-none"
                                            placeholder="Enter marriage counselling short header_title"
                                            ref={register({
                                                required: "header_title is required"
                                            })}
                                        />
                                    </div>

                                    {/* header_descripton \ */}
                                    <div className="form-group mb-4">
                                        {errors.header_descripton && errors.header_descripton.message ? (
                                            <p className="text-danger">{errors.header_descripton && errors.header_descripton.message}</p>
                                        ) : <p>Header Descriptoin</p>}

                                        <textarea
                                            type="text"
                                            name="header_descripton"
                                            defaultValue={item.header_descripton}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter marriage counselling short introduction"
                                            ref={register({
                                                required: "header_descripton is required"
                                            })}
                                        />
                                    </div>

                                    <br /> <br />

                                    <h4>How Does this Actually Work?</h4>
                                    {/* How Does this  */}
                                    <div className="form-group mb-4">
                                        {errors.how_does_part_1 && errors.how_does_part_1.message ? (
                                            <p className="text-danger">{errors.how_does_part_1 && errors.how_does_part_1.message}</p>
                                        ) : <p>How Does part - 1</p>}

                                        <textarea
                                            type="text"
                                            name="how_does_part_1"
                                            defaultValue={item.how_does_part_1}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter marriage counselling short how_does_part_1"
                                            ref={register({
                                                required: "how_does_part_1 is required"
                                            })}
                                        />
                                    </div>

                                    {/* how_does_part_2 */}
                                    <div className="form-group mb-4">
                                        {errors.how_does_part_2 && errors.how_does_part_2.message ? (
                                            <p className="text-danger">{errors.how_does_part_2 && errors.how_does_part_2.message}</p>
                                        ) : <p>How Does Part - 2</p>}

                                        <textarea
                                            type="text"
                                            name="how_does_part_2"
                                            defaultValue={item.how_does_part_2}
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter marriage counselling short how_does_part_2"
                                            ref={register({
                                                required: "how_does_part_2 is required"
                                            })}
                                        />
                                    </div>

                                    < br /> < br />
                                    <h4>Our Famous Marriage Counselors</h4>
                                    <>
                                        {/* 1st Marriage Counselors */}
                                        {/* counselor_1_image upload Container */}
                                        <div className="form-group mb-4">
                                            {error && error.counselor_1_image ? <p className="text-danger">{error.counselor_1_image}</p>
                                                : <p>1st Counselor image - 430 × 448 px </p>}
                                            <div className="d-flex">
                                            {
                                                selectedFile && selectedFile.counselor_1_image_preview ?
                                                    <div className="img-container text-center mr-2">
                                                        <div className="image border">
                                                            <img src={selectedFile.counselor_1_image_preview} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    :
                                                    item && item.counselor_1_image ?
                                                        <div className="img-container text-center mr-2">
                                                            <div className="image border">
                                                                <img src={item.counselor_1_image} className="img-fluid" alt="..." />
                                                            </div>
                                                        </div>
                                                        : null}

                                                <div className="img-container text-center">
                                                    <div className="image border">
                                                        <input type="file" className="upload" onChange={(e) => handleImage(e, "counselor_1_image")} />
                                                        <div className="flex-center flex-column">
                                                            <Icon icon={plus} size={22} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* 1st counselor_1_name */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.counselor_1_name && errors.counselor_1_name.message ? (
                                                        <p className="text-danger">{errors.counselor_1_name && errors.counselor_1_name.message}</p>
                                                    ) : <p>1st Counselor Name</p>
                                                    }

                                                    <input
                                                        type="text"
                                                        name="counselor_1_name"
                                                        defaultValue={item.counselor_1_name}
                                                        className="form-control shadow-none"
                                                        placeholder="Enter counselor_1_name"
                                                        ref={register({ required: "counselor_1_name is required" })}
                                                    />
                                                </div>
                                            </div>

                                            {/* counselor_1_sort_into */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.counselor_1_sort_into && errors.counselor_1_sort_into.message ? (
                                                        <p className="text-danger">{errors.counselor_1_sort_into && errors.counselor_1_sort_into.message}</p>
                                                    ) : <p>1st Counselor sort into</p>
                                                    }

                                                    <input
                                                        type="text"
                                                        name="counselor_1_sort_into"
                                                        defaultValue={item.counselor_1_sort_into}
                                                        className="form-control shadow-none"
                                                        placeholder="Enter counselor_1_sort_into"
                                                        ref={register({ required: "counselor_1_sort_into is required" })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4">
                                            {errors.counselor_1_description && errors.counselor_1_description.message ? (
                                                <p className="text-danger">{errors.counselor_1_description && errors.counselor_1_description.message}</p>
                                            ) : <p>1st Counselor Descriptoin</p>}

                                            <textarea
                                                type="text"
                                                name="counselor_1_description"
                                                defaultValue={item.counselor_1_description}
                                                rows={5}
                                                className="form-control shadow-none"
                                                placeholder="Enter marriage counselling short counselor_1_description"
                                                ref={register({
                                                    required: "counselor_1_description is required"
                                                })}
                                            />
                                        </div>
                                    </>

                                    <br /> <br />
                                    <>
                                        {/* 2nd Marriage Counselors */}
                                        {/* counselor_2_image upload Container */}
                                        <div className="form-group mb-4">
                                            {error && error.counselor_2_image ? <p className="text-danger">{error.counselor_2_image}</p>
                                                : <p>2nd Counselor image - 430 × 448 px </p>}
                                            <div className="d-flex">
                                            {
                                                selectedFile && selectedFile.counselor_2_image_preview ?
                                                    <div className="img-container text-center mr-2">
                                                        <div className="image border">
                                                            <img src={selectedFile.counselor_2_image_preview} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    :
                                                    item && item.counselor_2_image ?
                                                        <div className="img-container text-center mr-2">
                                                            <div className="image border">
                                                                <img src={item.counselor_2_image} className="img-fluid" alt="..." />
                                                            </div>
                                                        </div>
                                                        : null}

                                                <div className="img-container text-center">
                                                    <div className="image border">
                                                        <input type="file" className="upload" onChange={(e) => handleImage(e, "counselor_2_image")} />
                                                        <div className="flex-center flex-column">
                                                            <Icon icon={plus} size={22} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* 2nd counselor_2_name */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.counselor_2_name && errors.counselor_2_name.message ? (
                                                        <p className="text-danger">{errors.counselor_2_name && errors.counselor_2_name.message}</p>
                                                    ) : <p>2nd Counselor Name</p>
                                                    }

                                                    <input
                                                        type="text"
                                                        name="counselor_2_name"
                                                        defaultValue={item.counselor_2_name}
                                                        className="form-control shadow-none"
                                                        placeholder="Enter counselor_2_name"
                                                        ref={register({ required: "counselor_2_name is required" })}
                                                    />
                                                </div>
                                            </div>

                                            {/* counselor_2_sort_into */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.counselor_2_sort_into && errors.counselor_2_sort_into.message ? (
                                                        <p className="text-danger">{errors.counselor_2_sort_into && errors.counselor_2_sort_into.message}</p>
                                                    ) : <p>2nd Counselor sort into</p>
                                                    }

                                                    <input
                                                        type="text"
                                                        name="counselor_2_sort_into"
                                                        defaultValue={item.counselor_2_sort_into}
                                                        className="form-control shadow-none"
                                                        placeholder="Enter counselor_2_sort_into"
                                                        ref={register({ required: "counselor_2_sort_into is required" })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4">
                                            {errors.counselor_2_description && errors.counselor_2_description.message ? (
                                                <p className="text-danger">{errors.counselor_2_description && errors.counselor_2_description.message}</p>
                                            ) : <p>2nd Counselor Descriptoin</p>}

                                            <textarea
                                                type="text"
                                                name="counselor_2_description"
                                                defaultValue={item.counselor_2_description}
                                                rows={5}
                                                className="form-control shadow-none"
                                                placeholder="Enter marriage counselling short counselor_2_description"
                                                ref={register({
                                                    required: "counselor_2_description is required"
                                                })}
                                            />
                                        </div>
                                    </>

                                    <br /> <br />
                                    <>
                                        {/* 3rd Marriage Counselors */}
                                        {/* counselor_3_image upload Container */}
                                        <div className="form-group mb-4">
                                            {error && error.counselor_3_image ? <p className="text-danger">{error.counselor_3_image}</p>
                                                : <p>3rd Counselor image - 430 × 448 px </p>}
                                            <div className="d-flex">
                                            {
                                                selectedFile && selectedFile.counselor_3_image_preview ?
                                                    <div className="img-container text-center mr-2">
                                                        <div className="image border">
                                                            <img src={selectedFile.counselor_3_image_preview} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    :
                                                    item && item.counselor_3_image ?
                                                        <div className="img-container text-center mr-2">
                                                            <div className="image border">
                                                                <img src={item.counselor_3_image} className="img-fluid" alt="..." />
                                                            </div>
                                                        </div>
                                                        : null}

                                                <div className="img-container text-center">
                                                    <div className="image border">
                                                        <input type="file" className="upload" onChange={(e) => handleImage(e, "counselor_3_image")} />
                                                        <div className="flex-center flex-column">
                                                            <Icon icon={plus} size={22} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* 3rd counselor_3_name */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.counselor_3_name && errors.counselor_3_name.message ? (
                                                        <p className="text-danger">{errors.counselor_3_name && errors.counselor_3_name.message}</p>
                                                    ) : <p>3rd Counselor Name</p>
                                                    }

                                                    <input
                                                        type="text"
                                                        name="counselor_3_name"
                                                        defaultValue={item.counselor_3_name}
                                                        className="form-control shadow-none"
                                                        placeholder="Enter counselor_3_name"
                                                        ref={register({ required: "counselor_3_name is required" })}
                                                    />
                                                </div>
                                            </div>

                                            {/* counselor_3_sort_into */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.counselor_3_sort_into && errors.counselor_3_sort_into.message ? (
                                                        <p className="text-danger">{errors.counselor_3_sort_into && errors.counselor_3_sort_into.message}</p>
                                                    ) : <p>3rd Counselor sort into</p>
                                                    }

                                                    <input
                                                        type="text"
                                                        name="counselor_3_sort_into"
                                                        defaultValue={item.counselor_3_sort_into}
                                                        className="form-control shadow-none"
                                                        placeholder="Enter counselor_3_sort_into"
                                                        ref={register({ required: "counselor_3_sort_into is required" })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4">
                                            {errors.counselor_3_description && errors.counselor_3_description.message ? (
                                                <p className="text-danger">{errors.counselor_3_description && errors.counselor_3_description.message}</p>
                                            ) : <p>3rd Counselor Descriptoin</p>}

                                            <textarea
                                                type="text"
                                                name="counselor_3_description"
                                                defaultValue={item.counselor_3_description}
                                                rows={5}
                                                className="form-control shadow-none"
                                                placeholder="Enter marriage counselling short counselor_3_description"
                                                ref={register({
                                                    required: "counselor_3_description is required"
                                                })}
                                            />
                                        </div>
                                    </>

                                    <div className="form-group mb-4">
                                            {errors.book_now_text && errors.book_now_text.message ? (
                                                <p className="text-danger">{errors.book_now_text && errors.book_now_text.message}</p>
                                            ) : <p>Book Now Text</p>}

                                            <textarea
                                                type="text"
                                                name="book_now_text"
                                                defaultValue={item.book_now_text}
                                                rows={5}
                                                className="form-control shadow-none"
                                                placeholder="Enter marriage counselling short book_now_text"
                                                ref={register({
                                                    required: "book_now_text is required"
                                                })}
                                            />
                                        </div>

                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Creating...' : 'Create'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Edit;
