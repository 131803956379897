import React, { useState, useContext, useEffect } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import { AgeCalculate, HeightCalculate } from '../../../utils/helpers'
import SingleSelect from '../../form/SingleSelect'
import { optionContext } from '../../../utils/Context/optionContext'
import { Height } from "../../OptionsData/HeightData/Heightdata"
import { Disability } from "../../OptionsData/Disability/Disability"
import { Diet } from "../../OptionsData/Diet/Diet"
import { MaterialStatus } from "../../OptionsData/MaterialStatus/MaterialStatus"
import { HealthInfo } from "../../OptionsData/HealthInfo/HealthInfo"

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const BasicLifeStyle = ({ user, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [BasicEdit, setBasicEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [material, setMaterial] = useState({
        value: null,
        options: MaterialStatus
    })

    const [newHeight, setHeight] = useState({
        value: null,
        options: Height
    })

    const [health, setHealth] = useState({
        value: null,
        options: HealthInfo
    })

    const [newDiet, setDiet] = useState({
        value: null,
        options: Diet
    })

    const [newDisability, setDisability] = useState({
        value: null,
        options: Disability
    })

    const handleAge = (age) => {
        var date = new Date(age);
        return date.toISOString().substring(0, 10);
    }

    const [blood, setBlood] = useState({
        value: null,
        options: []
    })

    useEffect(() => {
        setBlood({ ...blood, options: handleOptionToData("Blood Group") })
    }, [options])

    const onSubmit = async (data) => {
        setBasicEdit({ ...BasicEdit, loading: true })
        const obj = {
            materialStatus: material.value,
            bloodGroup: blood.value,
            height: newHeight.value,
            healthInformation: health.value,
            diet: newDiet.value,
            disability: newDisability.value,
            age: data.age ? data.age : null,
            name: data.name ? data.name : null,
            bodyWeight: data.bodyWeight ? data.bodyWeight : null
        }
        const response = await User.UpdateBasicLifeStyle(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your infos')
        }
        setBasicEdit({ show: false, loading: false })
        handleRefetch()
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Basic Information</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setBasicEdit({ ...BasicEdit, show: !BasicEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        BasicEdit.show ? <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group mb-4">
                                <p>Name</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Enter your fullname"
                                    defaultValue={user.name || ''}
                                    name="name"
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Your Date of birth</p>
                                <input
                                    type="date"
                                    className="form-control shadow-none"
                                    defaultValue={user.basicAndLifestyleInformation.age ? handleAge(user.basicAndLifestyleInformation.age) : ''}
                                    name='age'
                                    ref={register({ required: true })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Material status</p>

                                <SingleSelect
                                    options={material.options}
                                    data={user.basicAndLifestyleInformation.materialStatus || ''}
                                    value={(data) => setMaterial({ ...material, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Your Height</p>

                                <SingleSelect
                                    options={newHeight.options}
                                    data={user.basicAndLifestyleInformation.height || ''}
                                    value={(data) => setHeight({ ...newHeight, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Your Blood Group</p>

                                <SingleSelect
                                    options={blood.options}
                                    data={user.basicAndLifestyleInformation.bloodGroup || ''}
                                    value={(data) => setBlood({ ...blood, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Diet</p>

                                <SingleSelect
                                    options={newDiet.options}
                                    data={user.basicAndLifestyleInformation.diet || ''}
                                    value={(data) => setDiet({ ...newDiet, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Health Information</p>

                                <SingleSelect
                                    options={health.options}
                                    data={user.basicAndLifestyleInformation.healthInformation || ''}
                                    value={(data) => setHealth({ ...health, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Disability</p>

                                <SingleSelect
                                    options={newDisability.options}
                                    data={user.basicAndLifestyleInformation.disability || ''}
                                    value={(data) => setDisability({ ...newDisability, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Body Weight</p>
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    placeholder="Enter body wight in kg..."
                                    defaultValue={user.basicAndLifestyleInformation.bodyWeight || ''}
                                    name="bodyWeight"
                                    ref={register()}
                                />
                            </div>


                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={BasicEdit.loading}
                                >
                                    {BasicEdit.loading ? 'Saving...' : 'Save info'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.profileId ? user.profileId : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Name</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.name ? user.name : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Material Status</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.materialStatus ? user.basicAndLifestyleInformation.materialStatus : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Diet</th>
                                                <td className="pl-5">:</td>
                                                {user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.diet ? user.basicAndLifestyleInformation.diet.map((one, id) => <td key={id} id={id}>{one}</td>) : "N/A"}
                                            </tr>
                                            <tr>
                                                <th>Age & Height</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.age ? AgeCalculate(user.basicAndLifestyleInformation.age) : "N/A"} & {user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.height ? HeightCalculate(user.basicAndLifestyleInformation.height) : "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table >
                                        <tbody>
                                            <tr>
                                                <th>Body Weight</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.bodyWeight ? `${user.basicAndLifestyleInformation.bodyWeight} kg` : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Blood Group</th>
                                                <td className="pl-md-5">:</td>
                                                <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.bloodGroup ? user.basicAndLifestyleInformation.bloodGroup : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Health Information</th>
                                                <td className="pl-md-5">:</td>
                                                <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.healthInformation ? user.basicAndLifestyleInformation.healthInformation : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Disability</th>
                                                <td className="pl-md-5">:</td>
                                                <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.disability ? user.basicAndLifestyleInformation.disability : "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default BasicLifeStyle;