import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}setting`, header)
        if (response.status === 200) {
            return response.data.settings
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Store item
const Store = async (data, header) => {
    try {
        const response = await Axios.post(`${api}setting/title`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update item
const Update = async (id, isActive, header) => {
    try {
        const response = await Axios.put(`${api}banner/${id}`, isActive, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Value add in field
const ValueStore = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}setting/value/${id}`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Value update in field
const ValueUpdate = async (id, values, newValues, header) => {
    try {
        const response = await Axios.put(`${api}setting/value/update/${id}?values=${values}`, newValues, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) {
            // console.log(error.response)
            return errorHandeller(error)
        }
    }
}

// Delete value
const DeleteValue = async (id, values, header) => {
    try {
        const response = await Axios.delete(`${api}setting/value/${id}?values=${values}`, header)
        if (response.status === 200) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Settings = {
    Index,
    Store,
    Update,
    ValueStore,
    ValueUpdate,
    DeleteValue
}

export default Settings