import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import Axios from 'axios'
import { useMutation } from 'react-query'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import { useHistory} from 'react-router-dom'

toast.configure({ autoClose: 2000 })
const EditHRMUsers = (props) => {
    const { register, handleSubmit, errors } = useForm()
    
    const id = props.match.params.id;
   

    const history = useHistory()
    const [percentage, setPercentage] = useState("");
    const [description, setDescription] = useState("");
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const editComission = async (data, header) => {
        const res = await Axios.put(`${api}ghotok/comission/${id}/edit`, data, header)
        return res.data.message
    }

    const { mutate, isLoading, isError } = useMutation(editComission, {
        onSuccess: (data) => {
            history.push('/dashboard/agents/comission')
           
            toast.success(data)
        }
    })

    const getAdminData = useCallback((_id) => {
        if (_id) {
            Axios.get(`${api}ghotok/comission/index`, header)
                .then((response) => {
                    if (response.data) {
                        let arrayData = response.data.percentageList;
                        let showData = arrayData.find((obj) => obj._id === _id);
                        if (showData) {
                            setPercentage(showData.percentage);
                            setDescription(showData.description);
                        }
                    }
                })
        }
    }, [header])
    useEffect(() => {
        getAdminData(id);
    }, [id]);
    // Submit Form
    const onSubmit = async (data, header) => {
        try {
            mutate(data, header)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }
    // const onSubmit = data => props.update(data, header)

    if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Update Commission</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/agents/comission"
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Comission */}
                                        <div className="col-12">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.percentage && errors.percentage.message ? (
                                                    <p className="text-danger">{errors.percentage && errors.percentage.message}</p>
                                                ) : <p>Comission</p>}

                                                <input
                                                    type="text"
                                                    name="percentage"
                                                    placeholder="Enter name"
                                                    className={errors.percentage ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={percentage}
                                                    ref={register({ required: "Comission is required" })}
                                                />
                                            </div>
                                        </div>
                                        </div>
    
                                        {/* Description */}
                                        <div className="col-12">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.description && errors.description.message ? (
                                                    <p className="text-danger">{errors.description && errors.description.message}</p>
                                                ) : <p>Description</p>}

                                                <textarea
                                                    type="text"
                                                    name="description"
                                                    placeholder="Enter description"
                                                    rows={5}
                                                    className={errors.description ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    defaultValue={description}
                                                    ref={register({ required: "Description is required" })}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        

                                        <div className="col-12 col-lg-6 text-right">
                                            <br />
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Updating...' : 'Update'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default EditHRMUsers;