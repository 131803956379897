import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Select from "react-select"
import { DateSpliter } from '../../../../utils/helpers'
import DateRangePicker from "rsuite/DateRangePicker"
import LoadingComponent from '../../../../components/loading/Index'
import { useQuery } from "react-query"
import { api } from "../../../../utils/api"
import Axios from 'axios'
import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"
import ExpenseTable from '../../../../components/table/Expense'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { plus } from 'react-icons-kit/feather'
import Pagination from "../../../../components/pagination/Index";
import NumberFormat from 'react-number-format';

const Expense = () => {
    const history = useHistory()
    const location = useLocation()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [items, setItems] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [pageReload, setPageReload] = useState(true)
    const [totalPage, setTotalPage] = useState(null);
    const [totalAmount, setTotalAmount]= useState(null);
    const typeOptions = [
        { label: 'None', value: null },
        { label: 'Office Rent', value: 'Office Rent' },
        { label: 'Salary', value: 'Salary' },
        { label: 'Utility', value: 'Utility' },
        { label: 'Mobile Recharge', value: 'Mobile Recharge' },
        { label: 'Others', value: 'Others' }
    ]
    const query = queryString.parse(location.search)
    const [queryParams, setQueryParams] = useState({
        page: query.page || 1,
        limit: query.limit || 30,
        admin: query.admin || null,
        date: query.date || null,
        type: query.type || null
    });

    // Fetch admin list
    const fetchData = useCallback(async () => {
        let adminOptions = [{ id: 0, value: `null`, label: `None`, _id: `null` }];
        const response = await Axios.get(`${api}auth/index`, header);
        if (response.status === 200) {
            response.data.adminList.map(async (one, idx) => {
                await adminOptions.push({
                    id: idx,
                    value: `${one.email}`,
                    label: `${one.name} - ${one.email}`,
                    _id: `${one._id}`
                });
            });
            setItems(adminOptions)
            setLoading(false)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    const getList = async () => {
        const res = await Axios.get(`${api}account/expense/index/${location.search}`, header)
        setPageReload(false)
        setTotalPage(res.data.totalPage);
        setTotalAmount(res.data.totalAmount)
        return res.data.expenseList;
    }

    const expenseList = useQuery("lists", getList, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageReload),
    });

    //   clear date range
    const handleRangeClean = (e) => {
        e.preventDefault();
        setQueryParams({ ...queryParams, date: null });
        setPageReload(true);
        history.push(`/dashboard/accounts/expense?page=${queryParams.page}&limit=${queryParams.limit}&date=null&admin=${queryParams.admin}&type=${queryParams.type}`)
    }

    //   Handle data range
    const handleDate = (data) => {
        if(data){
            let from = DateSpliter(data[0])
            let to = DateSpliter(data[1])
            setQueryParams({ ...queryParams, date: `${from}~${to}` });
            setPageReload(true)
            history.push(`/dashboard/accounts/expense?page=${queryParams.page}&limit=${queryParams.limit}&date=${`${from}~${to}`}&admin=${queryParams.admin}&type=${queryParams.type}`)
        } 
    } 

    // Handle Assign to
    const handleAssignTo = (data) => {
        setQueryParams({ ...queryParams, admin: data._id })
        history.push(`/dashboard/accounts/expense?page=${queryParams.page}&limit=${queryParams.limit}&date=${queryParams.date}&admin=${data._id}&type=${queryParams.type}`)
        setPageReload(true)
    }

    // Handle type to
    const handleType = (data) => {
        setQueryParams({ ...queryParams, type: data.value })
        history.push(`/dashboard/accounts/expense?page=${queryParams.page}&limit=${queryParams.limit}&date=${queryParams.date}&admin=${queryParams.admin}&type=${data.value}`)
        setPageReload(true)
    }

    // change page no
  const changePage = (page) => {
    setQueryParams({ ...queryParams, page: page });
    history.push(`/dashboard/accounts/expense?page=${page}&limit=${queryParams.limit}&date=${queryParams.date}&admin=${queryParams.admin}&type=${queryParams.type}`)
    setPageReload(true);
  };

    const defaultValueHandler = (data, options) => {
        if (data && data !== "null")
            return options.find((obj) => obj._id === data);
        else return null;
    };

    const defaultValueHandlerType = (data, options) => {
        if (data && data !== "null")
            return options.find((obj) => obj.value === data);
        else return null;
    };

    if (isLoading) return <LoadingComponent />
    return (
        <div className="expence-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0 d-inline">Expences</h6></div>
                                    <div className='ml-auto pr-2'>
                                        <Link
                                            to="/dashboard/accounts/expense/create"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={plus} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="justify-content-end filter-section">
                                {/* Filter by date range */}
                                <div className="filter-item">
                                    <div className="item-body shadow-sm rangepicker">
                                        <span>Filter by Date</span>
                                        <div className="form-group m-0">
                                        <DateRangePicker
                                            onChange={handleDate}
                                            onClean={handleRangeClean}
                                            block
                                        />
                                        </div>
                                    </div>
                                </div>

                                {/* Assign To */}
                                <div className="filter-item">
                                    <div className="item-body shadow-sm">
                                        <span>AdminList</span>
                                        <Select
                                            classNamePrefix="custom-select"
                                            styles={customStyles}
                                            placeholder={"Select"}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            options={items}
                                            defaultValue={defaultValueHandler(
                                                query.admin,
                                                items
                                            )}
                                            onChange={(event) => handleAssignTo(event)}
                                            menuPortalTarget={document.body}
                                        />
                                    </div>
                                </div>

                                <div className="filter-item">
                                    <div className="item-body shadow-sm">
                                        <span>Type</span>
                                        <Select
                                            classNamePrefix="custom-select"
                                            styles={customStyles}
                                            placeholder={"Select"}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            options={typeOptions}
                                            defaultValue={defaultValueHandlerType(
                                                query.type,
                                                typeOptions
                                            )}
                                            onChange={(event) => handleType(event)}
                                            menuPortalTarget={document.body}
                                        />
                                    </div>
                                </div>
                                <div className="filter-item">
                                    <div className="item-body shadow-sm">
                                        <span>Total Amount: </span>
                                        <div  styles={customStyles} className="p-2">
                                            <NumberFormat
                                                value={totalAmount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={' TK'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <ExpenseTable items={expenseList.data} refetch={()=>setPageReload(true)}/>
                            </div>
                            <div className="card-footer bg-white border-0 pt-0 pb-4">
                                <Pagination totalpage={totalPage} changePage={changePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Expense;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: "#000",
        minWidth: 170,
        boxShadow: "none",
        "&:hover": { borderColor: "1px solid #ced4da" },
        border: state.isFocused ? "1px solid #dfdfdf" : "1px solid #ced4da",
        borderRadius: 0,
        menuPortal: base => ({ ...base, zIndex: 999 })
    }),
};