import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import './style.scss'

const Index = () => {
    const refs = useRef()

    return (
        <div className="ghost-gallery">
            {[...Array(20).keys()].map((i) => {
                return (
                    <div className="ghost-container" key={i} ref={refs}>
                        <Skeleton width={refs.innerWidth} height={200} />
                    </div>
                )
            })}
        </div>
    );
}

export default Index;