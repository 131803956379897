import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Withdraw List item
const WithdrawList = async (data, header) => {
    try {
        const response = await Axios.get(`${api}ghotok/withdrawlist`, data, header)
        if (response.status === 200) {
            return response.data.WithdrawList
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update Profile item image
const UpdateProfileImage = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/profile/imageupdate`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update NID item image
const UpdateNIDImage = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/nid/imageupdate`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update AnyBillCopy item image
const UpdateAnyBillCopyImage = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/anybillcopy/imageupdate`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const Story = {
    WithdrawList,
    UpdateProfileImage,
    UpdateNIDImage,
    UpdateAnyBillCopyImage,
   
}

export default Story