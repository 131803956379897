import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { useMutation } from 'react-query'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import { useHistory } from 'react-router-dom'

toast.configure({ autoClose: 2000 })
const AddComission = () => {
    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
//description percentage
    const createComission = async (data, header) => {
        const res = await Axios.post(`${api}ghotok/comission/create`, data, header)
        return res.data.message
    }

    const { mutate, isLoading, isError } = useMutation(createComission, {
        onSuccess: (data) => {
            history.push('/dashboard/agents/comission')
            toast.success(data)
        }
    })

    // Submit Form
    const onSubmit = async (data) => {
        try {
            mutate(data, header)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Add Comission</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/agents/comission"
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Comission */}
                                        <div className="col-12">
                                        <div className="col-lg-6 ">
                                            <div className="form-group mb-4">
                                                {errors.percentage && errors.percentage.message ? (
                                                    <p className="text-danger">{errors.percentage && errors.percentage.message}</p>
                                                ) : <p>Comission</p>}

                                                <input
                                                    type="text"
                                                    name="percentage"
                                                    placeholder="Enter Comission"
                                                    className={errors.percentage ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "percentage is required" })}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        
                                        {/* Description */}
                                        <div className="col-12">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.description && errors.description.message ? (
                                                    <p className="text-danger">{errors.description && errors.description.message}</p>
                                                ) : <p>Description</p>}

                                                <textarea
                                                    name="description"
                                                    rows={5}
                                                    className="form-control shadow-none"
                                                    placeholder="Write description"
                                                    ref={register()}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        
                                        <div className="col-12 text-right">
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Creating...' : 'Create'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddComission;