import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    const refs = useRef()

    return (
        <div className="ghost-posts">
            <div className="row">

                {[...Array(6).keys()].map((i) => {
                    return (
                        <div className="col-12 col-md-6 col-xl-4 mb-3 mb-md-4" key={i}>
                            <div className="card border-0 shadow-sm mb-md-2" ref={refs} style={{ borderRadius: 6 }}>
                                <Skeleton height={300} width={refs.innerWidth} />
                                <div className="card-body">

                                    {/* Profile section */}
                                    <div className="profile-section d-flex">
                                        <div className="profile-img-container rounded-circle">
                                            <Skeleton height={40} width={40} circle={true} />
                                        </div>
                                        <div className="pl-2">
                                            <Skeleton height={20} width={150} />
                                            <br />
                                            <Skeleton height={10} width={100} />
                                        </div>
                                    </div>

                                    {/* Content */}
                                    <div className="content mt-4" ref={refs}>
                                        <Skeleton height={20} width={refs.innerWidth} count={5} />
                                        <br />
                                        <br />
                                    </div>

                                    {/* Footer */}
                                    <div className="footer-section">
                                        <div className="d-flex">
                                            <div className="p-2 flex-fill" ref={refs}>
                                                <Skeleton height={45} width={refs.innerWidth} />
                                            </div>
                                            <div className="p-2 flex-fill" ref={refs}>
                                                <Skeleton height={45} width={refs.innerWidth} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    );
}

export default Index;