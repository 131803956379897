import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { edit2, trash } from 'react-icons-kit/feather'
import SweetAlert from "react-bootstrap-sweetalert";
import Axios from 'axios'
import { api } from '../../utils/api'
import jwt_decode from "jwt-decode"
import { eye } from 'react-icons-kit/feather'
import { toast } from 'react-toastify'

const Expanse = ({ items, refetch }) => {
    const [show, setshow] = useState(false);
    const [data, setData] = useState(null);
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const showAlertDialog = (id) => {
        setData(id);
        setshow(true);
    };

    const deleteExpense = async () => {
        const user = jwt_decode(localStorage.getItem('token'));
        if (user.role === 'super_admin') {
            const res = await Axios.delete(`${api}account/expense/delete/${data}`, header)
            if (res.status === 200 && res.data.status) {
                toast.success(res.data.message)
                refetch()
                setshow(false);
            }
            else if (res.status === 200 && !res.data.status) {
                toast.warn(res.data.message)
                setshow(false);
            }
        }
        else{
            toast.warn('You are not authorize for this action.')
            setshow(false);
        }

    }

    return (
        <div className="content-image-table-container content-table-container pb-4">
            <table className="table table-hover table-responsive-xl table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center sl-td">SL</td>
                        <td>C/O</td>
                        <td>Date</td>
                        <td>Amount</td>
                        <td>Type</td>
                        <td>Method</td>
                        <td>Comment</td>
                        <td>Created By</td>
                        <td className="text-td text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom" key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                {/* <td>{item.careOf ? <UserInfo data={item.careOf} /> : <p>N/A</p>}</td> */}
                                <td className="text-td">
                                    {item.careOf ? <p className="text-capitalize mb-0">{item.careOf.name}</p> : <p>N/A</p>}
                                    {item.careOf ? <small className="text-lowercase mb-0"><i>{item.careOf.email}</i></small> : null}
                                </td>
                                <td className="text-td">{item.date ? item.date.split("T")[0] : "N/A"}</td>
                                <td className="text-td">{item.amount ? `${item.amount} TK` : "N/A"}</td>
                                <td className="text-td">{item.type ? item.type : <p>N/A</p>}</td>
                                <td className="text-td">{item.method ? item.method : <p>N/A</p>}</td>
                                <td className="text-td">{item.comment ? `${item.comment.substring(0, 50)}` : <p>N/A</p>}</td>

                                <td className="text-td">
                                    {item.createdBy ? <p className="text-capitalize mb-0">{item.createdBy.name}</p> : <p>N/A</p>}
                                    {item.createdBy ? <small className="text-lowercase mb-0"><i>{item.createdBy.email}</i></small> : null}
                                </td>
                                <td className="button-td text-center">
                                    <Link
                                        type="button"
                                        to={`/dashboard/accounts/expense/${item._id}/update`}
                                        className="btn btn-sm shadow-none table-edit-btn"
                                    >
                                        <Icon icon={edit2} size={20} />
                                    </Link>

                                    <Link
                                        type="button"
                                        className="btn btn-sm shadow-none table-trash-btn"
                                        to={`/dashboard/accounts/expense`}
                                    >
                                        <Icon icon={trash} size={20}
                                            onClick={() => showAlertDialog(item._id)}
                                        />
                                    </Link>

                                    <Link
                                        type="button"
                                        to={`/dashboard/accounts/expense/${item._id}/show`}
                                        className="btn btn-sm shadow-none table-show-btn"
                                    >
                                        <Icon icon={eye} size={20} />
                                    </Link>

                                </td>
                            </tr>
                        ) : null}
                </tbody>
            </table>
            <SweetAlert className="btnplus"
                showCancel
                show={show}
                confirmBtnText="Delete"
                confirmBtnBsStyle='#ff5733'
                cancelBtnBsStyle='#ff5733'
                title="Do you want to delete this expense history?"
                onConfirm={() => deleteExpense()}
                onCancel={() => setshow(false)}
            ></SweetAlert>

        </div>
    )
};

export default Expanse;