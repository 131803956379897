import React from 'react'
import './style.scss'
import { dateFormate } from '../../../utils/helpers'

const WithdrowList = ({ items }) => {
    return (
        <div className="content-image-table-container content-table-container pb-4">
            <table className="table table-hover table-responsive-xl table-borderless">
                <thead>
                    <tr className="border-bottom text-center">
                        <td className=" sl-td">SL</td>
                        {/* <td>SL</td> */}
                        <td>Date</td>
                        <td>Agent</td>
                        <td>AgentId</td>
                        <td>Status</td>
                        <td>Amount</td>
                        <td>Method</td>
                        <td>A.C Number</td>
                        <td>Comment</td>
                        {/* <td className="text-td text-center">Action</td> */}
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom text-center " key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td className="text-td">{dateFormate(item.createdAt)}</td>
                                <td className="text-td">{item.ghotok.name}</td>
                                <td className="text-td">{item.ghotok.ghotokMediaId}</td>
                                <td className="text-td">{item.ghotok.status}</td>
                                <td className="text-td">{item.amount}</td>
                                <td className="text-td">{item.ghotok.mobileNumberFor}</td>
                                <td className="text-td">{item.ghotok.mobileBankingNumber}</td>
                                <td className="text-td">{item.comment}</td>
                            </tr>
                        ) : null}
                </tbody>
            </table>

        </div>
    )
};

export default WithdrowList;
