import React, { useState, } from 'react';
import "./style.scss"
import { Icon } from 'react-icons-kit'
import { search, loader } from 'react-icons-kit/feather'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
// import NoDataComponent from '../../..//noData/Index'
import DateRangePicker from "rsuite/DateRangePicker";
import { eye } from 'react-icons-kit/feather'
import Axios from 'axios'
import { useForm } from 'react-hook-form'
import GhostTable from '../../../../components/ghostLoader/table/Index'
import { DateSpliter } from '../../../../utils/helpers'
import { Images } from '../../../../utils/Images'
import { api } from "../../../../utils/api"
import { toast } from 'react-toastify'
toast.configure({ autoClose: 2000 })


const History = () => {
    const { register, handleSubmit, errors } = useForm()
    const [pageReload, setPageReload] = useState(true)
    const [searching, setSearching] = useState(false)
    const [searchValues, setSearchValues] = useState(null)
    const [dateRange, setDateRange] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })


    const getSms = async () => {
        try {
            setSearching(true)
            const response = await Axios.get(`${api}marketing/sms/show?query=${searchValues}&dateRange=${dateRange}`, header);
            setPageReload(false)
            setSearching(false)
            return response.data.adminSms;

        } catch (error) {
            toast.warning(error.message)
        }

    }

    const queryData = useQuery('adminSms', getSms, {
        retry: 2,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageReload)
    })

    // clear search
    const onChangeSearch = event => {
        if (!event.target.value) {
            setSearchValues(null)
            setPageReload(true)
        }
        setSearchValues(event.target.value)
    }

    // Search user
    const submitSearch = async () => {
        setPageReload(true)
    }

    //   Handle data range
    const handleRange = (range) => {
        if (range) {
            let from = DateSpliter(range[0])
            let to = DateSpliter(range[1])
            setDateRange(`${from}~${to}`)
            setPageReload(true);
        }
    }

    //   clear date range
    const handleRangeClean = (e) => {
        e.preventDefault();
        setDateRange(null);
        setPageReload(true);
    }

    // If loading to get data
    if (queryData.isLoading) return (<GhostTable />)
    if (queryData.isError) return <div className='text-center mt-5'>Something went wrong</div>

    return (
        <div className='history_table'>
            <div className="container-fluid">

                {/* Search */}
                <div className="filter-item">
                    <div className="item-body">
                        <span>profileId / phone</span>
                        <form onSubmit={handleSubmit(submitSearch)}>
                            <div className="form-group m-0">
                                <input
                                    type="text"
                                    name="query"
                                    className={errors.query ? "form-control rounded-0 shadow-sm border-danger" : "form-control rounded-0 shadow-sm"}
                                    placeholder="Search"
                                    ref={register({ required: true })}
                                    disabled={searching}
                                    defaultValue={searchValues ? searchValues : null}
                                    onChange={onChangeSearch}
                                />
                                <Icon icon={searching ? loader : search} className={searching ? "icon spin primary-color" : "icon"} size={18} />
                            </div>
                        </form>
                    </div>
                </div>

                {/* Filter by date range */}
                <div className="filter-item">
                    <div className="item-body shadow-sm rangepicker">
                        <span>Filter by Date</span>
                        <div className="form-group m-0">
                            <DateRangePicker
                                onChange={handleRange}
                                onClean={handleRangeClean}
                                block
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-4 bg-white d-flex justify-content-between align-items-center">
                                <h6 className="mb-0">Send Message to Users history</h6>

                                <Link to="/dashboard/marketing/sms">
                                    <h6 className="mb-0">Back</h6>
                                </Link>
                            </div>
                            <div className="card-body p-4">
                                <table className="table table-responsive table-borderless">
                                    <thead>
                                        <tr className="border-bottom">
                                            <td className="text-center">SL</td>
                                            <td className="text-center">Image</td>
                                            <td>Profile ID</td>
                                            <td>Name</td>
                                            <td>phone</td>
                                            <td>Date</td>
                                            <td>Sender</td>
                                            <td>Message</td>
                                            <td className="text-center">Profile</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            queryData.data.map((sms, i) =>
                                                <tr className="border-bottom" key={i}>
                                                    <td className="text-center">{i + 1}</td>
                                                    <td className="text-center">
                                                        <div className="image-container rounded-circle">
                                                            <img src={sms.image ? sms.image : Images.Avatar} className="img-fluid" alt="..." />
                                                        </div>
                                                    </td>
                                                    <td>{sms.profileId}</td>
                                                    <td>{sms.userName}</td>
                                                    <td>{sms.userPhone}</td>
                                                    <td>{sms.date}</td>
                                                    <td>{sms.senderName}</td>
                                                    <td>{sms.message}</td>
                                                    <td className="text-right">
                                                        <div>
                                                            <Link
                                                                to={`/dashboard/user/${sms.userId}/show`}
                                                                type="button"
                                                                className="btn btn-sm shadow-none"
                                                            ><Icon icon={eye} size={18} /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default History;