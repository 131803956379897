import React from 'react'
import './style.scss'
import { Images } from '../../utils/Images'

const Index = () => {
    return (
        <div className="no-data-container">
            <img src={Images.NoData} className="img-fluid" alt="..." />
            <p>Opps ! Data not found .</p>
        </div>
    );
}

export default Index;