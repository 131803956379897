import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../utils/api'
import { Images } from '../../../utils/Images'
import 'react-toastify/dist/ReactToastify.css'
import { check, plus, edit2 } from 'react-icons-kit/feather'
import GhostPackage from '../../../components/ghostLoader/membershipPackage/Index'

toast.configure({ autoClose: 2000 })
const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // get packages
    const getPackages = useCallback(async () => {
        try {
            const response = await Axios.get(`${api}/package/index`, header)
            if (response.status === 200) {
                setItems(response.data.packages)
                setLoading(false)
            }
        } catch (error) {
            if (error) {
                setLoading(false)
                toast.error(error.response.data.message)
            }
        }
    }, [header])

    useEffect(() => {
        getPackages()
    }, [getPackages, header])


    if (isLoading) return <GhostPackage />

    return (
        <div className="membership-package-index">
            <div className="container-fluid">
                <div className="row">

                    {items && items.map((item, i) =>
                        <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 col-padding mb-2" key={i}>
                            {/* Package card */}
                            <div className="card border-0 package-card">
                                <div className="card-body">
                                    <div className="image-container rounded-circle">
                                        <img src={item.image || Images.Logo2} className="img-fluid" alt="..." />
                                    </div>
                                    <br />

                                    <h5>{item.title}</h5>  {/* Package title */}
                                    <div className="pricing mb-3">
                                        <h6>{item.type}</h6> {/* Package type */}
                                        <h3>{item.discountPercentage}% off</h3> {/* Discaount percentage*/}
                                        <h5 className={item.discountPercentage === 0 ? "" : "linecross"}>{item.currency} {item.currencyType}</h5> {/* Package price */}
                                        <h4 className={item.discountPercentage === 0 ? "hidden" : ""}>{item.finalCurrency} {item.currencyType}</h4> {/* Package price */}
                                        {
                                            item.durationType === 'Day' ? <p>For {item.duration} day</p>
                                            : <p>For {item.duration} month</p>
                                        }
                                    </div>

                                    <ul>
                                        <li><p><Icon icon={check} size={20} className="text-success mr-2" />Total proposal limit: {item.proposal.totalLimit} person</p></li>
                                        <li><p><Icon icon={check} size={20} className="text-success mr-2" />Contact view limit: {item.proposal.contactViewLimit}</p></li>
                                        {/* <li><p><Icon icon={check} size={20} className="text-success mr-2" />Message limit: {item.proposal.messageLimit}</p></li> */}
                                        <li><p><Icon icon={check} size={20} className="text-success mr-2" />Unlimited Messaging</p></li>
                                    </ul>

                                    <hr />

                                    <div className="d-flex">
                                        <div className="flex-fill">
                                            <p>Status: {item.status ?
                                                <span className="text-success">Active</span> :
                                                <span className="text-danger">Deactive</span>}
                                            </p>
                                            <p className="mb-0">Created by: {item.admin.name}</p>
                                        </div>
                                        <div className="pt-1">
                                            <Link
                                                to={`/dashboard/membership-packages/${item._id}/edit`}
                                                type="button"
                                                className="btn btn-light rounded-circle shadow-none"
                                            >
                                                <Icon icon={edit2} size={16} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>


            {/* Float button */}
            <Link
                to="/dashboard/membership-packages/create"
                type="button"
                className="btn shadow-none rounded-circle text-white float-btn"
            >
                <Icon icon={plus} size={22} />
            </Link>
        </div>
    );
}

export default Index;