import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { x } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import Select from "react-select";

const Create = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const [type, setType] = useState(null)
    const [manager, setManager] = useState({ value: null, error: null })
    const handleSelect = (e) => { setType(e.target.value) }
    const handleManager = event => {
        setManager({ ...manager, value: event.value, error: null })
    }

    // Submit Form
    const onSubmit = async (data) => {
        let obj = { ...data }
        if (type === 'single' && !manager.value) {
            return setManager({ ...manager, error: 'select manager' })
        }
        else if (type === 'single' && manager.value) {
            Object.assign(obj, { 'to': manager.value })
        }
        props.create(obj)
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div>
                            <Modal.Title>Create Notice</Modal.Title>
                        </div>
                        <div className="ml-auto">
                            <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                                <Icon icon={x} size={22} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Notice for */}
                        <div className="form-group mb-3">
                            <p>Notice for</p>

                            <select
                                name="noticeFor"
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                ref={register({ required: 'This field is required.' })}
                                onChange={handleSelect}

                            >
                                <option value="all">All</option>
                                <option value="single">Single</option>
                            </select>
                        </div>

                        {/* managerlist */}
                        {
                            type === 'single' &&
                            <div className="form-group mb-3">
                                {manager.error ? <p className="text-danger">{manager.error}</p> : <p>To (Manager)</p>}
                                <Select
                                    classNamePrefix="custom-select"
                                    styles={customStyles}
                                    placeholder={"Select"}
                                    error={manager.error}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                    }}
                                    options={props.managers}
                                    onChange={handleManager}
                                />
                            </div>
                        }

                        {/* Title */}
                        <div className="form-group mb-3">
                            {errors.title && errors.title.message ? (
                                <p className="text-danger">{errors.title && errors.title.message}</p>
                            ) : <p>Title</p>}
                            <input
                                name="title"
                                placeholder="Enter notice title"
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                ref={register({ required: 'Title is required.' })}
                            />
                        </div>

                        {/* Description */}
                        <div className="form-group mb-3">
                            {errors.description && errors.description.message ? (
                                <p className="text-danger">{errors.description && errors.description.message}</p>
                            ) : <p>Description</p>}

                            <textarea
                                rows="5"
                                name="description"
                                style={{ height: 100 }}
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                placeholder="Write description here..."
                                ref={register({ required: 'Description is required.' })}
                            />
                        </div>

                        {/* Priority */}
                        <div className="form-group mb-3">
                            <p>Priority</p>
                            <select
                                name="priority"
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                ref={register({ required: 'Priority is required.' })}
                            >
                                <option value="true">High</option>
                                <option value="false">Low</option>
                            </select>
                        </div>

                        <div className="text-right">
                            <button type="submit" className="btn shadow-none" disabled={props.loading}>
                                {props.loading ? 'Creating...' : 'Create'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Create;
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: "#000",
        minWidth: 170,
        boxShadow: "none",
        "&:hover": { borderColor: "1px solid #ced4da" },
        border: state.isFocused ? "1px solid #dfdfdf" : "1px solid #ced4da",
        borderRadius: 0,
    }),
};