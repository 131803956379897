import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import GhostLogs from '../../../components/ghostLoader/user/logsLoader/index'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Icon } from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
// import { trash2 } from 'react-icons-kit/feather'
import Logs from '../../../utils/Requests/Logs'
toast.configure({ autoClose: 2000 })

const Index = () => {
    const { email } = useParams()
    const [isloading, setLoading] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
    const { register, handleSubmit, errors } = useForm()
    const [logs, setLogs] = useState(null)
    const [updater, setUpdater] = useState({ status: false, id: null, sl: null })
    const [inputData, setInputData] = useState(null)

    const getLogs = useCallback(async () => {
        try {
            setLoading(true)
            const response = await Logs.Index(email, header)
            setLogs(response.logs)
            setLoading(false)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }, [email, header])

    useEffect(() => {
        getLogs()
    }, [email, getLogs])

    const onSubmit = async (data) => {
        try {
            setLoading(true)
            let response
            if (updater.status) {
                let obj = {
                    id: updater.id,
                    data: data.log
                }
                response = await Logs.Update(obj, header)
            }
            else {
                let obj = {
                    email: email,
                    data: data.log
                }
                response = await Logs.Create(obj, header)
            }
            toast.success(response.message)
            setLoading(false)
            getLogs()
            setUpdater({ status: false, id: null, sl: null })
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    // const handleDelete = async data => {
    //     try {
    //         setLoading(true)
    //         const response = await Logs.Delete(data, header)
    //         toast.success(response.message)
    //         setLoading(false)
    //         getLogs()
    //     } catch (error) {
    //         if (error) {
    //             toast.error(error.response.data.message)
    //         }
    //     }
    // }

    const handleUpdate = async (i, data) => {
        setUpdater({ status: true, id: data._id, sl: i })
        setInputData(data.body)
    }

    const handleInput = (e) => {
        if (!e.target.value) {
            setUpdater({ status: false, id: null, sl: null })
        }
        setInputData(e.target.value)
    }

    if (isloading) return <GhostLogs />

    return (
        <div className='user-logs'>
            <div className='container-form'>
                <h6>{updater.status ? `Update log ${updater.sl}` : 'Create logs'}</h6>
                {errors.log ? <span className='text-danger'>{errors.log.message}</span> : null}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <textarea
                        type="text"
                        name="log"
                        placeholder="type here..."
                        className='log-fields'
                        value={updater.status ? inputData : null}
                        onChange={(e) => handleInput(e)}
                        ref={register({ required: "*text is required" })}
                    />
                    <button
                        type="submit"
                        className="addbtn"
                        disabled={isloading}
                    >{(updater.status && !isloading) ? 'Update' : (updater.status && isloading) ? 'Updating' : (!updater.status && isloading) ? 'Saving...' : 'Save'}</button>
                </form>
            </div>
            <div className='container-list'>
                <h6>Logs</h6>
                <div>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Logs</th>
                                <th>Date</th>
                                <th>Listed by</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs ?
                                logs.map((one, i) =>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{one.body}</td>
                                        <td>{one.updatedAt.split("T")[0]}</td>
                                        <td>{one.admin.name}</td>
                                        <td>
                                            <button><Icon icon={edit2} size={18} className="mr-2" onClick={() => handleUpdate(i + 1, one)} /></button>
                                            {/* <button><Icon icon={trash2} size={18} onClick={() => handleDelete(one._id)} /></button> */}
                                        </td>
                                    </tr>)
                                : 'no such log found'
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Index
