import React, { useState } from 'react'
import './style.scss'
import { Row, Col } from 'react-bootstrap'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import { x, checkCircle, search, loader } from 'react-icons-kit/feather'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { Images } from '../../../utils/Images'
import { useQuery } from 'react-query'
import Pagination from '../../../components/pagination/Index'
import NoDataComponent from '../../../components/noData/Index'
toast.configure({ autoClose: 2000 })

const Index = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
    const [pageload, setPageload] = useState(true)
    const [singleSearch, setSingleSearch] = useState(false)
    const params = useParams()
    const [totalPage, setTotalPage] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [searching, setSearching] = useState({ value: null, error: null, loading: false })
    const [searching2, setSearching2] = useState({ value: null, error: null, loading: false })
    const [profiles, setProfiles] = useState([])
    const [incomeOptions, setIncomeOptions] = useState(null)
    const [country, setCountry] = useState(null)
    const [religion, setReligion] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [workingWith, setWorkingWith] = useState(null)
    const [professionArea, setProfessionArea] = useState(null)
    const [qualification, setQualification] = useState(null)
    const [matches, setMatches] = useState("all")
    let [searchValues, setSearchValues] = useState(null)
    const history = useHistory()
    const location = useLocation()
    const query = queryString.parse(location.search)
    const [queryParams, setQueryParams] = useState({
        page: query.page,
        limit: 20,
        materialStatus: query.materialStatus || null,
        profileType: query.profileType || null,
        ageRange: query.ageRange || null,
        heightRange: query.heightRange || null,
        professionArea: query.professionArea || null,
        religion: query.religion || null,
        livingCountry: query.livingCountry || null,
        city: query.city || null,
        qualification: query.qualification || null,
        workStatus: query.workStatus || null,
        socialTitle: query.socialTitle || null,
        income: query.income || null
    })

    // Get Options
    const getOptions = async () => {
        const response = await Axios.get(`${api}settingFields`, header)
        if (response.status === 200) {
            let option = response.data.settingFiled
            for (let i = 0; i < option.length; i++) {
                if (option[i].label === 'Income') {
                    setIncomeOptions(option[i].value)
                }
                else if (option[i].label === 'Social title') {
                    setSocialTitle(option[i].value)
                }
                else if (option[i].label === 'Working with') {
                    setWorkingWith(option[i].value)
                }
                else if (option[i].label === 'Profession area') {
                    setProfessionArea(option[i].value)
                }
                else if (option[i].label === 'Religion') {
                    setReligion(option[i].value)
                }
                else if (option[i].label === 'Country') {
                    setCountry(option[i].value)
                }
                else if (option[i].label === 'Qualification') {
                    setQualification(option[i].value)
                }
            }
            return response.data.settingFiled
        }
    }

    const options = useQuery('options', getOptions, {
        retry: false,
        cacheTime: 1500000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageload)
    })


    const fetchSuggestions = async () => {
        try {
            if (singleSearch && pageload) {
                if (!searching.value) return setSearching({ error: true })
                setSearching({ loading: true })
                const response = await Axios.get(`${api}user/search?query=${searching.value}`, header)
                if (response.status === 200) {
                    setTotalPage(1)
                    setSearching({ loading: false })
                    setPageload(false)
                    setSingleSearch(false)
                    return response.data.users
                }
            }
            else {
                const response = await Axios.get(`${api}${params.email}/${params.gender}/${matches}/queryindex${location.search}`, header)
                setPageload(false)
                setSingleSearch(false)
                setSearching2({ loading: false })
                setTotalPage(response.data.totalPage)
                return response.data.suggestions
            }
        } catch (error) {
            if (error) {
                setSearching({ loading: false })
                toast.warn(error.message)
            }
        }
    }

    const userData = useQuery("sendCvtoUser", fetchSuggestions, {
        retry: false,
        cacheTime: 900000,
        refetchOnWindowFocus: false,
        enabled: pageload
    })

    // Age options
    const ageOptions = [
        { value: '18-22', label: '18-22' },
        { value: '23-27', label: '23-27' },
        { value: '28-35', label: '28-35' },
        { value: '35-40', label: '35-40' },
        { value: '41-60', label: '41-60' },
        { value: 'null', label: 'Any' }
    ]

    // change page no
    const changePage = page => {
        setQueryParams({ ...queryParams, page: page })
        history.push(`?page=${page}&limit=${query.limit}&profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    };

    // Handle Age 
    const handleAge = data => {
        setQueryParams({ ...queryParams, ageRange: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${data}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    // Height options
    const heightOptions = [
        { label: "4'5 - 5'2", value: '134-157' },
        { label: "5'3 - 5'8", value: '160-172' },
        { label: "5'9 - 6'4", value: '175-193' },
        { label: "6'5 - 7'", value: '195-213' },
        { label: 'Any', value: 'null' }
    ]

    // Handle Height 
    const handleHeight = data => {
        setQueryParams({ ...queryParams, heightRange: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${data}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    // Material status options
    const materialStatusOptions = [
        { value: 'never married', label: 'Never Married' },
        { value: 'divorced', label: 'Divorced' },
        { value: 'annulled', label: 'Annulled' },
        { value: 'widowed', label: 'Widowed' },
        { value: 'married', label: 'Married' },
        { value: 'null', label: 'None' },

    ]

    // Handle material status
    const handleMaterialStatus = data => {
        setQueryParams({ ...queryParams, materialStatus: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${data}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    // Profile type options
    const profileTypeOptions = [
        { value: 'Free', label: 'Free' },
        { value: 'Paid', label: 'Paid' },
        { value: 'null', label: 'All' },
    ]

    // Handle profile type
    const handleProfileType = data => {
        setQueryParams({ ...queryParams, profileType: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${data}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle profession area
    const handleprofessionArea = data => {
        setQueryParams({ ...queryParams, professionArea: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${data}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle Qualification
    const handleQualification = data => {
        setQueryParams({ ...queryParams, qualification: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${data}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle work status
    const handleworkStatus = data => {
        setQueryParams({ ...queryParams, workStatus: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${data}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle living country
    const handleLivingCountry = data => {
        setQueryParams({ ...queryParams, livingCountry: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${data}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle Religion
    const handleReligion = data => {
        setQueryParams({ ...queryParams, religion: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${data}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle socialTitle
    const handleSocialTitle = data => {
        setQueryParams({ ...queryParams, socialTitle: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${data}&income=${queryParams.income}`)
        setPageload(true)
    }

    //handle socialTitle
    const handleIncome = data => {
        setQueryParams({ ...queryParams, income: data })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${queryParams.city}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${data}`)
        setPageload(true)
    }

    // Search user by City
    const submitSearchCity = async (event) => {
        event.preventDefault()
        setSearching2({ loading: true })
        setQueryParams({ ...queryParams, city: searchValues })
        history.push(`?page=${queryParams.page}&limit=${query.limit}profileType=${queryParams.profileType}&materialStatus=${queryParams.materialStatus}&ageRange=${queryParams.ageRange}&heightRange=${queryParams.heightRange}&professionArea=${queryParams.professionArea}&qualification=${queryParams.qualification}&workStatus=${queryParams.workStatus}&livingCountry=${queryParams.livingCountry}&city=${searchValues}&religion=${queryParams.religion}&socialTitle=${queryParams.socialTitle}&income=${queryParams.income}`)
        setPageload(true)
    }

    // clear search
    const onChangeClearSearch = event => {
        if (!event.target.value) {
            setPageload(true)
            setSearchValues(null)
        }
        setSearchValues(event.target.value)
        setSearching2({ value: event.target.value, error: false })
    }

    // check available profile
    const checkAvailable = data => {
        const findItem = profiles.find(e => e === data)
        if (findItem)
            return true
        else
            return false
    }

    // check active
    const checkActive = data => {
        if (profiles) {
            const item = profiles.find(x => x === data)
            if (item)
                return true
            return false
        }
    }

    // remove profile
    const removeProfile = data => {
        const items = profiles.filter(e => e !== data)
        setProfiles([])
        setProfiles(items)
    }

    // Select Profile
    const selectProfile = profile => {
        if (checkAvailable(profile)) {
            removeProfile(profile)
        } else {
            setProfiles([...profiles, profile])
        }
    }

    // On change search
    const onChangeSearch = event => {
        if (!event.target.value) {
            setPageload(true)
        }
        setSearching({ value: event.target.value, error: false })
    }

    // Submit search
    const submitSearch = async (event) => {
        event.preventDefault()
        setSingleSearch(true)
        setPageload(true)
    }

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            const messageData = {
                email: [params.email],
                subject: data.subject,
                message: data.message,
                profiles: profiles ? profiles : null
            }

            const response = await Axios.post(`${api}email/send`, messageData, header)
            if (response.status === 201) {
                setLoading(false)
                setProfiles([])
            }

            setTimeout(() => {
                setLoading(false)
                toast.success('Successfully mail send')
            }, 1500);

        } catch (error) {
            if (error) {
                setLoading(false)
                toast.warn(error.response.data.message)
            }
        }
    }

    const defaultValueHandler = (data, options) => {
        if (data) return options.find(obj => obj.value === data)
        else return null
    }
    if (options.isLoading) return <NoDataComponent />

    return (
        <div className="container-fluid">
            <Row className='row filter-container'>
                {/* Profile Type */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Profile Type</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={profileTypeOptions}
                            defaultValue={defaultValueHandler(query.profileType, profileTypeOptions)}
                            onChange={(event) => handleProfileType(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* Material status */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Material state</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={materialStatusOptions}
                            defaultValue={defaultValueHandler(query.materialStatus, materialStatusOptions)}
                            onChange={(event) => handleMaterialStatus(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>
                {/* Age range */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Age range</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={ageOptions}
                            defaultValue={defaultValueHandler(query.ageRange, ageOptions)}
                            onChange={(event) => handleAge(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* Height Range */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Height range</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={heightOptions}
                            defaultValue={defaultValueHandler(query.heightRange, heightOptions)}
                            onChange={(event) => handleHeight(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* professionArea */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>profession Area</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={professionArea}
                            defaultValue={defaultValueHandler(query.professionArea, professionArea)}
                            onChange={(event) => handleprofessionArea(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* Qualification */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Qualification</span>

                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={qualification}
                            defaultValue={defaultValueHandler(query.qualification, qualification)}
                            onChange={(event) => handleQualification(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* workStatus */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Working status</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={workingWith}
                            defaultValue={defaultValueHandler(query.workStatus, workingWith)}
                            onChange={(event) => handleworkStatus(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* livingCountry */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Living country</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={country}
                            defaultValue={defaultValueHandler(query.livingCountry, country)}
                            onChange={(event) => handleLivingCountry(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* city */}
                <Col md={true} className="filter-item">
                    <span>City name</span>
                    <form onSubmit={submitSearchCity}>
                        <div className="item-body shadow-sm d-flex city-input">
                            <div className="form-group m-0 custom-width">
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control shadow-sm"
                                    placeholder="City"
                                    // ref={register({ required: true })}
                                    disabled={searching2.loading}
                                    defaultValue={searchValues ? searchValues : null}
                                    onChange={onChangeClearSearch}
                                />
                            </div>
                            <div>
                                <div>
                                    <button type="submit" className="btn shadow-none px-3" disabled={searching2.loading}>
                                        <Icon icon={searching2.loading ? loader : search} className={searching2.loading ? 'spin' : ''} size={18} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Col>

                {/* religion */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Religion</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={religion}
                            defaultValue={defaultValueHandler(query.religion, religion)}
                            onChange={(event) => handleReligion(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>
                {/* social title */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>social title</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={socialTitle}
                            defaultValue={defaultValueHandler(query.socialTitle, socialTitle)}
                            onChange={(event) => handleSocialTitle(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>

                {/* Income */}
                <Col md={true} className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Income</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={incomeOptions}
                            defaultValue={defaultValueHandler(query.income, incomeOptions)}
                            onChange={(event) => handleIncome(event.value)}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </Col>
            </Row>
            <br />
            {/* Search container */}
            <div className="search-container">
                <form onSubmit={submitSearch}>
                    <div className="d-flex">
                        <div className="form-group flex-fill pr-2">
                            <input
                                type="text"
                                placeholder="Search user"
                                onChange={onChangeSearch}
                                className={searching.error ? "form-control shadow-none error" : "form-control shadow-none"}
                            />
                        </div>
                        <div>
                            <button type="submit" className="btn shadow-none px-3" disabled={searching.loading}>
                                <Icon icon={searching.loading ? loader : search} className={searching.loading ? 'spin' : ''} size={18} />
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            {/* Show selected profiles */}
            <div className="selected-profiles-email-container">
                {profiles && profiles.map((email, i) =>
                    <div className="email-container" key={i}>
                        <p className="text-lowercase mb-0">{email}
                            <span>
                                <button type="button" className="btn rounded-circle shadow-none" onClick={() => removeProfile(email)}>
                                    <Icon icon={x} size={15} />
                                </button>
                            </span>
                        </p>
                    </div>
                )}
            </div>

            <div className="matches">
                <h6>Matches</h6>
                <button type="button"
                    className={matches === "all" ? "btn btn-active" : "btn btn-secondary"}
                    onClick={() => {
                        setMatches("all")
                        setPageload(true)
                    }}
                >All</button>
                <button type="button"
                    className={matches === "today" ? "btn btn-active" : "btn btn-secondary"}
                    onClick={() => {
                        setMatches("today")
                        setPageload(true)
                    }}
                >Today</button>
                <button type="button"
                    className={matches === "new" ? "btn btn-active" : "btn btn-secondary"}
                    onClick={() => {
                        setMatches("new")
                        setPageload(true)
                    }}
                >New</button>
                <button type="button"
                    className={matches === "near" ? "btn btn-active" : "btn btn-secondary"}
                    onClick={() => {
                        setMatches("near")
                        setPageload(true)
                    }}
                >Near</button>


            </div>

            {/* Suggested profiles */}
            {
                userData.data ?
                    <div className="card">
                        <div className="suggested-profile-container">
                            {userData.data.map((user, i) =>
                                <div className="profile-card card border-0" key={i}>
                                    <div className="card-body" onClick={() => selectProfile(user.email)}>
                                        <div className="image-container rounded-circle">
                                            <img src={user.image ? user.image : Images.Avatar} className="img-fluid" alt="..." />
                                        </div>

                                        <div className="content-container">
                                            <h6 className="text-capitalize mb-0">{user.name}</h6>
                                            <p className="text-lowercase mb-0">{user.email}</p>
                                        </div>

                                        <button type="button" className="btn shadow-none rounded-circle">
                                            <Icon icon={checkCircle} size={18} style={{ color: checkActive(user.email) ? '#28a745' : '#dfdfdf' }} />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="card-footer bg-white border-0 pt-0 pb-4">
                            <Pagination totalpage={totalPage} changePage={changePage} />
                        </div>
                    </div>
                    :
                    <NoDataComponent />
            }


            <div className='email-div'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* To */}
                    <div className="form-group mb-3">
                        <p>To</p>
                        <input
                            type="text"
                            defaultValue={params.email}
                            className="form-control shadow-none"
                            disabled
                        />
                    </div>

                    {/* Subject */}
                    <div className="form-group mb-3">
                        <p>Subject</p>
                        <input
                            type="text"
                            name="subject"
                            placeholder="Write subject"
                            className={errors.subject ? "form-control shadow-none error" : "form-control shadow-none"}
                            ref={register({ required: true })}
                        />
                    </div>

                    {/* Message */}
                    <div className="form-group mb-3">
                        <p>Mail</p>
                        <textarea
                            rows="5"
                            name="message"
                            className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                            placeholder="Write here..."
                            ref={register({ required: true })}
                        />
                    </div>

                    <div className="text-right">
                        <button type="submit" className="btn shadow-none px-4" disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Send'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Index;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: '#000',
        minWidth: 170,
        boxShadow: 'none', '&:hover': { borderColor: '1px solid #ced4da' },
        border: state.isFocused ? '1px solid #dfdfdf' : '1px solid #ced4da',
        borderRadius: 0,
        menuPortal: base => ({ ...base, zIndex: 999 })
    })
}