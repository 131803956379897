import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { edit2, trash } from 'react-icons-kit/feather'

import Requests from '../../utils/Requests/Index'
import DeleteComponent from '../modal/delete/Index'

const SettingsField = ({ items, refetch }) => {
    const [isDelete, setDelete] = useState({ id: null, values: null, show: false, loading: false })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const handleDelete = async () => {
        setDelete({ ...isDelete, loading: true })
        await Requests.Settings.DeleteValue(isDelete.id, isDelete.values, header)
        refetch()
        setDelete({ value: null, status: null, loading: null })
    }

    return (
        <div className="content-image-table-container">
            {items && items.length ?
                items.map((item, i) =>
                    <div className="card border-0 shadow-sm mb-3 mb-lg-4" key={i}>
                        <div className="card-header bg-white p-3">
                            <div className="d-flex">
                                <div><p className="mb-0" style={{ fontSize: 14 }}><b>SL: {i + 1}</b></p></div>
                                <div className="pl-2">
                                    <p className="text-capitalize mb-0" style={{ fontSize: 14 }}><b>{item.title}</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr className="border-bottom">
                                        <td className="text-center">SL</td>
                                        <td>Setting Field Value</td>
                                        <td className="text-center">Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.values && item.values.length ?
                                        item.values.map((value, j) =>
                                            <tr className="border-bottom" key={j}>
                                                <td className="text-center sl-td" style={{ width: 60 }}>{j + 1}</td>
                                                <td>{value}</td>
                                                <td className="text-center" style={{ width: 120 }}>
                                                    <Link
                                                        type="button"
                                                        to={`/dashboard/website/settings-field/value/${item._id}/edit?values=${value}`}
                                                        className="btn btn-sm py-1 shadow-none table-edit-btn"
                                                    >
                                                        <Icon icon={edit2} size={16} />
                                                    </Link>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm py-1 shadow-none table-trash-btn"
                                                        onClick={() => setDelete({ id: item._id, values: value, show: true, loading: false })}
                                                    >
                                                        <Icon icon={trash} size={16} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : null}

            {/* Delete modal */}
            {isDelete.values && isDelete.show ?
                <DeleteComponent
                    title={'Field value'}
                    image={null}
                    data={isDelete.values}
                    show={isDelete.show}
                    loading={isDelete.loading}
                    delete={handleDelete}
                    onHide={() => setDelete({ ...isDelete, id: null, values: null, status: false })}
                />
                : null}
        </div>
    );
};

export default SettingsField;