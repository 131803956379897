import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (email, header) => {
    try {
        const response = await Axios.get(`${api}userlogs/${email}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Create
const Create = async (data, header) => {
    try {
        const response = await Axios.post(`${api}create/userlog`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// Update
const Update = async (data, header) => {
    try {
        const response = await Axios.patch(`${api}update/userlog`,data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// Delete
const Delete = async (id, header) => {
    try {
        const response = await Axios.delete(`${api}delete/userlog/${id}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Logs = {
    Index,
    Create,
    Update,
    Delete
}
export default Logs;