
import Banner from './Banner'
import Story from './Story'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import TermsAndConditions from './TermsAndConditions'
import PagePremium from './PagePremium'
import MarriageCounseling from "./MarriageCounseling"
import Blog from "./Blog"
import Income from './Income'
import Settings from './Settings'
import Package from './Package'
import PackageSales from './PackageSales'
import User from './User'
import Ghotok from './Ghotok'
import Withdraw from './Withdraw'
import Addsense from './Addsense'
import Logs from './Logs'

const Requests = {
    Banner,
    Story,
    AboutUs,
    ContactUs,
    TermsAndConditions,
    PagePremium,
    MarriageCounseling,
    Blog,
    Ghotok,
    Withdraw,
    Income,
    Settings,
    Package,
    PackageSales,
    User,
    Addsense,
    Logs,
}

export default Requests