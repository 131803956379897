import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { useMutation } from 'react-query'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import { useHistory } from 'react-router-dom'

toast.configure({ autoClose: 2000 })
const HRMUsers = () => {
    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()
    const [type, setType] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const createRoles = async (data, header) => {
        const res = await Axios.post(`${api}auth/register`, data, header)
        return res.data.message
    }

    const { mutate, isLoading, isError } = useMutation(createRoles, {
        onSuccess: (data) => {
            history.push('/dashboard/hrm/dashboard')
            toast.success(data)
        }
    })

    // Submit Form
    const onSubmit = async (data) => {
        try {
            mutate(data, header)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }
    const handleSelect=(e)=>{
        setType(e.target.value);
      }
    if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Create Admin/Manager</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/hrm/dashboard"
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Name */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.name && errors.name.message ? (
                                                    <p className="text-danger">{errors.name && errors.name.message}</p>
                                                ) : <p>Name</p>}

                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Name is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Email */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.email && errors.email.message ? (
                                                    <p className="text-danger">{errors.email && errors.email.message}</p>
                                                ) : <p>Email</p>}

                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Email is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Password */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.password && errors.password.message ? (
                                                    <p className="text-danger">{errors.password && errors.password.message}</p>
                                                ) : <p>Password</p>}

                                                <input
                                                    type="text"
                                                    name="password"
                                                    placeholder="Enter password"
                                                    className={errors.password ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Password is required",
                                                        minLength: 8
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Role */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.role && errors.role.message ? (
                                                    <p className="text-danger">{errors.role && errors.role.message}</p>
                                                ) : <p>Role</p>}

                                                <select
                                                    name="role"
                                                    className={errors.role ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Role is required" })}
                                                    onChange={handleSelect}
                                                >
                                                    <option value="admin">Admin</option>
                                                    <option value="manager">Manager</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            type === 'manager' &&
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-4">
                                                        {errors.commission && errors.commission.message ? (
                                                            <p className="text-danger">{errors.commission && errors.commission.message}</p>
                                                        ) : <p>Commission %</p>}

                                                        <input
                                                            type="number"
                                                            name="commission"
                                                            placeholder="Enter commission"
                                                            className={errors.commission ? "form-control shadow-none error" : "form-control shadow-none"}
                                                            ref={register({
                                                                required: "commission is required"
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        {
                                            type === 'manager' &&
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-4">
                                                        {errors.packageSellTarget && errors.packageSellTarget.message ? (
                                                            <p className="text-danger">{errors.packageSellTarget && errors.packageSellTarget.message}</p>
                                                        ) : <p>Monthly package sell target</p>}

                                                        <input
                                                            type="number"
                                                            name="packageSellTarget"
                                                            placeholder="Enter monthly package sell target"
                                                            className={errors.packageSellTarget ? "form-control shadow-none error" : "form-control shadow-none"}
                                                            ref={register({
                                                                required: "package sell target is required"
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        {
                                            type === 'manager' &&
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-4">
                                                        {errors.amountSellTarget && errors.amountSellTarget.message ? (
                                                            <p className="text-danger">{errors.amountSellTarget && errors.amountSellTarget.message}</p>
                                                        ) : <p>Enter monthly amount sell target</p>}

                                                        <input
                                                            type="number"
                                                            name="amountSellTarget"
                                                            placeholder="monthly amount sell target"
                                                            className={errors.amountSellTarget ? "form-control shadow-none error" : "form-control shadow-none"}
                                                            ref={register({
                                                                required: "amount sell target is required"
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        <div className="col-12 text-right">
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Creating...' : 'Create'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRMUsers;