import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { api } from '../../../utils/api'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { eye, edit2, trash2, plusCircle } from 'react-icons-kit/feather'
import DeleteComponent from '../../modal/delete/Index'
// import LoadingComponent from '../../loading/Index'

const RejectGhotok = ({ items }) => {
    // const history = useHistory();
    const [isDelete, setDelete] = useState({ value: null, show: false, loading: false })
    
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    // Approved Ghotok
    const ApprovedGhotok = async (id) => {
        const res = await Axios.put(`${api}ghotok/${id}/approved`, header)
        if (res.status === 201) {
            // setLoading(true)
            window.location.reload();
        }
    }
    const handleDelete = async id => {
        setDelete({ ...isDelete, loading: true })
        const res = await Axios.delete(`${api}ghotok/${id}/delete`, header)
        if (res.status === 201) {
            // setLoading(true)
            // window.location.reload();
        }
        // setLoading(true)
        window.location.reload();
        setDelete({ value: null, status: null, loading: null })
    }
    // if (isLoading) return <LoadingComponent />

    return (
        <div className="content-image-table-container content-table-container pb-4">
            <table className="table table-hover table-responsive-xl table-borderless">
                <thead>
                    <tr className="border-bottom text-center">
                        <td className=" sl-td">SL</td>
                        <td>AgentId</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Phone</td>
                        <td>Address</td>
                        <td>Status</td>
                        <td className="text-td text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom text-center " key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td className="text-td">{item.ghotokMediaId}</td>
                                <td className="text-td">{item.name}</td>
                                <td className="text-td">{item.email}</td>
                                <td className="text-td">{item.phone}</td>
                                <td className="text-td">{item.address}</td>
                                <td className="text-td">{item.status}</td>
                                <td className="text-center ">
                                    <div className="btn-group">
                                        {/* Show user */}
                                        <Link
                                            to={`/dashboard/agents/${item._id}/show`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={eye} size={18} /></Link>
                                        <Link
                                            to={`/dashboard/agents/${item._id}/update`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon
                                                icon={edit2} size={18} /></Link>
                                        <Link
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon
                                                onClick={() => ApprovedGhotok(item._id)}
                                                icon={plusCircle} size={18} /></Link>
                                        <Link
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon
                                                onClick={() => setDelete({ value: item, show: true, loading: false })}
                                                icon={trash2} size={18} /></Link>
                                    </div>
                                </td>

                            </tr>
                        ) : null}
                </tbody>
            </table>
            {/* Delete modal */}
            {isDelete.value && isDelete.show ?
                <DeleteComponent
                    title={'Ghotok'}
                    data={isDelete.value._id}
                    show={isDelete.show}
                    loading={isDelete.loading}
                    delete={handleDelete}
                    onHide={() => setDelete({ ...isDelete, value: null, status: false })}
                />
                : null}
        </div>
    )
};

export default RejectGhotok;
