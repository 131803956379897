import React from 'react'

const HRMEmployees = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h1>HRM Employees</h1>
                </div>
            </div>
        </div>
    );
}

export default HRMEmployees;