import React from 'react'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { x } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'

const Edit = (props) => {
    const { register, handleSubmit, errors } = useForm()

    // Submit Form
    const onSubmit = async (data) => props.update({ ...data, id: props.data._id, to: props.data.to ? props.data.to._id : null })

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div>
                            <Modal.Title>Edit Notice</Modal.Title>
                        </div>
                        <div className="ml-auto">
                            <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                                <Icon icon={x} size={22} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* Title */}
                        <div className="form-group mb-3">
                            {errors.title && errors.title.message ? (
                                <p className="text-danger">{errors.title && errors.title.message}</p>
                            ) : <p>Title</p>}


                            <input
                                name="title"
                                placeholder="Enter notice title"
                                defaultValue={props.data.title}
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                ref={register({ required: 'Title is required.' })}
                            />
                        </div>

                        {/* Description */}
                        <div className="form-group mb-3">
                            {errors.description && errors.description.message ? (
                                <p className="text-danger">{errors.description && errors.description.message}</p>
                            ) : <p>Description</p>}

                            <textarea
                                rows="5"
                                name="description"
                                style={{ height: 100 }}
                                defaultValue={props.data.description}
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                placeholder="Write description here..."
                                ref={register({ required: 'Description is required.' })}
                            />
                        </div>

                        {/* Priority */}
                        <div className="form-group mb-3">
                            <p>Priority</p>

                            <select
                                name="priority"
                                defaultValue={props.data.priority}
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                ref={register({ required: 'Priority is required.' })}
                            >
                                <option value="true">High</option>
                                <option value="false">Low</option>
                            </select>
                        </div>

                        {/* Notice for */}
                        <div className="form-group mb-3">
                            <p>Notice for</p>
                            <input
                                name="noticeFor"
                                placeholder="Enter notice title"
                                value={props.data.noticeFor}
                                className={"form-control shadow-none"}
                                ref={register({ required: 'Title is required.' })}
                            />
                        </div>
                        {/* to */}
                        {
                            props.data.to &&
                            <div className="form-group mb-3">
                                <p>To</p>
                                <input
                                    name="to"
                                    placeholder="Enter notice title"
                                    value={props.data.to.name}
                                    className={"form-control shadow-none"}
                                    ref={register({ required: 'Title is required.' })}
                                />
                            </div>
                        }
                        <div className="text-right">
                            <button type="submit" className="btn shadow-none" disabled={props.loading}>
                                {props.loading ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Edit;