
// Date formate
export const dateFormate = (date) => {
    date = new Date(date)
    const cdate = date.toDateString()
    return cdate
}

// Date formate with AM/PM
export const formatDateWithAMPM = date => {
    date = new Date(date)
    const cdate = date.toDateString();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = cdate + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

// String Short
export const StringShort = data => {
    let string = data
    const firstLetter = string.slice(0, 1)
    return firstLetter
}

export const AgeCalculate = dob => Math.floor((new Date() - new Date(dob).getTime()) / 3.15576e+10)

export const HeightCalculate = n => {
    var realFeet = ((n * 0.393700) / 12);
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches + '"';
}

export const DateSpliter = data => {
    const date = new Date(data).toISOString()
    return date.split("T")[0]
}

export const DayMonthYear = create => {
    const date = new Date(create)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    return `${day}/${month}/${year}`
}

export const dateTimeAmPm = create => {
    const date = new Date(create)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${day}/${month}/${year}-${hours}:${minutes}${ampm}`
}