import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft, plus } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

toast.configure({ autoClose: 2000 })
const Create = () => {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Handle image
    const handleImage = event => {
        const file = event.target.files[0]
        if (file) {
            const size = parseInt(file.size) / 1000
            if (size > 700) {
                setError({ ...error, image: 'Select less than 700KB file.' })
                return
            }
            setSelectedFile({ image: file, preview: URL.createObjectURL(file) })
            setError({ ...errors, image: null })
        }
    }

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            let formData = new FormData()
            formData.append('how_it_works', data.how_it_works)
            formData.append('vip_consultants', data.vip_consultants)
            formData.append('vip_matches', data.vip_matches)
            formData.append('vip_privacy', data.vip_privacy)
            formData.append('vip_review_title', data.vip_review_title)
            formData.append('vip_review_sub_title', data.vip_review_sub_title)
            formData.append('vip_review', data.vip_review)
            formData.append('vip_review_image', selectedFile.image)

            await Requests.PagePremium.Store(formData, header)
            setLoading(false)
            history.push('/dashboard/website/premium')
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div className="premium-story pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Premium story</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/premium"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* how_it_works 1 */}
                                    <div className="form-group mb-4">
                                        {errors.how_it_works && errors.how_it_works.message ? (
                                            <p className="text-danger">{errors.how_it_works && errors.how_it_works.message}</p>
                                        ) : <p>HOW IT WORKS</p>}

                                        <textarea
                                            type="text"
                                            name="how_it_works"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short introduction"
                                            ref={register({
                                                required: "how_it_works is required"
                                            })}
                                        />
                                    </div>

                                    {/* VIP Consultants  */}
                                    <div className="form-group mb-4">
                                        {errors.vip_consultants && errors.vip_consultants.message ? (
                                            <p className="text-danger">{errors.vip_consultants && errors.vip_consultants.message}</p>
                                        ) : <p>VIP Consultants</p>}

                                        <textarea
                                            type="text"
                                            name="vip_consultants"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short vip_consultants"
                                            ref={register({
                                                required: "vip_consultants is required"
                                            })}
                                        />
                                    </div>

                                    {/* VIP Matches  */}
                                    <div className="form-group mb-4">
                                        {errors.vip_matches && errors.vip_matches.message ? (
                                            <p className="text-danger">{errors.vip_matches && errors.vip_matches.message}</p>
                                        ) : <p>VIP Matches</p>}

                                        <textarea
                                            type="text"
                                            name="vip_matches"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short vip_matches"
                                            ref={register({
                                                required: "vip_matches is required"
                                            })}
                                        />
                                    </div>

                                    {/* VIP Privacy */}
                                    <div className="form-group mb-4">
                                        {errors.vip_privacy && errors.vip_privacy.message ? (
                                            <p className="text-danger">{errors.vip_privacy && errors.vip_privacy.message}</p>
                                        ) : <p>VIP Privacy </p>}

                                        <textarea
                                            type="text"
                                            name="vip_privacy"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short vip_privacy"
                                            ref={register({
                                                required: "vip_privacy is required"
                                            })}
                                        />
                                    </div>

                                    < br /> < br />
                                    {/* vip_review */}
                                    <div className="row">

                                        {/* vip_review_title */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.vip_review_title && errors.vip_review_title.message ? (
                                                    <p className="text-danger">{errors.vip_review_title && errors.vip_review_title.message}</p>
                                                ) : <p>VIP Review Title</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="vip_review_title"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter vip_review_title"
                                                    ref={register({ required: "vip_review_title is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* vip_review_sub_title */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.vip_review_sub_title && errors.vip_review_sub_title.message ? (
                                                    <p className="text-danger">{errors.vip_review_sub_title && errors.vip_review_sub_title.message}</p>
                                                ) : <p>VIP Review Sub Title</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="vip_review_sub_title"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter vip_review_sub_title"
                                                    ref={register({ required: "vip_review_sub_title is required" })}
                                                />
                                            </div>
                                        </div>


                                    </div>


                                    <div className="form-group mb-4">
                                        {errors.vip_review && errors.vip_review.message ? (
                                            <p className="text-danger">{errors.vip_review && errors.vip_review.message}</p>
                                        ) : <p>VIP Review</p>}

                                        <textarea
                                            type="text"
                                            name="vip_review"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Enter blog short vip_review"
                                            ref={register({
                                                required: "vip_review is required"
                                            })}
                                        />
                                    </div>

                                    {/* Image upload Container */}
                                    <div className="form-group mb-4">
                                        {error && error.image ? <p className="text-danger">{error.image}</p> : <p>Image</p>}
                                        <div className="d-flex">
                                            {selectedFile && selectedFile.preview ?
                                                <div className="img-container text-center mr-2">
                                                    <div className="image border">
                                                        <img src={selectedFile.preview} className="img-fluid" alt="..." />
                                                    </div>
                                                </div>
                                                : null}

                                            <div className="img-container text-center">
                                                <div className="image border">
                                                    <input type="file" className="upload" onChange={handleImage} />
                                                    <div className="flex-center flex-column">
                                                        <Icon icon={plus} size={22} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Creating...' : 'Create'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Create;
