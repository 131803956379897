import React from 'react'
import './style.scss'
import { Images } from '../../../utils/Images'

const Index = () => {
    return (
        <div className="post-list-container">
            <div className="row">

                {[...Array(9).keys()].map((i) => {
                    return (
                        <div className="col-12 col-md-6 col-xl-4 mb-3 mb-md-4" key={i}>
                            <div className="card border-0 shadow-sm mb-md-2">
                                <img src={'https://source.unsplash.com/XtUd5SiX464'} className="card-img" alt="..." />
                                <div className="card-body">

                                    {/* Profile section */}
                                    <div className="profile-section d-flex">
                                        <div className="profile-img-container rounded-circle">
                                            <img src={Images.Image5} className="img-fluid" alt="..." />
                                        </div>
                                        <div className="pl-2 pt-2">
                                            <p className="text-capitalize">abdullah al mamun</p>
                                            <small>Posted on 8 Mar, 2021</small>
                                        </div>
                                    </div>

                                    {/* Content */}
                                    <div className="content">
                                        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p>
                                    </div>

                                    {/* Footer */}
                                    <div className="footer-section">
                                        <div className="d-flex">
                                            <div className="p-2 flex-fill">
                                                <button type="button" className="btn btn-block shadow-none">Like 120</button>
                                            </div>
                                            <div className="p-2 flex-fill">
                                                <button type="button" className="btn btn-block shadow-none border-left">Comments 120</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    );
}

export default Index;