import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import { x } from 'react-icons-kit/feather'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({ autoClose: 2000 })
const OTPMessage = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)

    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)

            const messageData = {
                reciver: [props.data.id],
                phone: [props.data.phone],
                message: data.message
            }

            const response = await Axios.post(`${api}sms/send`, messageData, props.header)
            if (response.status === 201) {
                setLoading(false)
                toast.success('Successfully message send')
                props.onHide()
            }

        } catch (error) {
            if (error) {
                setLoading(false)
                props.onHide()
                toast.warn(error.response.data.message)
            }
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div>
                            <Modal.Title>Send message </Modal.Title>
                            <p>To {props.data.phone}</p>
                        </div>
                        <div className="ml-auto">
                            <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                                <Icon icon={x} size={22} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-3">
                            <textarea
                                rows="5"
                                name="message"
                                className={errors.message ? "form-control shadow-none error" : "form-control shadow-none"}
                                placeholder="Write message here..."
                                ref={register({ required: true })}
                            />
                        </div>

                        <div className="text-right">
                            <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                {isLoading ? 'Sending...' : 'Send'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default OTPMessage;