import React, { useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { Images } from '../../../utils/Images'
import NoDataComponent from '../../noData/Index'
import { list } from 'react-icons-kit/entypo'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { eye, edit2, smartphone, mail, moreVertical } from 'react-icons-kit/feather'
import GhostTable from '../../ghostLoader/table/Index'
import OTPModal from '../modal/OTP'
import { dateTimeAmPm } from '../../../utils/helpers'

const Index = ({ users, updatestatus, header, updateBlockStatus }) => {
    const [isOTP, setOTP] = useState({ value: null, status: false })

    // Change status
    const changeStatus = data => updatestatus(data)
    const changeBlockStatus = data => updateBlockStatus(data)

    // Handle OTP message
    const handleOTPMessage = (data) => setOTP({ value: data, status: true })


    if (!users) return (<GhostTable />)
    if (!users.length) return (<NoDataComponent />)


    return (
        <div className="category-list">
            <table className="table table-responsive table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center">SL</td>
                        <td className="text-center">Image</td>
                        <td>Profile ID</td>
                        <td>Name</td>
                        <td>Joined</td>
                        <td>CreatedBy</td>
                        <td>Profile Type</td>
                        <td>Material Status</td>
                        <td>Gender</td>
                        <td>Status</td>
                        <td>Block Status</td>
                        <td>Admin</td>
                        <td>Active</td>
                        <td className="text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map((user, i) =>
                        <tr className="border-bottom" key={i}>
                            <td className="text-center">{i + 1}</td>
                            <td className="text-center">
                                <div className="image-container rounded-circle">
                                    <img src={user.image ? user.image : Images.Avatar} className="img-fluid" alt="..." />
                                </div>
                            </td>
                            <td>{user.profileId}</td>
                            <td>{user.name}</td>
                            <td>{dateTimeAmPm(user.createdAt)}</td>
                            <td>{user.profileReferedBy}</td>
                            <td>{user.profileType}</td>
                            <td>{user.materialStatus}</td>
                            <td className="text-capitalize">{user.gender}</td>
                            <td className={user.isOnline === 'offline' ? 'text-muted text-capitalize' : 'text-success text-capitalize'}>
                                {user.isOnline}
                            </td>
                            <td className={user.isLocked ? 'text-muted text-capitalize' : 'text-success text-capitalize'}>
                                {user.isLocked ? 'Locked' : 'UnLocked'}
                            </td>
                            <td>
                                {user.assignToAdmin === 'None' ? user.assignToAdmin : user.assignToAdmin.name}
                            </td>
                            <td className={user.activeStatus === 'deactivated' ? 'text-danger text-capitalize' : 'text-success text-capitalize'}>
                                {user.activeStatus}
                            </td>
                            <td className="text-right">
                                <div>
                                    <div className="btn-group">
                                        {/* Show user */}
                                        <Link
                                            to={`/dashboard/user/${user.id}/show`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={eye} size={18} /></Link>

                                        {/* Edit user */}
                                        <Link
                                            to={`/dashboard/user/${user.id}/edit`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={edit2} size={18} /></Link>

                                        {/* Send OTP message */}
                                        <button
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                            onClick={() => handleOTPMessage(user)}
                                        ><Icon icon={smartphone} size={19} /></button>

                                        {/* Send Mail message */}
                                        <Link
                                            to={`/dashboard/users/emailusers/${user.email}/${user.gender}/?page=1&limit=50`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={mail} size={19} /></Link>

                                        {/* Logs */}
                                        <Link
                                            to={`/dashboard/user/${user.email}/logs`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={list} size={19} /></Link>

                                        {/* Other Actions */}
                                        <DropdownButton
                                            menuAlign="right"
                                            title={<Icon icon={moreVertical} size={19} />}
                                        >
                                            <Dropdown.Item
                                                eventKey="1"
                                                onClick={() => changeStatus(user)}
                                            >Make {user.activeStatus === 'deactivated' ? 'activated' : 'deactivated'}</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">Print profile</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" as={Link} to={`/dashboard/user/${user.id}/payment`}>Payment history</Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="4"
                                                onClick={() => changeBlockStatus(user)}
                                            >{user.isLocked ? 'Unlock' : 'Lock'} user</Dropdown.Item>
                                        </DropdownButton>
                                    </div>

                                    {/* Editor */}
                                    <div className="editor pl-2">
                                        {/* <small>Assigned to: <span className="text-capitalize">{user.assignToAdmin.name}</span></small> */}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* OTP modal */}
            {isOTP.status ?
                <OTPModal
                    header={header}
                    show={isOTP.status}
                    data={isOTP.value}
                    onHide={() => setOTP({ value: null, status: false })}
                />
                : null}


        </div>
    );
};

export default Index;