import React, { useState, useEffect } from 'react'
import './style.scss'
import { Switch, Route, useHistory } from 'react-router-dom'

import Layout from '../../../components/layout/Index'
import Layout2 from '../../../components/layout/Index2'

import DashboardIndex from '../dashboard/Index'
import EmailIndext from '../email/Index'

// Website
import BannerIndex from '../website/banner/Index'
import BannerCreate from '../website/banner/Create'
import StoryIndex from '../website/story/Index'
import StoryStore from '../website/story/Create'
import StoryShow from '../website/story/Show'
import StoryEdit from '../website/story/Edit'
import AboutUsIndex from '../website/AboutUS/Index'
import AboutUsStore from '../website/AboutUS/Create'
import AboutUsShow from '../website/AboutUS/Show'
import AboutUsEdit from '../website/AboutUS/Edit'
import ContactUsIndex from '../website/ContactUs/Index'
import ContactUsStore from '../website/ContactUs/Create'
import ContactUsShow from '../website/ContactUs/Show'
import ContactUsEdit from '../website/ContactUs/Edit'
import TermsIndex from '../website/TermsAndConditions/Index'
import TermsStore from '../website/TermsAndConditions/Create'
import TermsShow from '../website/TermsAndConditions/Show'
import TermsEdit from '../website/TermsAndConditions/Edit'
import MarriageCounsellingIndex from '../website/MarriageCounseling/Index'
import MarriageCounsellingStore from '../website/MarriageCounseling/Create'
import MarriageCounsellingShow from '../website/MarriageCounseling/Show'
import MarriageCounsellingEdit from '../website/MarriageCounseling/Edit'
import PremiumIndex from '../website/Premium/Index'
import PremiumStore from '../website/Premium/Create'
import PremiumShow from '../website/Premium/Show'
import PremiumEdit from '../website/Premium/Edit'
import BlogIndex from '../website/blog/Index'
import BlogStore from '../website/blog/Create'
import BlogShow from '../website/blog/Show'
import BlogEdit from '../website/blog/Edit'
import SettingFieldIndex from '../website/settingFields/Index'
import SettingFieldCreate from '../website/settingFields/Create'
import SettingFieldValueIndex from '../website/settingFieldValue/Index'
import SettingFieldValueEdit from '../website/settingFieldValue/Edit'

// Accounts
import PackageSaleIndex from '../accounts/packageSales/Index'
import PackageSaleStore from '../accounts/packageSales/Create'
import PackageSaleEdit from '../accounts/packageSales/Edit'
import PackageSaleShow from '../accounts/packageSales/Show'
import AccountIndex from '../accounts/account/Index'
import Expense from '../accounts/Expense/Expense'
import ExpenseCreate from '../accounts/Expense/Create'
import ExpenseShow from '../accounts/Expense/Show'
import ExpenseUpdate from '../accounts/Expense/Update'

// User Pages
import UsersIndex from '../users/Index'
import UsersIndex2 from '../users/Index2'

import UserShow from '../users/Show'
import UserLogs from '../users/Logs'
import UserEdit from "../users/Edit"
import PaymentHistory from '../users/Payment'
// Membership Package Pages
import PackageIndex from '../membership-packages/Index'
import PackageCreate from '../membership-packages/Create'
import PackageEdit from '../membership-packages/Edit'

// Marketing
//// E-mail
import MarketingEmailIndex from '../marketing/email/Create'
import MarketingEmailHistory from '../marketing/email/History'
// sms
import MarketingSMSCreate from '../marketing/sms/Create'
import MarketingSMSHistory from '../marketing/sms/History'
// Addsesnse
import AddsenseIndex from '../marketing/addsense/Index'
import AddsenseCreate from '../marketing/addsense/Create'
import AddsenseUpdate from '../marketing/addsense/Update'

//HRM
import HRMDashboard from '../hrm/Dashboard'
import HRMEmployees from '../hrm/Employees'
import HRMUsers from '../hrm/Users'
import HRMUsersEdit from '../hrm/EditHrm'

//Ghotok 
import PendingGhotoks from '../ghotok/PendingGhotok'
import RejectGhotoks from '../ghotok/RejectGhotok'
import SellsPackageGhotok from "../ghotok/PackageSells"
import ComissionsGhotok from "../ghotok/comission/Comission"
import WithdrawGhotok from "../ghotok/WithdrowRequests"
import GhotokAccount from "../ghotok/GhotokAccount"
import GhotokProfile from "../ghotok/ghotok_profile/Index"
import GhotokProfileEdit from "../ghotok/ghotok_profile/ghotok_profile_edit"
import AddComission from "../ghotok/comission/CreateComission"
import ComissionEdit from "../ghotok/comission/Edit"
import WithdrawTransection from "../ghotok/Transection"
import phoneVerified from "../ghotok/Otp"
import ApprovedGhotoks from "../ghotok/approvedGhotok/Index"
//Premium
import Premium from "../premium/index"

// Notice board
import NoticeBoardIndex from '../notice-board/Index'
import ManagerNoticeBoardIndex from '../notice-board/IndexManager'

// Four O Four Page
import FourOFour from '../fourOfour/Index'

import { checkUserRole } from '../../../utils/Authenticate'
import AgentsUser from "../ghotok/AgentsUserList"


const Index = () => {
    const [userRole, setUserRole] = useState(null)
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setUserRole(checkUserRole())
        setLoading(true)
    }, [])

    if (!userRole && loading){
        localStorage.clear()
        history.push('/')
    }
    
    return (
        <div className="master">
            {
                (userRole !== 'admin' && userRole !== 'super_admin')
                    ? <Layout2 />
                    : <Layout />
            }

            <div className="main">
                <Switch>
                    <Route exact path="/dashboard/" component={DashboardIndex} />
                    <Route exact path="/dashboard/users"
                        component={(userRole !== 'admin' && userRole !== 'super_admin')
                            ? UsersIndex2 : UsersIndex} />
                    <Route path="/dashboard/user/:id/show" component={UserShow} />
                    <Route path="/dashboard/user/:id/edit" component={UserEdit} />
                    <Route path="/dashboard/user/:email/logs" component={UserLogs} />
                    <Route exact path="/dashboard/user/:id/payment" component={PaymentHistory} />
                    <Route exact path="/dashboard/users/emailusers/:email/:gender" component={EmailIndext} />
                    {/* Website */}
                    <Route exact path="/dashboard/website/banner" component={BannerIndex} />
                    <Route exact path="/dashboard/website/banner/store" component={BannerCreate} />

                    <Route exact path="/dashboard/website/story" component={StoryIndex} />
                    <Route exact path="/dashboard/website/story/store" component={StoryStore} />
                    <Route exact path="/dashboard/website/story/:id/edit" component={StoryEdit} />
                    <Route exact path="/dashboard/website/story/:id/show" component={StoryShow} />

                    <Route exact path="/dashboard/website/about-us" component={AboutUsIndex} />
                    {/* <Route exact path="/dashboard/website/about-us/store" component={AboutUsStore} /> */}
                    <Route exact path="/dashboard/website/about-us/:id/edit" component={AboutUsEdit} />
                    <Route exact path="/dashboard/website/about-us/:id/show" component={AboutUsShow} />
                    
                    <Route exact path="/dashboard/website/contact-us" component={ContactUsIndex} />
                    {/* <Route exact path="/dashboard/website/contact-us/store" component={ContactUsStore} /> */}
                    <Route exact path="/dashboard/website/contact-us/:id/edit" component={ContactUsEdit} />
                    <Route exact path="/dashboard/website/contact-us/:id/show" component={ContactUsShow} />

                    <Route exact path="/dashboard/website/terms-and-conditions" component={TermsIndex} />
                    {/* <Route exact path="/dashboard/website/terms-and-conditions/store" component={TermsStore} /> */}
                    <Route exact path="/dashboard/website/terms-and-conditions/:id/edit" component={TermsEdit} />
                    <Route exact path="/dashboard/website/terms-and-conditions/:id/show" component={TermsShow} />

                    <Route exact path="/dashboard/website/premium" component={PremiumIndex} />
                    {/* <Route exact path="/dashboard/website/premium/store" component={PremiumStore} /> */}
                    <Route exact path="/dashboard/website/premium/:id/edit" component={PremiumEdit} />
                    <Route exact path="/dashboard/website/premium/:id/show" component={PremiumShow} />

                    <Route exact path="/dashboard/website/marriage-counselling" component={MarriageCounsellingIndex} />
                    {/* <Route exact path="/dashboard/website/marriage-counselling/store" component={MarriageCounsellingStore} /> */}
                    <Route exact path="/dashboard/website/marriage-counselling/:id/edit" component={MarriageCounsellingEdit} />
                    <Route exact path="/dashboard/website/marriage-counselling/:id/show" component={MarriageCounsellingShow} />

                    <Route exact path="/dashboard/website/blog" component={BlogIndex} />
                    <Route exact path="/dashboard/website/blog/store" component={BlogStore} />
                    <Route exact path="/dashboard/website/blog/:id/edit" component={BlogEdit} />
                    <Route exact path="/dashboard/website/blog/:id/show" component={BlogShow} />
                    <Route exact path="/dashboard/website/settings-field" component={SettingFieldIndex} />
                    <Route exact path="/dashboard/website/settings-field/store" component={SettingFieldCreate} />
                    <Route exact path="/dashboard/website/settings-field/value" component={SettingFieldValueIndex} />
                    <Route exact path="/dashboard/website/settings-field/value/:id/edit" component={SettingFieldValueEdit} />

                    {/* Accounts */}
                    <Route exact path="/dashboard/accounts/account" component={AccountIndex} />
                    <Route exact path="/dashboard/accounts/sales" component={PackageSaleIndex} />
                    <Route exact path="/dashboard/accounts/sales/store" component={PackageSaleStore} />
                    <Route exact path="/dashboard/accounts/sales/:id/edit" component={PackageSaleEdit} />
                    <Route exact path="/dashboard/accounts/sales/:id/show" component={PackageSaleShow} />
                    <Route exact path="/dashboard/accounts/expense" component={Expense} />
                    <Route exact path="/dashboard/accounts/expense/create" component={ExpenseCreate} />
                    <Route exact path="/dashboard/accounts/expense/:id/show" component={ExpenseShow} />
                    <Route exact path="/dashboard/accounts/expense/:id/update" component={ExpenseUpdate} />

                    {/* Membership package */}
                    <Route exact path="/dashboard/membership-packages" component={PackageIndex} />
                    <Route exact path="/dashboard/membership-packages/create" component={PackageCreate} />
                    <Route exact path="/dashboard/membership-packages/:id/edit" component={PackageEdit} />


                    {/* {HRM} */}
                    <Route exact path="/dashboard/hrm/dashboard" component={HRMDashboard} />
                    <Route exact path="/dashboard/hrm/user" component={HRMUsers} />
                    <Route exact path="/dashboard/hrm/user/:email/update" component={HRMUsersEdit} />
                    <Route exact path="/dashboard/hrm/employee" component={HRMEmployees} />

                    {/* Premium */}
                    <Route exact path="/dashboard/premium" component={Premium} />


                    {/* Ghotok */}
                    <Route exact path="/dashboard/agents/approved" component={ApprovedGhotoks} />
                    <Route exact path="/dashboard/agents/pending" component={PendingGhotoks} />
                    <Route exact path="/dashboard/agents/reject" component={RejectGhotoks} />
                    <Route exact path="/dashboard/agents/sells" component={SellsPackageGhotok} />
                    <Route exact path="/dashboard/agents/withdraw" component={WithdrawGhotok} />
                    <Route exact path="/dashboard/agents/transection" component={WithdrawTransection} />
                    <Route path="/dashboard/agents/create" component={GhotokAccount} />
                    <Route path="/dashboard/agents/:id/phone-verified" component={phoneVerified} />
                    <Route path="/dashboard/agents/:id/show" component={GhotokProfile} />
                    <Route path="/dashboard/agents/:id/update" component={GhotokProfileEdit} />
                    <Route exact path="/dashboard/agents/comission" component={ComissionsGhotok} />
                    <Route path="/dashboard/create/comission" component={AddComission} />
                    <Route path="/dashboard/comission/:id/edit" component={ComissionEdit} />
                    <Route path="/dashboard/agents/:id/users" component={AgentsUser} />

                    {/* ///// E-mail ///// */}
                    {/* Marketing */}
                    <Route exact path="/dashboard/marketing/email" component={MarketingEmailIndex} />
                    <Route exact path="/dashboard/marketing/email/history" component={MarketingEmailHistory} />
                    <Route exact path="/dashboard/marketing/sms" component={MarketingSMSCreate} />
                    <Route exact path="/dashboard/marketing/sms/history" component={MarketingSMSHistory} />
                    <Route exact path="/dashboard/marketing/web-ad" component={AddsenseIndex} />
                    {/* <Route exact path="/dashboard/marketing/web-ad/create" component={AddsenseCreate} /> // need comment */}
                    <Route exact path="/dashboard/marketing/web-ad/update/:id" component={AddsenseUpdate} />


                    <Route exact path="/dashboard/notice-board" component={NoticeBoardIndex} />
                    <Route exact path="/dashboard/manager-notice-board" component={ManagerNoticeBoardIndex} />

                    <Route path="*" component={FourOFour} />
                </Switch>
            </div>
        </div>
    );
}

export default Index;

