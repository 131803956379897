
import React, { useState } from 'react'
import './style.scss'
import { useQuery } from 'react-query'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from 'react-router-dom'
import { api } from '../../../utils/api'
import 'react-toastify/dist/ReactToastify.css'
import { plus, edit2, trash2 } from 'react-icons-kit/feather'
import GhostPackage from '../../../components/ghostLoader/membershipPackage/Index'

toast.configure({ autoClose: 2000 })

const HRMDashboard = () => {
    const history = useHistory();
    // const [veiw, setVeiw] = useState(false)
    const [show, setshow] = useState(false);
    const [pageload, setPageload] = useState(true);
    const [itemEmail, setitemEmail] = useState(null);
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const showAlertDialog = (itememail) => {
        setitemEmail(itememail);
        setshow(true);
    };

    const deleteAdmin = async () => {
        const res = await Axios.delete(`${api}auth/delete/${itemEmail}`, header)
        if (res.status === 201) {
            toast.success("Delete successful")
            setshow(false);
            setPageload(true)
        }
    }



    const getAdmins = async () => {
        const res = await Axios.get(`${api}auth/index`, header)
        setPageload(false)
        return res.data.adminList
    }
    // useEffect(() => {
    //     AdminList();
    //   }, [AdminList]);

    const { data, isLoading, isError } = useQuery("adminlist", getAdmins, {
        retry: false,
        cacheTime: 600000,
        enabled: Boolean(pageload)
    })

    if (isLoading) return <GhostPackage />
    if (isError) return 'Something went wrong'

    return (
        <div className="hrm-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="mb-2 col-12">
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <td>SL</td>
                                    <td>Email</td>
                                    <td>Name</td>
                                    <td>Role</td>
                                    <td>Commission</td>
                                    <td>Status</td>
                                    <td className="text-center">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((item, i) =>
                                    <tr className="border-bottom" key={i}>
                                        <td className="text-center">{i + 1}</td>
                                        <td>{item.email}</td>
                                        <td>{item.name}</td>
                                        <td>{item.role}</td>
                                        <td>{item.commission > 0 ? `${item.commission}%` : "N/A"}</td>
                                        <td>{item.status}</td>
                                        <td className="text-right">
                                            <div className="btn-group">
                                                {/* Show user */}
                                                {/* <Link
                                                    to={`/dashboard/user/${user.id}/show`}
                                                    type="button"
                                                    className="btn btn-sm shadow-none"
                                                ><Icon icon={eye} size={18} /></Link> */}

                                                {item.role === "super_admin" ? null : (
                                                    <>
                                                        <Link
                                                            type="button"
                                                            className="btn btn-sm shadow-none"
                                                        >
                                                        <Icon onClick={() => history.push(`/dashboard/hrm/user/${item.email}/update`)}
                                                                icon={edit2} size={18} />
                                                        </Link>
                                                        <Link
                                                            type="button"
                                                            className="btn btn-sm shadow-none"
                                                        >
                                                        <Icon icon={trash2} size={18}
                                                            onClick={() => showAlertDialog(item.email)}
                                                            />
                                                        </Link></>
                                                )}

                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/* Float button */}
            <Link
                to="/dashboard/hrm/user"
                type="button"
                className="shadow-none rounded-circle text-white float-btn"
            >
                <Icon icon={plus} size={22} />
            </Link>
            <SweetAlert className="btnplus"
                // warning
                showCancel
                show={show}
                confirmBtnText="Delete"
                confirmBtnBsStyle='#ff5733'
                // "danger""default"
                cancelBtnBsStyle='#ff5733'
                title="Do you want to delete this persone from admin panel?"
                onConfirm={(e) => deleteAdmin()}
                onCancel={() => setshow(false)}
            ></SweetAlert>
        </div>
    );
}

export default HRMDashboard;