import React, { useState, useEffect } from 'react'
import './style.scss'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'
import 'react-toastify/dist/ReactToastify.css'
import { api } from '../../../../../utils/api'
import { chevronsRight } from 'react-icons-kit/feather'
import MessageList from '../../../../../components/users/messageList/Index'
import GhostMessages from '../../../../../components/ghostLoader/user/messageListLoader/Index'

toast.configure({ autoClose: 2000 })
const Index = () => {
    const { id } = useParams()
    const [isTab, setTab] = useState('sms')
    const [isLoading, setLoading] = useState(true)
    const [messages, setMessages] = useState({ type: null, values: [] })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    useEffect(() => {
        // get messages
        const getMessages = async () => {
            try {
                setLoading(true)
                const response = await Axios.get(`${api}admin/message/${id}/show?query=${isTab}`, header)
                if (response.status === 200) {
                    setLoading(false)
                    setMessages({ type: isTab, values: response.data.messages })
                }
            } catch (error) {
                if (error) {
                    toast.error(error.response.data.message)
                }
            }
        }

        getMessages()
    }, [id, isTab, header])


    return (
        <div className="card border-0 shadow-sm" style={styles.card}>
            <div className="card-header bg-white p-3 p-lg-4">
                <div className="title-container">
                    <div className="d-flex">
                        <div>
                            <Icon icon={chevronsRight} size={25} />
                        </div>
                        <div><h5 className="mb-0">Admin Messages</h5></div>
                    </div>
                    <div className="custom-tab mt-2">
                        <button
                            type="button"
                            className={isTab === 'sms' ? "btn tab-btn shadow-none isActive" : "btn tab-btn shadow-none"}
                            onClick={() => setTab('sms')}
                        >SMS</button>
                        <button
                            type="button"
                            className={isTab === 'mail' ? "btn tab-btn shadow-none isActive" : "btn tab-btn shadow-none"}
                            onClick={() => setTab('mail')}
                        >E-mail</button>
                    </div>
                </div>
            </div>

            <div className="card-body p-3 p-lg-4">
                {isLoading ?
                    <GhostMessages /> :
                    <MessageList messages={messages.values} type={messages.type} />
                }
            </div>
        </div>
    );
}

export default Index;

const styles = {
    card: {
        borderRadius: 6,
        overflow: 'hidden'
    }
}