import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import GhostProfile from '../../../components/ghostLoader/user/profileLoader/Index'
import { AgeCalculate, HeightCalculate } from '../../../utils/helpers';
import NewPassword from '../../../components/users/Edit/NewPassword'
import ResetEmail from '../../../components/users/Edit/ResetEmail'
import ResetPhone from '../../../components/users/Edit/ResetPhone'
import One from '../../../components/users/Edit/accountSteps/One';
import Two from '../../../components/users/Edit/accountSteps/Two';
import About from '../../../components/users/Edit/About';
import Image from '../../../components/users/Edit/Images'
import { Images } from '../../../utils/Images';
import User from "../../../utils/Requests/User"
import BasicLifeStyle from '../../../components/users/Edit/BasicLifeStyle';
import ReligiousBackground from '../../../components/users/Edit/ReligiousBackground';
import FamilyDetails from '../../../components/users/Edit/FamilyDetails';
import EducationCareer from '../../../components/users/Edit/EducationCareer';
import LocationOfGroom from '../../../components/users/Edit/LocationOfGroom';
import BasicInformations from '../../../components/users/Edit/parterpreference/BasicInformations';
import LocationDetails from '../../../components/users/Edit/parterpreference/LocationDetails';
import PartnerEducationCareer from '../../../components/users/Edit/parterpreference/PartnerEducationCareer';
import Others from '../../../components/users/Edit/parterpreference/Others';

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const Edit = () => {
    const { id } = useParams()
    const [isloading, setLoading] = useState(true)
    const [refetch, setRefetch] = useState(false);
    const [user, setUser] = useState(null)
    const [images, setImages] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })

    // get user
    const getUser = useCallback(async () => {
        try {
            const response = await User.UserIndex(id, header)
            setUser(response.user)
            setImages(response.imageLists)
            setLoading(false)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }, [id, header])

    useEffect(() => {
        getUser()
    }, [id, header, getUser, refetch])

    if (isloading || !user) return (<GhostProfile />)

    return (
        <div className="myprofile-container">
            <div className="container">
                <div className="row ">
                    <div className="col-12">
                        <div className="flex-fill main-section px-lg-4 pt-4 pt-lg-0 pb-4">
                            <div className="row pt-3 pb-5">
                                <div className="col-12 col-md-4">
                                    <div className="profileImage">
                                        <img src={user.profilePicture.clearImage ? `${process.env.REACT_APP_IMAGE_URL}/${user.profilePicture.clearImage}` : Images.Avatar} className="rounded-circle img-fluid" alt="" />
                                        {/* <button type="button" className="btn shadow rounded-circle" onClick={() => setShow(true)}>
                                            <Icon icon={edit2} size={18} />
                                        </button> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-8 profileBasicInfo">
                                    <h5 className="">{user.name}</h5>
                                    <div className="pt-2 pb-5 ml-3" >
                                        <table >
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td >{user.profileId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Material Status</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.materialStatus ? user.basicAndLifestyleInformation.materialStatus : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Posted By</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user.profileCreatedFor ? user.profileCreatedFor : "Not Available"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Mother Tongue</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user.language.motherTongue ? user.language.motherTongue : "Not Available"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Age & Height</th>
                                                    <td className="pl-md-5">:</td>
                                                    <td>{user.dob ? AgeCalculate(user.dob) : "Age"} & {user.basicAndLifestyleInformation && user.basicAndLifestyleInformation.height ? HeightCalculate(user.basicAndLifestyleInformation.height) : "Height not available"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="progress shadow">
                                        <div className="progress-bar" role="progressbar" style={{ width: user.profileUpdateRange + "%" }} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">{user.profileUpdateRange}%</div>
                                    </div>
                                </div>
                            </div>
                            <NewPassword email={user.email} />
                            <ResetEmail id={user._id} email={user.email} handleRefetch={() => setRefetch(!refetch)}/>
                            <ResetPhone email={user.email} phone={user.phone} handleRefetch={() => setRefetch(!refetch)}/>
                            
                            {
                                user.registrationStep === 1 ?
                                    <>
                                        <One step={user.registrationStep} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                    </>
                                    :
                                    user.registrationStep === 2 ?
                                        <>
                                            <Two step={user.registrationStep} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                        </>
                                        :
                                        <>
                                            <About user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <Image images={images} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <BasicLifeStyle user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <ReligiousBackground user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <FamilyDetails user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <EducationCareer user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <LocationOfGroom user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />

                                            <div id="parterpreference" name="parterpreference" className="shadow p-3 mb-3 mt-5 bg-body rounded">Partner Preference</div>
                                            <BasicInformations user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <LocationDetails user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <PartnerEducationCareer user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />
                                            <Others user={user} email={user.email} handleRefetch={() => setRefetch(!refetch)} />

                                        </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;