import React, { useState } from 'react'
import Axios from 'axios'
import { api } from '../../../utils/api'
import LoadingComponent from '../../../components/loading/Index'
import './style.scss'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { DateSpliter } from '../../../utils/helpers'
import { useSelector } from 'react-redux'

const Index = () => {
    const history = useHistory();
    const user = useSelector(state => state.user.userData)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const ResultToUser = async () => {
        const res = await Axios.get(`${api}auth/user`, header)
        return res.data.Users
    }

    const { data, isLoading, isError } = useQuery("Users", ResultToUser, {
        retry: false,
        cacheTime: 600000
    })
    
    if (isLoading) return <LoadingComponent />
    
    if (isError) {
        localStorage.clear()
        history.push('/')
    }

    return (
        <div className="ghotok-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className='card border-0 shadow-sm'>
                            {/* <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-lg-flex">
                                    <div><h1 className="mb-2 d-inline">Dashboard</h1></div>
                                </div>
                            </div> */}

                            <div className="d-lg-flex">
                                <div className="flex-fill border-0 px-lg-4 mb-4 mb-xl-0 activity-card">
                                    {data && data.map((item, i) =>
                                        <div className="card-body px-0 ">
                                            {
                                                user.role === 'manager' && user.totalSell &&
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Commission generated this month</h6>
                                                        <h4>{user.commission > 0 ? user.commission : 0}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                user.role === 'manager' &&
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Package selling target</h6>
                                                        <h4>{user.packageSellTarget}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                user.role === 'manager' && user.remainingPackageCount ?
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Remaining package sell</h6>
                                                        <h4>{user.remainingPackageCount}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {
                                                user.role === 'manager' && user.soldCount &&
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Package sold this month</h6>
                                                        <h4>{user.soldCount}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                user.role === 'manager' &&
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Amount selling target</h6>
                                                        <h4>{user.amountSellTarget}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                user.role === 'manager' && user.remainingAmountCount &&
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Remaining amount sell</h6>
                                                        <h4>{user.remainingAmountCount}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                user.role === 'manager' && user.totalSell &&
                                                <div className="content-card">
                                                    <div className="body shadow" type="button">
                                                        <h6>Amount sold this month</h6>
                                                        <h4>{user.totalSell}</h4>
                                                        <span className="angle"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=male`)}>
                                                    <h6>Male Users</h6>
                                                    <h4>{item.TotalMaleUser}</h4>
                                                    <span className="angle"
                                                    />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=female`)}>
                                                    <h6>Female Users</h6>
                                                    <h4>{item.TotalFemaleUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50`)}>
                                                    <h6>Total Users</h6>
                                                    <h4>{item.TotalUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow">
                                                    <h6>Total Agents</h6>
                                                    <h4>{item.TotalGhotok}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=${`${DateSpliter(item.start)}~${DateSpliter(item.end)}`}&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=null`)}>
                                                    <h6>Today Users</h6>
                                                    <h4>{item.TodayUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=Paid&materialStatus=null&workStatus=null&qualification=null&gender=null`)}>
                                                    <h6>Paid Users</h6>
                                                    <h4>{item.TotalPaidUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=activated&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=null`)}>
                                                    <h6>Activated Users</h6>
                                                    <h4>{item.TotalActivatedUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=deactivated&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=null`)}>
                                                    <h6>Deactivated Users</h6>
                                                    <h4>{item.TotalInactivatedUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=${`${DateSpliter(item.firstDay)}~${DateSpliter(item.lastDay)}`}&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=null`)}>

                                                    <h6>Current Month All User</h6>
                                                    <h4>{item.monthUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                            <div className="content-card">
                                                <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=${`${DateSpliter(item.firstDay)}~${DateSpliter(item.lastDay)}`}&status=null&assignTo=null&orderBy=null&profileType=Paid&materialStatus=null&workStatus=null&qualification=null&gender=null`)}>
                                                    <h6>Current Month Paid Users</h6>
                                                    <h4>{item.monthPaidUser}</h4>
                                                    <span className="angle" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;