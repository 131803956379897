import React, { useCallback, useEffect, useState } from 'react'
import Axios from 'axios'
// import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft } from 'react-icons-kit/feather'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import Requests from '../../../utils/Requests/Index'
import SingleSelect from '../../../components/select/Single'
import { useHistory } from 'react-router-dom'
const Create = () => {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [amount, setAmount] = useState(null);
    const [comment, setComment] = useState(null);
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const [transaction, setTransaction] = useState({ value: null, options: [], error: null })
    const [isLoading, setLoading] = useState(false)
    const handleTransaction = event => setTransaction({ ...transaction, value: event, error: null })

    const createTransaction = async (data, header) => {
        const res = await Axios.put(`${api}ghotok/withdrawrequest`, data, header)
        return res
    }

    const { mutate } = useMutation(createTransaction,{
        onSuccess: (data)=>{
            history.push('/dashboard/agents/withdraw')
            toast.success(data)
        }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const transactionResult = await Requests.Ghotok.Index(header)
            if (transactionResult) setTransaction({
                ...transaction,
                options: transactionResult && transactionResult.map(item => ({
                    label: item.name + ' - ' + item.email + ' - ' + item.phone,
                    value: item._id,
                    currentBalance: item.wallet.currentBalance,
                    mobileNumberFor: item.mobileNumberFor,
                    accountNumber: item.mobileBankingNumber,
                }))
            })

        } catch (error) {
        
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    // Submit Form
    const onSubmit = async (data) => {
        try {
            
            if (!transaction.value) return setTransaction({ ...transaction, error: 'Transaction is required' })
            let id = transaction.value
            setLoading(true)
            const data = {
                // ...data,
                id: id.value,
                accountNumber:id.accountNumber,
                paymentMethod:id.mobileNumberFor,
                amount:amount,
                comment:comment
               
                
            } 
            mutate(data,header)
        } catch (error) {
           
        }
    }

    return (
        <div className="store-income pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Transection</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/agents/withdraw"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Agent */}
                                        <div className="col-12">
                                            <div className="form-group mb-4">
                                                {transaction.error ? <p className="text-danger">{transaction.error}</p> : <p>Agent</p>}

                                                <SingleSelect
                                                    name = "id"
                                                    placeholder="Agent"
                                                    options={transaction.options}
                                                    error={transaction.error}
                                                    value={handleTransaction}
                                                />
                                            </div>
                                        </div>

                                        {/*Current Amount */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.currentBalance && errors.currentBalance.message ? (
                                                    <p className="text-danger">{errors.currentBalance && errors.currentBalance.message}</p>
                                                ) : <p>Current Amount</p>}

                                                <input
                                                    type="number"
                                                    name="currentBalance"
                                                    placeholder="Enter current amount"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Amount is required" })}
                                                    defaultValue={transaction.value ? transaction.value.currentBalance : null}
                                                />
                                            </div>
                                        </div>

                                        {/*Transection Amount */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.amount && errors.amount.message ? (
                                                    <p className="text-danger">{errors.amount && errors.amount.message}</p>
                                                ) : <p>Transection Amount</p>}
                                                
                                                {transaction.value ?(transaction.value.currentBalance <500 ?(
                                                    <>
                                                    <p className="text-danger">Current Amount Less Than 500</p>
                                                     <input
                                                     type="text"
                                                     name="amount"
                                                     placeholder="Enter transection amount"
                                                     className="form-control shadow-none"
                                                    //  ref={register({ required: "Current Amount < 500 is required" , 
                                                     disabled
                                                    />
                                                 </>
                                                ):(
                                                    <>
                                                    <input
                                                    type="text"
                                                    name="amount"
                                                    placeholder="Enter transection amount"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Amount is required" })}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                />
                                                </>
                                                )):(
                                                    <>
                                                    <input
                                                    type="text"
                                                    name="amount"
                                                    placeholder="Enter transection amount"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Amount is required" })}
                                                />
                                                </>
                                                )}
                                                
                                            </div>
                                        </div>
                                      

                                        {/* Payment method */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.paymentMethod && errors.paymentMethod.message ? (
                                                    <p className="text-danger">{errors.paymentMethod && errors.paymentMethod.message}</p>
                                                ) : <p>Payment method</p>}

                                                <select
                                                    name="paymentMethod"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Currency is required" })}
                                                    // onChange={(e) => setPaymentMethod(e.target.value)}
                                                >
                                                {transaction.value ? (
                                                    transaction.value.mobileNumberFor === "Bkash" ? (
                                                        <>
                                                            <option value="Bkash" >Bkash</option>
                                                            <option value="Nagad">Nagad</option>
                                                            <option value="Rocket">Rocket</option>
                                                        </>):(
                                                                transaction.value.mobileNumberFor === "Nagad" ? (
                                                                    <>
                                                                    <option value="Nagad">Nagad</option>
                                                                    <option value="Bkash" > Bkash</option>
                                                                    <option value="Rocket">Rocket</option></>
                                                                ):(
                                                                    <>
                                                                        <option value="Rocket">Rocket</option>
                                                                        <option value="Bkash" > Bkash</option>
                                                                        <option value="Nagad">Nagad</option>
                                                                    </>
                                                                )
                                                    )
                                                ):(
                                                    <>
                                                        <option value="Bkash" >Bkash</option>
                                                        <option value="Nagad">Nagad</option>
                                                        <option value="Rocket">Rocket</option>
                                                    </>)}
                                                
                                                </select>
                                            </div>
                                        </div>                                     
                                        {/* Payment Account */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.accountNumber && errors.accountNumber.message ? (
                                                    <p className="text-danger">{errors.accountNumber && errors.accountNumber.message}</p>
                                                ) : <p>Payment Account</p>}

                                                <input
                                                    type="text"
                                                    name="accountNumber"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter details"
                                                    defaultValue={transaction.value ? transaction.value.accountNumber : null}
                                                    ref={register({ required: "Payment account is required" })}
                                                    // onChange={(e) => setAccountNumber(e.target.value)}

                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {/* Comment */}
                                    <div className="form-group mb-4">
                                        {errors.comment && errors.comment.message ? (
                                            <p className="text-danger">{errors.comment && errors.comment.message}</p>
                                        ) : <p>Comment</p>}

                                        <textarea
                                            name="comment"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Write comment"
                                            onChange={(e) => setComment(e.target.value)}

                                        />
                                    </div>

                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Creating...' : 'Create'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Create;