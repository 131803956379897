import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const NewPassword = ({ email }) => {
    const [passwordEdit, setPasswordEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register, errors } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const onSubmit = async (data) => {
        setPasswordEdit({ ...passwordEdit, loading: true })
        let obj ={
            newPassword: data.password
        }
        const response = await User.ResetPassword(email, obj, header)
        if (response.status) {
            toast.success(response.message)
        }
        setPasswordEdit({ show: false, loading: false })
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Reset Password </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setPasswordEdit({ ...passwordEdit, show: !passwordEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        passwordEdit.show ? 
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {errors.password ? <span className='text-danger mb-3'>{errors.password.message}</span> : null}
                            <div className="form-group mb-4">
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Password must be 8 character long"
                                    name="password"
                                    ref={register({
                                        required: '*minimun length 8 character',
                                        minLength: {
                                            value: 8,
                                            message: "Minimun length 8 character"
                                        }
                                    })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={passwordEdit.loading}
                                >
                                    {passwordEdit.loading ? 'Updation...' : 'Update'}
                                </button>
                            </div>
                        </form>
                        :
                        <p className="text-justify">********</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewPassword;