import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    const [width, setWidth] = useState(null)

    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div>
                                        <Skeleton height={40} width={150} />
                                    </div>
                                    <div className="ml-auto">
                                        <Skeleton height={40} width={40} circle={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <td><Skeleton height={25} width={100} /></td>
                                            {width > 768 ? <td><Skeleton height={25} width={100} /></td> : null}
                                            {width > 992 ? <td><Skeleton height={25} width={100} /></td> : null}
                                            <td className="text-right text-lg-center"><Skeleton height={25} width={100} /></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(6).keys()].map((i) => {
                                            return (
                                                <tr key={i}>
                                                    <td><Skeleton height={25} width={100} /></td>
                                                    {width > 768 ? <td><Skeleton height={25} width={100} /></td> : null}
                                                    {width > 992 ? <td><Skeleton height={25} width={100} /></td> : null}
                                                    <td className="text-right text-lg-center">
                                                        <Skeleton height={35} width={35} circle={true} />
                                                        <Skeleton height={35} width={35} circle={true} className="ml-1" />
                                                        <Skeleton height={35} width={35} circle={true} className="ml-1" />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;