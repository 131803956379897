import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import SingleSelect from '../../form/SingleSelect'

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const FamilyDetails = ({ user, email, handleRefetch }) => {
    const [BackgroundEdit, setBackgroundEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [FamilyType, setFamilyType] = useState({
        value: null,
        options: [
            { label: 'Joint', value: 'Joint' },
            { label: 'Nuclear', value: 'Nuclear' }
        ]
    })
    const [FamilyAffluence, setFamilyAffluence] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Affluence', value: 'Affluence' },
            { label: 'Upper Middle Class', value: 'Upper Middle Class' },
            { label: 'Middle Class', value: 'Middle Class' },
            { label: 'Lower Middle Class', value: 'Lower Middle Class' }
        ]
    })

    const [FamilyValue, setFamilyValue] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Traditional', value: 'Traditional' },
            { label: 'Moderate', value: 'Moderate' },
            { label: 'Liberal', value: 'Liberal' }
        ]
    })
    const [fatherOccupation, setFatherOccupation] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Employed', value: 'Employed' },
            { label: 'Business', value: 'Business' },
            { label: 'Retired', value: 'Retired' },
            { label: 'Not Employed', value: 'NotEmployed' },
            { label: 'Passed Away', value: 'Passed Away' },
        ]
    })
    const [motherOccupation, setMotherOccupation] = useState({
        value: null,
        options: [
            { label: 'Not Interested to share', value: null },
            { label: 'Employed', value: 'Employed' },
            { label: 'Business', value: 'Business' },
            { label: 'Retired', value: 'Retired' },
            { label: 'Not Employed', value: 'NotEmployed' },
            { label: 'Passed Away', value: 'Passed Away' },
        ]
    })


    // on submit handler
    const onSubmit = async (data) => {
        const obj = {
            familyAffluence: FamilyAffluence.value,
            familyType: FamilyType.value,
            familyValue: FamilyValue.value,
            familyLocation: data.familyLocation ? data.familyLocation : null,
            nativePlace: data.nativePlace ? data.nativePlace : null,
            fatherName: data.fatherName ? data.fatherName : null,
            fatherStatus: fatherOccupation.value,
            motherName: data.motherName ? data.motherName : null,
            motherStatus: motherOccupation.value,
            yourOrder: data.yourOrder ? data.yourOrder : null,
            numberOfBrothers: data.brother ? data.brother : null,
            numberOfBrothersMarried: data.marriedbrother ? data.marriedbrother : null,
            numberOfSisters: data.sister ? data.sister : null,
            numberOfSistersMarried: data.marriedsister ? data.marriedsister : null
        }
        setBackgroundEdit({ ...BackgroundEdit, loading: true })
        const response = await User.UpdateFamilyDetails(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setBackgroundEdit({ show: false, loading: false })
        handleRefetch()
    }



    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0"> Family Details </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setBackgroundEdit({ ...BackgroundEdit, show: !BackgroundEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        BackgroundEdit.show ? <form onSubmit={handleSubmit(onSubmit)}>


                            <div className="form-group mb-4">
                                <p>Family Location</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Family Location"
                                    defaultValue={user.familyInfo.familyLocation || ''}
                                    name='familyLocation'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Family Type</p>

                                <SingleSelect
                                    options={FamilyType.options}
                                    data={user.familyInfo.familyType}
                                    value={(data) => setFamilyType({ ...FamilyType, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Family Affluence	</p>

                                <SingleSelect
                                    options={FamilyAffluence.options}
                                    data={user.familyInfo.familyAffluence}
                                    value={(data) => setFamilyAffluence({ ...FamilyAffluence, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Family Value</p>

                                <SingleSelect
                                    options={FamilyValue.options}
                                    data={user.familyInfo.familyValue}
                                    value={(data) => setFamilyValue({ ...FamilyValue, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Growup in</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.nativePlace || ''}
                                    name='nativePlace'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Father's Name</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.father.name || ''}
                                    name='fatherName'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Father's Occupation</p>

                                <SingleSelect
                                    options={fatherOccupation.options}
                                    data={user.familyInfo.father.status}
                                    value={(data) => setFatherOccupation({ ...fatherOccupation, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Mother's Name</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.mother.name || ''}
                                    name='motherName'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Mother's Occupation</p>

                                <SingleSelect
                                    options={motherOccupation.options}
                                    data={user.familyInfo.mother.status}
                                    value={(data) => setMotherOccupation({ ...motherOccupation, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Your order as child</p>
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.yourOrder}
                                    name='yourOrder'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>No of Brother</p>
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.numberOfBrothers}
                                    name='brother'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Married Brother</p>
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.numberOfBrothersMarried}
                                    name='marriedbrother'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>No of Sister</p>
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.numberOfSisters}
                                    name='sister'
                                    ref={register()}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Married Sister</p>
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    defaultValue={user.familyInfo.numberOfSistersMarried}
                                    name='marriedsister'
                                    ref={register()}
                                />
                            </div>


                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={BackgroundEdit.loading}
                                >
                                    {BackgroundEdit.loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <thead>

                                            <tr>
                                                <th>Family Location</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.familyLocation ? user.familyInfo.familyLocation : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Family Type</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.familyType ? user.familyInfo.familyType : "N/A"}</td>
                                            </tr>

                                            <tr>
                                                <th>Father's Name</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.father && user.familyInfo.father.name ? user.familyInfo.father.name : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Father's Occupation</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.father && user.familyInfo.father.status ? user.familyInfo.father.status : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Mother's Name</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.mother && user.familyInfo.mother.name ? user.familyInfo.mother.name : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Mother's Occupation</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.mother && user.familyInfo.mother.status ? user.familyInfo.mother.status : "N/A"}</td>

                                            </tr>
                                            <tr>
                                                <th>Your order as child</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.yourOrder ? user.familyInfo.yourOrder : 'N/A'}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>No of Brother</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.numberOfBrothers ? user.familyInfo.numberOfBrothers : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Married Brother</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.numberOfBrothersMarried ? user.familyInfo.numberOfBrothersMarried : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>No of Sister</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.numberOfSisters ? user.familyInfo.numberOfSisters : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Married Sister</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.numberOfSistersMarried ? user.familyInfo.numberOfSistersMarried : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Family Affluence</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.familyAffluence ? user.familyInfo.familyAffluence : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Family Value</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.familyInfo && user.familyInfo.familyValue ? user.familyInfo.familyValue : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Grow up in</th>
                                                <td className="pl-5">:</td>
                                                <td >{user.familyInfo && user.familyInfo.nativePlace ? user.familyInfo.nativePlace : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FamilyDetails;