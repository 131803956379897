import React, {useState} from 'react'
import './style.scss'
import Axios from 'axios'
import { api } from '../../../utils/api'
import Icon from 'react-icons-kit'
// import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { eye, edit2, slash, user } from 'react-icons-kit/feather'
import LoadingComponent from '../../loading/Index'
const ApprovedGhotok = ({ items }) => {
    
    // const [items, setItems] = useState({ items })
    const [isLoading, setLoading] = useState(false)
    // const history = useHistory()
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    //Reject Ghotok
    const RejectGhotok = async (id) => {
        const res = await Axios.put(`${api}ghotok/${id}/reject`, header)
            if(res.status === 201){
                setLoading(true) 
                // items.refetch()
                // return items
                window.location.reload();
            }
    }
    // const { data, refetch } = useQuery(ApprovedGhotok);
    if (isLoading) return <LoadingComponent />
    return (
        <div className="content-image-table-container content-table-container pb-4">
            <table className="table table-hover table-responsive-xl table-borderless">
                <thead>
                    <tr className="border-bottom text-center">
                        <td className="text-center sl-td">SL</td>
                        <td>AgentId</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Phone</td>
                        <td>Address</td>
                        <td>Status</td>
                        <td className="text-td text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom text-center" key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td className="text-td">{item.ghotokMediaId}</td>
                                <td className="text-td">{item.name}</td>
                                <td className="text-td">{item.email}</td>
                                <td className="text-td">{item.phone}</td>
                                <td className="text-td">{item.address}</td>
                                <td className="text-td">{item.status}</td>
                                <td className="text-center ">
                                    <div className="btn-group ">
                                        {/* Show user */}
                                        <Link
                                            to={`/dashboard/agents/${item._id}/show`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={eye} size={18} /></Link>
                                        <Link
                                            to={`/dashboard/agents/${item._id}/update`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={edit2} size={18} /></Link>
                                        <Link
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon onClick={() => RejectGhotok(item._id)} icon={slash} size={18}/></Link>
                                        <Link
                                            to={`/dashboard/agents/${item._id}/users`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={user} size={18} /></Link>
                                    </div>
                                </td>
                            </tr>
                        ) : null}
                </tbody>
            </table>
        </div>
    )
};

export default ApprovedGhotok;

