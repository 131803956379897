import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { eye } from 'react-icons-kit/feather'

const Brand = ({ items }) => {
    return (
        <div className="content-image-table-container content-table-container pb-4">
            <table className="table table-hover table-responsive-xl table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center sl-td">SL</td>
                        <td>Id</td>
                        <td>User</td>
                        <td>Package</td>
                        <td>Amount</td>
                        <td>Payment Method</td>
                        <td>Sale By</td>
                        <td className="text-td text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom" key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td className="text-td">{item.transactionId ? item.transactionId : "N/A"}</td>
                                <td>{item.userId ? <UserInfo data={item.userId} /> : <p>N/A</p>}</td>
                                <td className="text-td" style={{ minWidth: 120 }}>
                                    {item.packageId ? <PackageInfo data={item.packageId} /> : <p>N/A</p>}
                                </td>
                                <td className="text-td">{(item.amount && item.currency==="BDT") ? `${item.amount}৳` 
                                                        : (item.amount && item.currency==="USD") ? `${item.amount}$`
                                                        : <p>N/A</p>}</td>
                                <td className="text-td">{item.issuer ? item.issuer : <p>N/A</p>}</td>
                                <td className="text-td">
                                    {item.admin ? <p className="text-capitalize mb-0">{item.admin.name}</p> : <p>N/A</p>}
                                    {item.admin ? <small className="text-lowercase mb-0"><i>{item.admin.email}</i></small> : null}
                                </td>
                                <td className="button-td text-center">
                                    <Link
                                        type="button"
                                        to={`/dashboard/accounts/sales/${item._id}/show`}
                                        className="btn btn-sm shadow-none table-show-btn"
                                    >
                                        <Icon icon={eye} size={20} />
                                    </Link>
                                    {/* <Link
                                        type="button"
                                        to={`/dashboard/accounts/sales/${item._id}/edit`}
                                        className="btn btn-sm shadow-none table-edit-btn"
                                    >
                                        <Icon icon={edit2} size={20} />
                                    </Link> */}
                                </td>
                            </tr>
                        ) : null}
                </tbody>
            </table>
        </div>
    )
};

export default Brand;

const PackageInfo = ({ data }) => {
    return (
        <div>
            <p className="mb-1">{data.title}</p>
            <p className="mb-0"><b>Type: </b>{data.type}</p>
        </div>
    )
}

const UserInfo = ({ data }) => {
    return (
        <div>
            <p className="mb-1">{data.name}</p>
            <small className="text-lowercase mb-0"><i>{data.email}</i></small> 
        </div>
    )
}