import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import 'react-toastify/dist/ReactToastify.css'
import { useQuery } from "react-query";
import GhostTable from '../../../components/ghostLoader/table/Index'
import NoticeList from '../../../components/noticeBoard/noticeList/Index'
import ShowNoticeModal from '../../../components/noticeBoard/modal/Show'

toast.configure({ autoClose: 2000 })

const IndexManager = () => {
    const [isLoading, setLoading] = useState(true)
    const [showNotice, setShowNotice] = useState({ show: false, data: null })
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const getpageData = async () => {
        try {
            const response = await Axios.get(`${api}/notice/index-manager`, header)
            if (response.status === 200) {
                setLoading(false)
                return response.data.notices
            }
        } catch (error) {
            if (error) toast.error(error.response.data)
        }
    }


    const notices = useQuery("managernotices", getpageData, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(isLoading),
    })

    if (notices.isLoading) return <GhostTable />

    return (
        <div className="notice-board-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-4">
                                <h5 className="mb-0 mt-1">Notice board</h5>
                            </div>
                            <div className="card-body p-0">
                                <NoticeList
                                    data={notices.data}
                                    show={(notice) => setShowNotice({ show: true, data: notice })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Show modal */}
            {showNotice.show &&
                <ShowNoticeModal
                    show={showNotice.show}
                    data={showNotice.data}
                    onHide={() => setShowNotice({ show: false })}
                />}

        </div>
    );
}

export default IndexManager;
