import React from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import HTMLParser from 'react-html-parser'
import { androidStar } from 'react-icons-kit/ionicons'

import { dateFormate } from '../../../../../utils/helpers'

const Index = ({ user }) => {
    return (
        <div className="user-about-container">

            {/* About user */}
            <div className="card border-0 shadow-sm about-user mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">About</h6>
                    <hr />

                    <div className="row">
                        <div className="col-12">
                            {HTMLParser(user.shortDescription)}
                        </div>
                    </div>
                </div>
            </div>

            {/* Personal information */}
            <div className="card border-0 shadow-sm personal-info mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">Personal information</h6>
                    <hr />

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p>Name: <span>{user.name}</span></p>
                            <p>E-mail: <span className="text-lowercase">{user.email}</span></p>
                            <p>Phone: <span>{user.phone}</span></p>
                            <p>Gender: <span>{user.gender}</span></p>
                            <p>Date of birth: <span>{dateFormate(user.dob)}</span></p>
                            <p>Lives in: <span>{user.livingCountry}</span></p>
                        </div>
                        <div className="col-12 col-sm-6">
                            <p>Religion: <span>{user.religion}</span></p>
                            <p>Social order: <span>{user.socialOrder}</span></p>
                            <p>Lookingfor: <span>{user.lookingFor}</span></p>
                            <p>State/Division: <span>{user.stateDevision}</span></p>
                            <p>City: <span>{user.city}</span></p>
                            <p>Country: <span>{user.birthCountry}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Education & Profession */}
            <div className="card border-0 shadow-sm education-profession-info mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">Education & Profession</h6>
                    <hr />

                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p>Qualification: <span>{user.educationAndProfession.qualification}</span></p>
                            <p>Working with: <span>{user.educationAndProfession.workingWith}</span></p>
                            <p>Profession: <span>{user.educationAndProfession.professionArea}</span></p>
                            <p>Annual income: <span>{user.annualIncome.endTo}</span></p>
                            <p>Profession: <span>{user.educationAndProfession.professionArea}</span></p>
                        </div>
                        <div className="col-12 col-sm-6">
                            <p>Mother tongue: <span>{user.language.motherTongue}</span></p>
                            <p>Spoken language: <span>{user.language.spokenLanguage && user.language.spokenLanguage.map(language => language + ', ')}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact information */}
            <div className="card border-0 shadow-sm contact-info mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">Contact Information</h6>
                    <hr />

                    {user.contactInformation ?
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <p>Name: <span>{user.contactInformation.name}</span></p>
                                <p>Relationship: <span>{user.contactInformation.relationship}</span></p>
                                <p>Phone: <span>{user.contactInformation.phoneNumber}</span></p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <p>Alt phone: <span>{user.contactInformation.altPhone}</span></p>
                                <p>Convention time: <span>{user.contactInformation.convenientTimeToCall}</span></p>
                                <p>NID: <span>{user.contactInformation.nidNumber}</span></p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <p>Passport: <span>{user.contactInformation.passportNumber}</span></p>
                                <p>Present address: <span>{user.contactInformation.presentAddress}</span></p>
                                <p>Permanent address: <span>{user.contactInformation.permanentAddress}</span></p>
                            </div>
                        </div>
                        : null}
                </div>
            </div>

            {/* Basic & Lifestyle */}
            <div className="card border-0 shadow-sm basic-lifestyle mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">Basic & Lifestyle</h6>
                    <hr />

                    {user.basicAndLifestyleInformation ?
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <p>Age: <span>{user.basicAndLifestyleInformation.age} Yrs</span></p>
                                <p>Height: <span>{user.basicAndLifestyleInformation.height} Feet</span></p>
                                <p>Body weight: <span>{user.basicAndLifestyleInformation.bodyWeight} Kg</span></p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <p>Material status: <span>{user.basicAndLifestyleInformation.materialStatus}</span></p>
                                <p>Diet: <span>{user.basicAndLifestyleInformation.diet.map(item => item + ', ')}</span></p>
                                <p>Blood group: <span>{user.basicAndLifestyleInformation.bloodGroup}</span></p>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <p>Health information: <span>{user.basicAndLifestyleInformation.healthInformation}</span></p>
                                <p>Disability: <span>{user.basicAndLifestyleInformation.disability}</span></p>
                            </div>
                        </div>
                        : null}
                </div>
            </div>

            {/* Personal Activities */}
            <div className="card border-0 shadow-sm personal-activities mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">Personal Activities</h6>
                    <hr />

                    <div className="row">
                        {/* Hobbies */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Hobbies</p>
                            <ol>
                                {user.personalActivities.hobbies && user.personalActivities.hobbies.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>

                        {/* Interests */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Interest</p>
                            <ol>
                                {user.personalActivities.interests && user.personalActivities.interests.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>

                        {/* Favourite Music */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Favourite Music</p>
                            <ol>
                                {user.personalActivities.favouriteMusic && user.personalActivities.favouriteMusic.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>

                        {/* Favourite Reads */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Favourite Reads</p>
                            <ol>
                                {user.personalActivities.favouriteReads && user.personalActivities.favouriteReads.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>

                        {/* Preferred Movies */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Preferred Movies</p>
                            <ol>
                                {user.personalActivities.preferredMovies && user.personalActivities.preferredMovies.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>

                        {/* sports */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Sports</p>
                            <ol>
                                {user.personalActivities.sports && user.personalActivities.sports.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>

                        {/* Favourite Cuisine */}
                        <div className="col-12 col-sm-6 col-lg-4 mb-2">
                            <p><Icon icon={androidStar} size={20} className="mr-2" />Favourite Cuisine</p>
                            <ol>
                                {user.personalActivities.favouriteCuisine && user.personalActivities.favouriteCuisine.map((item, i) =>
                                    <li key={i}><p>{item}</p></li>
                                )}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            {/* Partner Preference */}
            <div className="card border-0 shadow-sm partner-preference mb-sm-4">
                <div className="card-body p-4">
                    <h6 className="title">Partner Preference</h6>
                    <hr />

                    {user.partnerPreference ?
                        <div>
                            {/* Basic */}
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <p>Age: <span className="primary-text">({user.partnerPreference.ageRange.startFrom}-{user.partnerPreference.ageRange.endTo})</span></p>
                                    <p>Height: <span className="primary-text">({user.partnerPreference.heightRange.startFrom}-{user.partnerPreference.heightRange.endTo})</span></p>
                                    <p>Material status: <span className="primary-text">{user.partnerPreference.materialStatus.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Religion: <span className="primary-text">{user.partnerPreference.religion && user.partnerPreference.religion.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <p>Social order: <span className="primary-text">{user.partnerPreference.socialOrder && user.partnerPreference.socialOrder.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Mother tounge: <span className="primary-text">{user.partnerPreference.motherTounge}</span></p>
                                    <p>Spoken languages: <span className="primary-text">{user.partnerPreference.spokenLanguages && user.partnerPreference.spokenLanguages.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Country: <span className="primary-text">{user.partnerPreference.location.country && user.partnerPreference.location.country.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <p>State / Division: <span className="primary-text">{user.partnerPreference.location.stateDivision && user.partnerPreference.location.stateDivision.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>City: <span className="primary-text">{user.partnerPreference.location.city && user.partnerPreference.location.city.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                </div>
                            </div>

                            <br />

                            {/* Education & Profession */}
                            <div className="row">
                                <div className="col-12">
                                    <p><Icon icon={androidStar} size={20} className="mr-2" />Education & Profession</p>
                                    <hr />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <p>Qualification: <span className="primary-text">{user.partnerPreference.educationAndProfession.qualification && user.partnerPreference.educationAndProfession.qualification.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Working with: <span className="primary-text">{user.partnerPreference.educationAndProfession.workingWith && user.partnerPreference.educationAndProfession.workingWith.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Profession area: <span className="primary-text">{user.partnerPreference.educationAndProfession.professionArea && user.partnerPreference.educationAndProfession.professionArea.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Annual Income: <span className="primary-text">({user.partnerPreference.annualIncome.startFrom + ' - ' + user.partnerPreference.annualIncome.endTo}) USD</span></p>
                                </div>
                            </div>

                            <br />

                            {/* Health information */}
                            <div className="row">
                                <div className="col-12">
                                    <p><Icon icon={androidStar} size={20} className="mr-2" />Health information</p>
                                    <hr />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <p>Diet: <span className="primary-text">{user.partnerPreference.diet && user.partnerPreference.diet.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Blood group: <span className="primary-text">{user.partnerPreference.bloodGroup && user.partnerPreference.bloodGroup.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Health: <span className="primary-text">{user.partnerPreference.healthInformation && user.partnerPreference.healthInformation.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                    <p>Disability: <span className="primary-text">{user.partnerPreference.disability && user.partnerPreference.disability.map((item, i) => <span key={i}>{item + ', '}</span>)}</span></p>
                                </div>
                            </div>

                        </div>
                        : null}

                </div>
            </div>

        </div>
    );
}

export default Index;