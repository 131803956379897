import React, { useState } from 'react'
// import PackagePurchaseModal from '../../../components/users/modal/PackagePurchase'
import GhostPayment from '../../../components/ghostLoader/user/payment/Index'
import { useQuery } from 'react-query'
import Axios from 'axios';
import { api } from "../../../utils/api";
import { useParams } from 'react-router-dom';
import { DayMonthYear } from '../../../utils/helpers'

const Payment = () => {
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    let [pageLoad, setPageload] = useState(true)
    const { id } = useParams()

    const getTransactionHistory = async () => {
        try {
            const res = await Axios.get(`${api}payment/transactionhistory/${id}`, header)
            if (res.status === 200) {
                setPageload(false)
                return res.data.history
            }
        } catch (error) {
            setPageload(false)
        }
    }

    const { data, isLoading, isError } = useQuery("transactionHistory", getTransactionHistory, {
        retry: false,
        cacheTime: 2100000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageLoad)
    })

    if (isLoading || isError) return <GhostPayment />

    return (
        <div className="payment-history">
            <div className="container-fluid">
                <div className="row">

                    {/* Package information */}
                    {/* <div className="col-12 col-padding pb-3">
                        <div className="card border-0 shadow-sm package-card">
                            <div className="card-header bg-white p-lg-4">
                                <h6 className="mb-0 mt-2">Last purchase package</h6>
                            </div>
                            <div className="card-body p-lg-4">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="d-flex">
                                            <div>
                                                <p>Package</p>
                                                <p>Activate at</p>
                                                <p>Expired at</p>
                                            </div>
                                            <div className="pl-4">
                                                <p>: Test Package</p>
                                                <p>: 01 Mat, 2021</p>
                                                <p>: 01 jul 2021</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex">
                                            <div>
                                                <p>Pay</p>
                                                <p>Due</p>
                                                <p>Status</p>
                                            </div>
                                            <div className="pl-4">
                                                <p>: 0 (TK)</p>
                                                <p>: 0 (TK)</p>
                                                <p>: <span className="text-success">running</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Payment history */}
                    <div className="col-12 col-padding pb-3">
                        <div className="card border-0 shadow-sm history-card">
                            <div className="card-header bg-white p-lg-4">
                                <h6 className="mb-0 mt-2">Payment history</h6>
                            </div>
                            <div className="card-body p-0">
                                {
                                    data && data.length > 0 ?
                                        <table className="table table-responsive table-borderless">
                                            <thead>
                                                <tr className="border-bottom row d-table-row">
                                                    <td className="text-center col-1">SL</td>
                                                    <td className='col-3'>Transaction ID</td>
                                                    <td className='col-1'>Amount</td>
                                                    <td className='col-1'>Currency</td>
                                                    <td className='col-2'>Issuer</td>
                                                    <td className='col-2'>Start Date</td>
                                                    <td className='col-2'>End Date</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((one, i) =>
                                                    <tr className="border-bottom row d-table-row" key={i}>
                                                        <td className="text-center col-1">{i + 1}</td>
                                                        <td className='col-3'>{one.transactionId}</td>
                                                        <td className='col-1'>{one.amount}</td>
                                                        <td className='col-1'>{one.currency}</td>
                                                        <td className='col-2'>{one.issuer}</td>
                                                        <td className='col-2'>{one.transactionDate ? DayMonthYear(one.transactionDate) : 'N/A'}</td>
                                                        <td className='col-2'>{DayMonthYear(one.endDate)}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        : <p className='text-center'>no such payment history found</p>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Package purchase modal */}
            {/* <PackagePurchaseModal
                show={isPackage}
                user={user}
                onHide={() => setPackage(false)}
            /> */}
        </div>
    );
}

export default Payment;