import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../../utils/Requests/User"
import SingleSelect from '../../../form/SingleSelect'
import { optionContext } from '../../../../utils/Context/optionContext'

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const PartnerEducationCareer = ({ user, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [EducationEdit, setEducationEdit] = useState({ show: false, loading: false })
    const { handleSubmit } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [newQualification, setQualification] = useState({
        value: null,
        options: []
    })
    const [newWorkingWith, setWorkingWith] = useState({
        value: null,
        options: []
    })
    const [newProfessionArea, setProfessionArea] = useState({
        value: null,
        options: []
    })
    const [annualIncome, setAnnualIncome] = useState({
        value: null,
        options: []
    })
    useEffect(() => {
        setQualification({ ...newQualification, options: handleOptionToData("Qualification") })
        setWorkingWith({ ...newWorkingWith, options: handleOptionToData("Working with") })
        setProfessionArea({ ...newProfessionArea, options: handleOptionToData("Profession area") })
        setAnnualIncome({ ...annualIncome, options: handleOptionToData("Income") })
    }, [options])

    const onSubmit = async (data) => {
        let obj = {
            qualification: newQualification.value,
            workingWith: newWorkingWith.value,
            professionArea: newProfessionArea.value,
            annualIncome: annualIncome.value
        }
        setEducationEdit({ ...EducationEdit, loading: true })
        const response = await User.UpdatePartnerEducationCareer(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setEducationEdit({ show: false, loading: false })
        handleRefetch()
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Partner Education Career </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEducationEdit({ ...EducationEdit, show: !EducationEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        EducationEdit.show ? <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group mb-4">
                                <p>Qualification</p>

                                <SingleSelect
                                    options={newQualification.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.educationAndProfession
                                        && user.partnerPreference.educationAndProfession.qualification
                                        && user.partnerPreference.educationAndProfession.qualification.length
                                        ? user.partnerPreference.educationAndProfession.qualification
                                        : ""}
                                    value={(data) => setQualification({ ...newQualification, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Working with</p>

                                <SingleSelect
                                    options={newWorkingWith.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.educationAndProfession
                                        && user.partnerPreference.educationAndProfession.workingWith
                                        && user.partnerPreference.educationAndProfession.workingWith.length
                                        ? user.partnerPreference.educationAndProfession.workingWith
                                        : ""}
                                    value={(data) => setWorkingWith({ ...newWorkingWith, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Profession area</p>

                                <SingleSelect
                                    options={newProfessionArea.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.educationAndProfession
                                        && user.partnerPreference.educationAndProfession.professionArea
                                        && user.partnerPreference.educationAndProfession.professionArea.length
                                        ? user.partnerPreference.educationAndProfession.professionArea
                                        : ""}
                                    value={(data) => setProfessionArea({ ...newProfessionArea, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Annual income in $USD</p>

                                <SingleSelect
                                    options={annualIncome.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.annualIncome
                                        && user.partnerPreference.annualIncome.startFrom !== null
                                        ? `${user.partnerPreference.annualIncome.startFrom}-${user.partnerPreference.annualIncome.endTo}`
                                        : ""}
                                    value={(data) => setAnnualIncome({ ...annualIncome, value: data })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={EducationEdit.loading}
                                >
                                    {EducationEdit.loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Qualifications</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.educationAndProfession
                                                    && user.partnerPreference.educationAndProfession.qualification
                                                    && user.partnerPreference.educationAndProfession.qualification.length
                                                    ? user.partnerPreference.educationAndProfession.qualification
                                                    : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Working with</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.educationAndProfession
                                                    && user.partnerPreference.educationAndProfession.workingWith
                                                    && user.partnerPreference.educationAndProfession.workingWith.length
                                                    ? user.partnerPreference.educationAndProfession.workingWith
                                                    : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Profession Area</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.educationAndProfession
                                                    && user.partnerPreference.educationAndProfession.professionArea
                                                    && user.partnerPreference.educationAndProfession.professionArea.length
                                                    ? user.partnerPreference.educationAndProfession.professionArea
                                                    : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Annual Income</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.annualIncome
                                                    && user.partnerPreference.annualIncome.startFrom !== null
                                                    ? `${user.partnerPreference.annualIncome.startFrom}-${user.partnerPreference.annualIncome.endTo}`
                                                    : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PartnerEducationCareer;