import React, { useState } from "react";
import "./style.scss";
import Axios from "axios";
import { useQuery } from "react-query";
import queryString from "query-string";
import { toast } from "react-toastify";
import { api } from "../../../utils/api";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.css";
// import UsersList from "../../../components/users/usersList/Index";
import UsersList from "../../../components/premiumList/index";
// import AdvanceSearch from '../../../components/users/modal/AdvanceSearch'
import GhostTable from "../../../components/ghostLoader/table/Index";
import Pagination from "../../../components/pagination/Index";
import { DateSpliter } from '../../../utils/helpers'

toast.configure({ autoClose: 2000 });
const Index = () => {
  // const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [pageReload, setPageReload] = useState(true);
  const [singleRefetch, setSigleRefetch] = useState(false);
  const [totalPage, setTotalPage] = useState(null);
  const [searchValues, setSearching] = useState(false);
  // const [, setSearchValues] = useState(null);
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [queryParams, setQueryParams] = useState({
    page: query.page,
    limit: 50,
    dateRange: query.dateRange || null,
  });

  const [header] = useState({
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });


  const getUsers = async () => {
    try {
      if (singleRefetch && pageReload) {
        setSearching(true);
        const response = await Axios.get(
            `${api}premiumlist/searchbydate?time=${queryParams.dateRange}`,
          header
        );
        // console.log("Premium List ", response)
        setTotalPage(1);
        setSearching(false);
        setPageReload(false);
        setSigleRefetch(false);
        return response.data.PremiumList;
      } 
      else {
        const response = await Axios.get(
          `${api}premiumlist`,
          header
        );
        setTotalPage(response.data.totalPage);
        setPageReload(false);
        setSigleRefetch(false);
        return response.data.PremiumList;
      }
    } catch (error) {
      if (error) {
        setSearching(false);
        toast.warn(error.message);
      }
    }
  };

  const queryData = useQuery("PremiumList", getUsers, {
    retry: false,
    cacheTime: 600000,
    refetchOnWindowFocus: false,
    enabled: Boolean(pageReload),
  });

  // change page no
  const changePage = (page) => {
    setQueryParams({ ...queryParams, page: page });
    history.push(
      `/dashboard/premium?dateRange=${queryParams.dateRange}`
    );
    setPageReload(true);
  };



  
//   Handle data range
  const handleRange = (range) => {
      if(range){
        let from = DateSpliter(range[0])
        let to = DateSpliter(range[1])
        setQueryParams({ ...queryParams, dateRange:`${from}~${to}` });
        setPageReload(true);
        setSigleRefetch(true);
        history.push(
          `/dashboard/premium?dateRange=${`${from}~${to}`}`
        )
      }
  }

//   clear date range
  const handleRangeClean = (e) => {
    e.preventDefault();
    setQueryParams({ ...queryParams, dateRange: null});
        setPageReload(true);
        // setSigleRefetch(false);
        history.push(
          `/dashboard/premium?dateRange=null`
        )
  }

  // update user activated & deactivated status
  const updateStatus = async (user) => {
    const response = await Axios.get(`${api}user/activestatus/update/${user.id}/${user.activeStatus}`, header)
    if (response.status === 201) {
      if (searchValues) {
        setSigleRefetch(true);
        setPageReload(true);
      } else {
        setPageReload(true);
        setSigleRefetch(false);
      }
      toast.success(response.data.message);
    }
  };

 

 
  // If loading to get data
  if (queryData.isLoading) return <GhostTable />;
  if (queryData.isError)
    return <div className="text-center mt-5">Something went wrong</div>;

  return (
    <div className="dashboard-users-index">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-padding pb-0">
            {/* User filter section */}
            <div className="justify-content-end filter-section">             

              {/* Filter by date range */}
              <div className="filter-item">
                <div className="item-body shadow-sm rangepicker">
                  <span>Filter by Date</span>
                  <div className="form-group m-0">
                    <DateRangePicker
                      onChange={handleRange}
                      onClean={handleRangeClean}
                      block
                    />
                  </div>
                </div>
              </div> 
            </div>
          </div>

          <div className="col-12 col-padding">
            {/* User list component */}
            <div className="card border-0 shadow-sm">
              <UsersList
                users={queryData.data}
                updatestatus={updateStatus}
                header={header}
                refetch={getUsers}
              />
              <div className="card-footer bg-white border-0 pt-0 pb-4">
                <Pagination totalpage={totalPage} changePage={changePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

