import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import SelectSingle from '../select/Single'
import Requests from '../../utils/Requests/Index'

const SettingFieldValue = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [selected, setSelected] = useState({ value: null, error: false })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Handle single select
    const handleSelect = data => setSelected({ value: data.value, error: false })

    // Submit Form
    const onSubmit = async (data) => {
        try {
            if (!selected.value) return setSelected({ ...selected, error: true })

            setLoading(true)
            const formData = { values: data.value }
            await Requests.Settings.ValueStore(selected.value, formData, header)
            setLoading(false)
            props.refetch()

        } catch (error) {
            if (error) console.log(error.response)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-lg-flex">
                    <div className="flex-fill">
                        <SelectSingle
                            placeholder={'Field'}
                            error={selected.error}
                            options={props.options}
                            value={handleSelect}
                        />
                    </div>
                    <div className="flex-fill form-group my-2 my-lg-0 px-lg-3">
                        <input
                            type="text"
                            name="value"
                            placeholder="Field value"
                            className={errors.value ? `form-control shadow-none ${styles.redBorder}` : "form-control shadow-none"}
                            ref={register({ required: "Value is required" })}
                        />
                    </div>
                    <div className="text-right text-lg-center">
                        <button
                            type="submit"
                            className="btn shadow-none"
                            style={styles.btn}
                            disabled={isLoading}
                        >{isLoading ? 'Adding...' : 'Add'}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SettingFieldValue;

const styles = {
    btn: {
        padding: '8px 30px',
        borderRadius: '4px'
    },
    redBorder: {
        border: '1px solid red'
    }
}