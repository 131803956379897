import React from 'react'
import Select from 'react-select'

const CustomeSelect = (props) => {
    const handleSelect = event => props.value(event)

    const manageSelect = (data, options) =>{
        if(data){
            if(typeof data === 'object'){
                let result = options.filter(one => one.value === data[0])
                return result[0]
            }
            else{
                let result = options.filter(one => one.value === data)
                return result[0]
            }
        } 
    }

    return (
        <div>
            <Select
                styles={props.error ? errorStyle : customStyles}
                options={props.options}
                onChange={handleSelect}
                classNamePrefix="custom-select"
                placeholder={`Select ${props.placeholder}`}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                defaultValue={manageSelect(props.data, props.options) || ''}
            />
        </div>
    );
};

export default CustomeSelect;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: 42,
        fontSize: 14,
        color: '#000',
        boxShadow: 'none', '&:hover': { borderColor: '1px solid #ced4da' },
        border: state.isFocused ? '1px solid #dfdfdf' : '1px solid #ced4da',
        borderRadius: 4
    })
}

const errorStyle = {
    control: (provided) => ({
        ...provided,
        minHeight: 42,
        fontSize: 14,
        color: '#000',
        boxShadow: 'none', '&:hover': { borderColor: '1px solid #ced4da' },
        border: '1px solid red',
        borderRadius: 4
    })
}