import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { eye} from 'react-icons-kit/feather'
import { dateFormate } from '../../../utils/helpers'

const PackageSell = ({ items }) => {
    

    return (
        <div className="content-image-table-container content-table-container pb-4">
            <table className="table table-hover table-responsive-xl table-borderless">
                <thead>
                    <tr className="border-bottom text-center">
                        <td className=" sl-td">SL</td>
                        <td>Date</td>
                        <td>AgentId</td>
                        <td>Agents</td>
                        <td>Phone</td>
                        <td>Package Price<small> tk</small></td>
                        <td className="text-td text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom text-center " key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td className="text-td">{dateFormate(item.createdAt)}</td>
                                <td className="text-td">{item.ghotok.ghotokMediaId}</td>
                                <td className="text-td">{item.ghotok.name}</td>
                                <td className="text-td">{item.ghotok.phone}</td>
                                <td className="text-td">{item.amount}<small> tk</small></td>
                                <td className="text-td">
                                    <div className="btn-group">
                                        <Link
                                            to={`/dashboard/accounts/sales/${item._id}/show`}
                                            type="button"
                                            className="btn btn-sm shadow-none"
                                        ><Icon icon={eye} size={18} /></Link>
                                    </div>
                                </td>


                            </tr>
                        ) : null}
                </tbody>
            </table>

        </div>
    )
};

export default PackageSell;
