import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../../utils/Requests/User"
import { Height } from '../../../OptionsData/HeightData/Heightdata'
import { Diet } from '../../../OptionsData/Diet/Diet'
import { MaterialStatus } from '../../../OptionsData/MaterialStatus/MaterialStatus'
import { optionContext } from '../../../../utils/Context/optionContext'
import SingleSelect from '../../../form/SingleSelect'

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })


const One = ({ step, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [Edit, setEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register, formState: { errors } } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [familyType, setFamilyType] = useState({
        value: null,
        error: null,
        options: [
            { label: 'Joint', value: 'Joint' },
            { label: 'Nuclear', value: 'Nuclear' },
        ]
    })
    const [diet, setDiet] = useState({
        value: null,
        error: null,
        options: Diet
    })
    const [materialStatus, setMaterialStatus] = useState({
        value: null,
        error: null,
        options: MaterialStatus
    })
    const [height, setHeight] = useState({
        value: null,
        error: null,
        options: Height
    })
    const [religion, setReligion] = useState({
        value: null,
        error: null,
        options: []
    })
    const [title, setTitle] = useState({
        value: null,
        error: null,
        options: []
    })
    const [country, setCountry] = useState({
        value: null,
        error: null,
        options: []
    })
    
    useEffect(() => {
        setTitle({ ...title, options: handleOptionToData("Social title") })
        setCountry({ ...country, options: handleOptionToData("Country") })
        setReligion({ ...religion, options: handleOptionToData("Religion") })
    }, [options])

    const onSubmit = async (data) => {
        if (!familyType.value) return setFamilyType({ ...familyType, error: 'This field is required.' })
        if (!materialStatus.value) return setMaterialStatus({ ...materialStatus, error: 'This field is required.' })
        if (!diet.value) return setDiet({ ...diet, error: 'Diet is required.' })
        if (!title.value) return setTitle({ ...title, error: 'Title is required.' })
        if (!religion.value) return setReligion({ ...religion, error: 'Religion is required.' })
        if (!country.value) return setCountry({ ...country, error: 'Country is required.' })
        if (!height.value) return setHeight({ ...height, error: 'Height is required.' })

        setEdit({ ...Edit, loading: true })
        const stepData = {
            ...data,
            familyType: familyType.value,
            materialStatus: materialStatus.value,
            diet: diet.value,
            title: title.value,
            religion: religion.value,
            country: country.value,
            height: height.value,
        }
        const response = await User.UpdateStepByStep(step, email, stepData, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setEdit({ show: false, loading: false })
        handleRefetch()
    }


    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0"> Registration Step 1 </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEdit({ ...Edit, show: !Edit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        Edit.show ? <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group mb-4">
                                {country.error && <span className="text-danger">{country.error}</span>}
                                <p> Your Country </p>
                                <SingleSelect
                                    options={country.options}
                                    data={''}
                                    value={(data) => setCountry({ ...country, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {errors.city && <span className="text-danger">This city is required</span>}
                                <p>City live in</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Enter your city name"
                                    name="city"
                                    ref={register()}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {religion.error && <span className="text-danger">{religion.error}</span>}
                                <p>Select Religion</p>
                                <SingleSelect
                                    options={religion.options}
                                    data={''}
                                    value={(data) => setReligion({ ...religion, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {familyType.error && <span className="text-danger">{familyType.error}</span>}
                                <p>Family type</p>
                                <SingleSelect
                                    options={familyType.options}
                                    data={''}
                                    value={(data) => setFamilyType({ ...familyType, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {materialStatus.error && <span className="text-danger">{materialStatus.error}</span>}
                                <p>Material Status</p>
                                <SingleSelect
                                    options={materialStatus.options}
                                    data={''}
                                    value={(data) => setMaterialStatus({ ...materialStatus, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {diet.error && <span className="text-danger">{diet.error}</span>}
                                <p>Diet</p>
                                <SingleSelect
                                    options={diet.options}
                                    data={''}
                                    value={(data) => setDiet({ ...diet, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {errors.age && <span className="text-danger">This field is required</span>}
                                <p>Date of birth</p>
                                <input
                                    type="date"
                                    className="form-control shadow-none"
                                    name='dob'
                                    ref={register({ required: true })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {height.error && <span className="text-danger">{height.error}</span>}
                                <p> Height</p>
                                <SingleSelect
                                    options={height.options}
                                    data={''}
                                    value={(data) => setHeight({ ...height, value: data })}
                                />
                            </div>

                            <div className="form-group mb-4">
                                {title.error && <span className="text-danger">{title.error}</span>}
                                <p> Title</p>
                                <SingleSelect
                                    options={title.options}
                                    data={''}
                                    value={(data) => setTitle({ ...title, value: data })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={Edit.loading}
                                >
                                    {Edit.loading ? 'Saving...' : 'Save '}
                                </button>
                            </div>
                        </form>
                            :
                            <p className="text-justify"> Registration Step 1 </p>
                    }
                </div>
            </div>
        </div>
    );
};

export default One;