import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft } from 'react-icons-kit/feather'

import Requests from '../../../../utils/Requests/Index'
import SingleSelect from '../../../../components/select/Single'
import { toast } from 'react-toastify'

const Create = () => {
    const { register, handleSubmit, errors } = useForm()
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const [user, setUser] = useState({ value: null, options: [], error: null })
    const [packageId, setPackageId] = useState({ value: null, options: [], error: null })
    const [isLoading, setLoading] = useState(false)

    const handleUser = event => setUser({ ...user, value: event, error: null })
    const handlePackage = event => setPackageId({ ...packageId, value: event, error: null })

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const usersResult = await Requests.User.ShortIndex(header)
            const packageResult = await Requests.Package.Index(header)
            if (usersResult) setUser({
                ...user,
                options: usersResult && usersResult.map(item => ({
                    label: item.name + ' - ' + item.email,
                    value: item._id
                }))
            })
            if (packageResult) setPackageId({
                ...packageId,
                options: packageResult && packageResult.map(item => ({
                    label: item.title + ' - ' + item.type,
                    value: item._id,
                    currency: item.discountPercentage ? item.finalCurrency : item.currency,
                    currencyType: item.currencyType
                }))
            })

        } catch (error) {
            if (error) console.log(error)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    // Submit Form
    const onSubmit = async (data) => {
        try {
            if (!user.value) return setUser({ ...user, error: 'User is required' })
            if (!packageId.value) return setPackageId({ ...packageId, error: 'Package is required' })

            setLoading(true)
            const formData = {
                ...data,
                userId: user.value.value,
                packageId: packageId.value.value,
            }
            if(formData.paymentMethod === "Cash"){
                await Requests.PackageSales.Store(formData, header)
                setLoading(false)
            }
            else{
                const res = await Requests.PackageSales.SurjoRequest(formData, header)
                setLoading(false)
                if(res.link){
                    window.location.replace(`${res.link}`)
                }
                else{
                    toast.error(res.message,{
                        autoClose: 4000
                    })
                }
            }
            
        } catch (error) {
            if (error) console.log(error)
        }
    }

    return (
        <div className="store-income pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Create sales</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/accounts/sales"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* User */}
                                        <div className="col-12">
                                            <div className="form-group mb-4">
                                                {user.error ? <p className="text-danger">{user.error}</p> : <p>Select user</p>}

                                                <SingleSelect
                                                    placeholder="user"
                                                    options={user.options}
                                                    error={user.error}
                                                    value={handleUser}
                                                />
                                            </div>
                                        </div>

                                        {/* package */}
                                        <div className="col-12">
                                            <div className="form-group mb-4">
                                                {packageId.error ? <p className="text-danger">{packageId.error}</p> : <p>Package</p>}

                                                <SingleSelect
                                                    placeholder="package"
                                                    options={packageId.options}
                                                    error={packageId.error}
                                                    value={handlePackage}
                                                />
                                            </div>
                                        </div>

                                        {/* Amount */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.amount && errors.amount.message ? (
                                                    <p className="text-danger">{errors.amount && errors.amount.message}</p>
                                                ) : <p>Amount</p>}

                                                <input
                                                    type="number"
                                                    name="amount"
                                                    placeholder="Enter amount"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Amount is required" })}
                                                    defaultValue={packageId.value ? packageId.value.currency : null}
                                                />
                                            </div>
                                        </div>

                                        {/* Currency */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.currency && errors.currency.message ? (
                                                    <p className="text-danger">{errors.currency && errors.currency.message}</p>
                                                ) : <p>Select currency</p>}

                                                <select
                                                    name="currency"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Currency is required" })}
                                                    defaultValue={packageId.value ? packageId.value.currencyType : null}
                                                >
                                                    <option value="BDT">BDT</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>


                                        {/* Payment method */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.paymentMethod && errors.paymentMethod.message ? (
                                                    <p className="text-danger">{errors.paymentMethod && errors.paymentMethod.message}</p>
                                                ) : <p>Payment method</p>}

                                                <select
                                                    name="paymentMethod"
                                                    className="form-control shadow-none"
                                                    ref={register({ required: "Payment method is required" })}
                                                >
                                                    <option value="Cash">Cash</option>
                                                    <option value="Bkash">Others</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Payment details */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.paymentDetails && errors.paymentDetails.message ? (
                                                    <p className="text-danger">{errors.paymentDetails && errors.paymentDetails.message}</p>
                                                ) : <p>Payment details</p>}

                                                <input
                                                    type="text"
                                                    name="paymentDetails"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter details"
                                                    ref={register({ required: "Payment details is required" })}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {/* Comment */}
                                    <div className="form-group mb-4">
                                        {errors.comment && errors.comment.message ? (
                                            <p className="text-danger">{errors.comment && errors.comment.message}</p>
                                        ) : <p>Comment</p>}

                                        <textarea
                                            name="comment"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Write comment"
                                            ref={register()}
                                        />
                                    </div>

                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Creating...' : 'Create'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Create;