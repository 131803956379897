import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        userData: {},
    },
    reducers: {
        userSetter: (state, action) => {
            state.userData = action.payload
        }
    },
});

export const { userSetter } = UserSlice.actions

export default UserSlice.reducer;