import React from 'react'
import Icon from 'react-icons-kit'
import { eye, edit2, trash2 } from 'react-icons-kit/feather'
import { dateFormate } from '../../../utils/helpers'
import { useSelector } from 'react-redux'
const Index = (props) => {

    // handle show
    const handleShow = data => props.show(data)

    // handle edit
    const handleEdit = data => props.edit(data)

    const role = useSelector((state) => state.user.userData.role)
    
    return (
        <div className="notice-table">
            <table className="table table-responsive-md table-borderless table-responsive">
                <thead>
                    <tr>
                        <td className="text-center">SL</td>
                        <td style={{ minWidth: 130 }}>Date</td>
                        <td style={{ minWidth: 130 }}>Title</td>
                        <td style={{ minWidth: 130 }}>Description</td>
                        <td style={{ minWidth: 60 }}>Notice For</td>
                        <td style={{ minWidth: 100, textAlign: 'center' }}>To(Manager)</td>
                        <td style={{ minWidth: 60, textAlign: 'center' }}>Priority</td>
                        <td style={{ minWidth: 130 }} className="text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {props.data && props.data.map((item, i) =>
                        <tr key={i}>
                            <td className="text-center">{i + 1}</td>
                            <td>{dateFormate(item.createdAt)}</td>
                            <td>{item.title}</td>
                            <td>{item.description.slice(0, 100) + '...'}</td>
                            <td>{item.noticeFor}</td>
                            <td className="text-center">{item.to ?
                                <>
                                    <p>{item.to.name}</p>
                                    <small>{item.to.email}</small>
                                </>
                                : 'ALL'}</td>
                            <td className="text-center">
                                {item.priority ? <span className="text-danger">High</span> : <span className="text-info">Low</span>}
                            </td>
                            <td className="text-center">
                                <div className="btn-group">
                                    <button type="button" className="btn rounded-circle shadow-none" onClick={() => handleShow(item)}>
                                        <Icon icon={eye} size={18} />
                                    </button>
                                    <button type="button" disabled={role === 'manager'} className="btn rounded-circle shadow-none" onClick={() => handleEdit(item)}>
                                        <Icon icon={edit2} size={18} />
                                    </button>
                                    <button type="button" disabled={role === 'manager'} className="btn rounded-circle shadow-none" onClick={() => props.delete(item._id)}>
                                        <Icon icon={trash2} size={18} />
                                    </button>
                                </div>
                                <br />
                                <small className="text-muted">Creatd by <span className="text-capitalize">{item.admin ? item.admin.name : null}</span></small>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Index;