import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { plus } from 'react-icons-kit/feather'
import DateRangePicker from "rsuite/DateRangePicker"
import Select from "react-select"
import Requests from '../../../../utils/Requests/Index'
import LoadingComponent from '../../../../components/loading/Index'
import SearchComponent from '../../../../components/search/Index'
import SalesTable from '../../../../components/table/PackageSales'
import { useQuery } from "react-query"
import { api } from "../../../../utils/api"
import { DateSpliter } from '../../../../utils/helpers'
import Axios from 'axios'
import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"
import jwt_decode from "jwt-decode"

const Index = () => {
    const history = useHistory()
    const location = useLocation()
    const user = jwt_decode(localStorage.getItem('token'));
    const query = queryString.parse(location.search)
    const [items, setItems] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [pageReload, setPageReload] = useState(true)
    const [searching, setSearching] = useState(false)
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [managerInfo, setManagerInfo] = useState(null)
    const [queryParams, setQueryParams] = useState({
        page: query.page || 1,
        limit: 10,
        manager: query.manager || null,
        dateRange: query.dateRange || null,
    });

    // Fetch admin list
    const fetchData = useCallback(async () => {
        let adminOptions = [{ id: 0, value: `null`, label: `None`, _id: `null` }];
        const response = await Axios.get(`${api}auth/indexofmanager`, header);
        if (response.status === 200) {
            response.data.adminList.map(async (one, idx) => {
                await adminOptions.push({
                    id: idx,
                    value: `${one.email}`,
                    label: `${one.email}`,
                    _id: `${one._id}`
                });
            });
            setItems(adminOptions)
            setLoading(false)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    // Handle search
    const handleSearch = async data => {
        try {
            setSearching(true)
            const response = await Requests.Income.Search({ query: data.query }, header)
            // if (response) setItems(response)
            if (response) setItems([])
            setSearching(false)
        } catch (error) {
            if (error) setSearching(false)
        }
    }
    const getList = async () => {
        const data = await Requests.PackageSales.Index(location.search, header)
        setPageReload(false)
        setManagerInfo(data.results)
        return data.invoices;
    }

    const sellList = useQuery("lists", getList, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(pageReload),
    })
    
    //   Handle data range
    const handleRange = (range) => {
        if (range) {
            let from = DateSpliter(range[0])
            let to = DateSpliter(range[1])
            setQueryParams({ ...queryParams, dateRange: `${from}~${to}` });
            setPageReload(true);
            history.push(
                `/dashboard/accounts/sales?page=${queryParams.page}&limit=${queryParams.limit}&dateRange=${`${from}~${to}`}&manager=${queryParams.manager}`
            )
        }
    }

    //   clear date range
    const handleRangeClean = (e) => {
        e.preventDefault();
        setQueryParams({ ...queryParams, dateRange: null });
        setPageReload(true);
        history.push(
            `/dashboard/accounts/sales?page=${queryParams.page}&limit=${queryParams.limit}&dateRange=null&manager=${queryParams.manager}`
        )
    }

    // Handle Assign to
    const handleAssignTo = (data) => {
        setQueryParams({ ...queryParams, manager: data._id });
        history.push(
            `/dashboard/accounts/sales?page=${queryParams.page}&limit=${queryParams.limit}&dateRange=${queryParams.dateRange}&manager=${data._id}`
        );
        setPageReload(true);
    }

    const defaultValueHandler = (data, options) => {
        if (data && data !== "null")
            return options.find((obj) => obj._id === data);
        else return null;
    };

    if (isLoading) return <LoadingComponent />
    return (
        <div className="income-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0 d-inline">Sales List</h6></div>
                                    <div className="ml-auto pr-2">
                                        <SearchComponent
                                            placeholder="sales"
                                            search={handleSearch}
                                            loading={searching}
                                            clear={fetchData}
                                        />
                                    </div>
                                    <div>
                                        <Link
                                            to="/dashboard/accounts/sales/store"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={plus} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="justify-content-end filter-section">
                                {/* Filter by date range */}
                                <div className="filter-item">
                                    <div className="item-body shadow-sm rangepicker">
                                        <span>Filter by Date</span>
                                        <div className="form-group m-0">
                                            <DateRangePicker
                                                onChange={handleRange}
                                                onClean={handleRangeClean}
                                                block
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    user.role !== 'manager' &&
                                    <div className="filter-item">
                                        <div className="item-body shadow-sm">
                                            <span>AdminList</span>
                                            <Select
                                                classNamePrefix="custom-select"
                                                styles={customStyles}
                                                placeholder={"Select"}
                                                components={{
                                                    DropdownIndicator: () => null,
                                                    IndicatorSeparator: () => null,
                                                }}
                                                options={items}
                                                defaultValue={defaultValueHandler(
                                                    query.manager,
                                                    items
                                                )}
                                                onChange={(event) => handleAssignTo(event)}
                                                menuPortalTarget={document.body}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                managerInfo && managerInfo.packageSellTarget ?
                                    <div className='row manager-infos px-5 py-2'>
                                        <div className='col-4'>
                                            <h6>Package</h6>
                                            <p className='info-item'>sell target: {managerInfo.packageSellTarget}</p>
                                            <p className='info-item'>sold this month: {managerInfo.soldCount}</p>
                                            <p className='info-item'>remaining: {managerInfo.remainingPackageCount}</p>
                                        </div>
                                        <div className='col-4'>
                                            <h6>Amount</h6>
                                            <p className='info-item'>sell target: {managerInfo.amountSellTarget}</p>
                                            <p className='info-item'>sold this month: {managerInfo.totalSell}</p>
                                            <p className='info-item'>remaining: {managerInfo.remainingAmountCount}</p>
                                        </div>
                                        <div className='col-4'>
                                            <h6>Commission Generated</h6>
                                            <p className='info-item'>Commission: {managerInfo.commission}</p>
                                        </div>
                                    </div>
                                    : null
                            }

                            {/* Data table component for show data */}
                            <div className="card-body p-0">
                                <SalesTable items={sellList.data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Index;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: "#000",
        minWidth: 170,
        boxShadow: "none",
        "&:hover": { borderColor: "1px solid #ced4da" },
        border: state.isFocused ? "1px solid #dfdfdf" : "1px solid #ced4da",
        borderRadius: 0,
        menuPortal: base => ({ ...base, zIndex: 999 })
    }),
};