import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft, plus } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'

const Create = () => {
    const [fileError, setFileError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const { register, handleSubmit, errors } = useForm()
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Handle image
    const handleImage = event => {
        const file = event.target.files[0]
        if (file) {
            const size = parseInt(file.size) / 1000
            if (size > 800) {
                setFileError({ ...errors, image: 'Select less than 800KB file.' })
                return
            }
            setSelectedFile({ image: file, preview: URL.createObjectURL(file) })
            setFileError({ ...errors, image: null })
        }
    }

    // Submit Form
    const onSubmit = async (data) => {
        try {
            if (!selectedFile) return setFileError({ ...fileError, image: 'Image is required' })

            let formData = new FormData()
            formData.append('name', data.name)
            formData.append('bannerImage', selectedFile.image)

            setLoading(true)
            await Requests.Banner.Store(formData, header)
            setLoading(false)

        } catch (error) {
            if (error) {
                setLoading(false)
                console.log(error)
            }
        }
    }

    return (
        <div className="store-banner pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Store Banner</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/banner"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* Name */}
                                    <div className="form-group mb-4">
                                        {errors.name && errors.name.message ? (
                                            <p className="text-danger">{errors.name && errors.name.message}</p>
                                        ) : <p>Name</p>}

                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control shadow-none"
                                            placeholder="Enter name"
                                            ref={register({
                                                required: "Name is required"
                                            })}
                                        />
                                    </div>

                                    {/* Image upload Container */}
                                    <div className="form-group mb-4">
                                        {fileError && fileError.image ? <p className="text-danger">{fileError.image}</p> : <p>Image</p>}
                                        <div className="d-flex">
                                            {selectedFile && selectedFile.preview ?
                                                <div className="img-container text-center mr-2">
                                                    <div className="image border">
                                                        <img src={selectedFile.preview} className="img-fluid" alt="..." />
                                                    </div>
                                                </div>
                                                : null}

                                            <div className="img-container text-center">
                                                <div className="image border">
                                                    <input type="file" className="upload" onChange={handleImage} />
                                                    <div className="flex-center flex-column">
                                                        <Icon icon={plus} size={22} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Create;
