import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const NewPassword = ({ id, email, handleRefetch }) => {
    const [emailEdit, setEmailEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register, errors } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const onSubmit = async (data) => {
        setEmailEdit({ ...emailEdit, loading: true })
        let obj ={
            email: data.email
        }
        const response = await User.ResetEmail(id, obj, header)
        if (response.status) {
            toast.success(response.message)
        }
        setEmailEdit({ show: false, loading: false })
        handleRefetch()
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Reset Email</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEmailEdit({ ...emailEdit, show: !emailEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        emailEdit.show ? 
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {errors.email ? <span className='text-danger mb-3'>{errors.email.message}</span> : null}
                            <div className="form-group mb-4">
                                <input
                                    type="email"
                                    className="form-control shadow-none"
                                    placeholder="Enter new Email"
                                    name="email"
                                    defaultValue={email}
                                    ref={register({ required: '*Email can not be empty' })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={emailEdit.loading}
                                >
                                    {emailEdit.loading ? 'Updation...' : 'Update'}
                                </button>
                            </div>
                        </form>
                        :
                        <p className="text-justify">{email}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewPassword;