import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { useHistory, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { chevronLeft } from 'react-icons-kit/feather'
import CustomeSelect from '../../../../components/select/CustomeSelect'
import { toast } from 'react-toastify'
import { api } from "../../../../utils/api";
import Axios from 'axios'
import jwt_decode from "jwt-decode"

const Update = () => {
    const history = useHistory()
    const params = useParams()
    const { register, handleSubmit, errors } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [admin, setAdmin] = useState({ value: null, options: [], error: null })
    const [type, setType] = useState({ value: null, error: null })
    const [method, setMethod] = useState({ value: null, error: null })
    const [isLoading, setLoading] = useState(false)
    const expenseID = params.id;
    const [expenseData, setExpenseData] =  useState(null)

    const handleAdmin = event => setAdmin({ ...admin, value: event.value, error: null })
    const handleMethod = event => setMethod({ ...method, value: event.value, error: null })
    const handleType = event => setType({ ...type, value: event.value, error: null })

    const typeOptions = [
        { label: 'Office Rent', value: 'Office Rent' },
        { label: 'Salary', value: 'Salary' },
        { label: 'Utility', value: 'Utility' },
        { label: 'Mobile Recharge', value: 'Mobile Recharge' },
        { label: 'Others', value: 'Others' }
    ]
    
    const paymentOptions = [
        { label: 'Cash', value: 'Cash' },
        { label: 'Check', value: 'Check' }
    ]

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const [response, expense] = await Promise.all([
                Axios.get(`${api}auth/index`, header),
                Axios.get(`${api}account/expense/getone/${expenseID}`, header)
            ])
            if (response.status === 200 && expense.status === 200) {
                const data = expense.data.expense
                setType({ ...type, value: data.type })
                setMethod({ ...method, value: data.method })
                setAdmin({
                    ...admin,
                    value: data.careOf,
                    options: response.data && response.data.adminList.map(item => ({
                        label: item.name + ' - ' + item.email,
                        value: item._id
                    }))
                })
                setExpenseData(data)
            }
        } catch (error) {
            if (error) console.log(error)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    // Submit Form
    const onSubmit = async (data) => {
        try {
            if (!type.value) return setType({ ...type, error: 'Payment type is required' })
            if (!method.value) return setMethod({ ...method, error: 'payment method is required' })
            setLoading(true)
            const formData = {
                ...data,
                type: type.value,
                method: method.value,
                careOf: admin.value
            }
            const user = jwt_decode(localStorage.getItem('token'));
            if (user.role === 'super_admin') {
                const res = await Axios.patch(`${api}account/expense/${expenseID}/update`, formData, header)
                if(res.data.status){
                    setLoading(false)
                    toast.success(res.data.message)
                    history.push('/dashboard/accounts/expense')
                }
                else if(!res.data.status){
                    toast.warn(res.data.message)
                    setLoading(false)
                }
            }
            else{
                toast.warn('You are not authorize for this action.')
                setLoading(false)

            }
           
        } catch (error) {
            if (error) console.log(error)
        }
    }

    if(!expenseData) return 'loading'

    return (
        <div className="expence-create pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Update Expense</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/accounts/expense"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Admin */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {admin.error ? <p className="text-danger">{admin.error}</p> : <p>C/O</p>}
                                                <CustomeSelect
                                                    placeholder="Admin"
                                                    options={admin.options}
                                                    error={admin.error}
                                                    value={handleAdmin}
                                                    data={admin.value}
                                                />
                                            </div>
                                        </div>
                                        
                                        {/* Amount */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.amount && errors.amount.message ? (
                                                    <p className="text-danger">{errors.amount && errors.amount.message}</p>
                                                ) : <p>Amount (TK)</p>}
                                                <input
                                                    type="number"
                                                    name="amount"
                                                    placeholder="Enter amount"
                                                    className="form-control shadow-none"
                                                    defaultValue={expenseData.amount}
                                                    ref={register({ required: "Amount is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Date */}
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group mb-4">
                                                {errors.date && errors.date.message ? (
                                                    <p className="text-danger">{errors.date && errors.date.message}</p>
                                                ) : <p>Date</p>}
                                                <input
                                                    type="date"
                                                    name="date"
                                                    placeholder="Select Date"
                                                    className="form-control shadow-none"
                                                    defaultValue={expenseData.date.split("T")[0]}
                                                    ref={register({ required: "Date is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Expense Type */}
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group mb-4">
                                                { type.error ? ( <p className="text-danger">{type.error}</p> ) : <p>Expense Type</p>}
                                                <CustomeSelect
                                                    placeholder="Type"
                                                    options={typeOptions}
                                                    error={type.error}
                                                    value={handleType}
                                                    data={expenseData.type}
                                                />
                                            </div>
                                        </div>

                                        {/* Payment method */}
                                        <div className="col-12 col-lg-4">
                                            <div className="form-group mb-4">
                                            { method.error ? ( <p className="text-danger">{method.error}</p> ) : <p>Payment method</p> }
                                                <CustomeSelect
                                                    placeholder="Method"
                                                    options={paymentOptions}
                                                    data={expenseData.method}
                                                    error={method.error}
                                                    value={handleMethod}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Comment */}
                                    <div className="form-group mb-4">
                                        {errors.comment && errors.comment.message ? (
                                            <p className="text-danger">{errors.comment && errors.comment.message}</p>
                                        ) : <p>Comment</p>}

                                        <textarea
                                            name="comment"
                                            rows={5}
                                            className="form-control shadow-none"
                                            placeholder="Write comment"
                                            maxLength={50}
                                            defaultValue={expenseData.comment}
                                            ref={register({ required: "Comment is required" })}
                                        />
                                    </div>

                                    <div className="text-right">
                                        <button type="submit" className="btn shadow-none" disabled={isLoading}>
                                            {isLoading ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Update;