export const Height = [
    { label: "4'5 - 134cm", value: '134' },
    { label: "4'6 - 137cm", value: '137' },
    { label: "4'7 - 139cm", value: '139' },
    { label: "4'8 - 142cm", value: '142' },
    { label: "4'9 - 144cm", value: '144' },
    { label: "4'10 - 147cm", value: '147' },
    { label: "4'11 - 149cm", value: '149' },
    { label: "5' - 152cm", value: '152' },
    { label: "5'1 - 154cm", value: '154' },
    { label: "5'2 - 157cm", value: '157' },
    { label: "5'3 - 160cm", value: '160' },
    { label: "5'4 - 162cm", value: '162' },
    { label: "5'5 - 165cm", value: '165' },
    { label: "5'6 - 167cm", value: '167' },
    { label: "5'7 - 170cm", value: '170' },
    { label: "5'8 - 172cm", value: '172' },
    { label: "5'9 - 175cm", value: '175' },
    { label: "5'10 - 177cm", value: '177' },
    { label: "5'11 - 180cm", value: '180' },
    { label: "6' - 182cm", value: '182' },
    { label: "6'1 - 185cm", value: '185' },
    { label: "6'2 - 187cm", value: '187' },
    { label: "6'3 - 190cm", value: '190' },
    { label: "6'4 - 193cm", value: '193' },
    { label: "6'5 - 195cm", value: '195' },
    { label: "6'6 - 198cm", value: '198' },
    { label: "6'7 - 200cm", value: '200' },
    { label: "6'8 - 203cm", value: '203' },
    { label: "6'9 - 205cm", value: '205' },
    { label: "6'10 - 208cm", value: '208' },
    { label: "6'11 - 210cm", value: '210' },
    { label: "7' - 213cm", value: '212' }
]