import React, { useEffect, useState } from 'react'
import './style.scss'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { api } from '../../utils/api'
import { NavLink, useHistory } from 'react-router-dom'
import { list, } from 'react-icons-kit/feather'
import {
    ic_dashboard,
    ic_people,
    ic_account_box,
    ic_language,
    ic_keyboard_arrow_right,
    ic_description,
    ic_domain,
    ic_payment,
    ic_loyalty,
    ic_bug_report,
    ic_library_add,
    ic_person_add,
    ic_no_encryption,
    ic_work,
    ic_pregnant_woman,
    ic_assignment
} from 'react-icons-kit/md'
import { userTie } from 'react-icons-kit/icomoon'
import { standby } from 'react-icons-kit/iconic'
import Navbar from '../navbar/Index'
import { checkUserRole } from '../../utils/Authenticate'
import Layout2 from './Index2'

const Layout = () => {
    const history = useHistory()
    const [show, setShow] = useState(false)
    const [isMenu, setMenu] = useState(false)
    const [loggingOut, setLoggingOut] = useState(false)
    const [notifications] = useState([])
    const [messages] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })
    const [userRole, setUserRole] = useState(null)

    useEffect(() => {
        setUserRole(checkUserRole())
    }, [])

    // Toggle menu
    const toggleMenu = event => {
        let current = event.target.getAttribute("data-value")

        if (isMenu === current) {
            setMenu(false)
        } else {
            setMenu(current)
        }
    }

    // Logout
    const doLogout = async () => {
        try {
            setLoggingOut(true)
            const response = await Axios.get(`${api}admin/auth/logout`, header)
            if (response.status === 200) {
                localStorage.clear()
                setTimeout(() => {
                    history.push('/')
                }, 2000)
            }
        } catch (error) {
            if (error) {
                localStorage.clear()
                setTimeout(() => {
                    history.push('/')
                }, 2000)
            }
        }
    }
    if (!userRole) return 'loading'

    if (userRole === 'manager') return <Layout2 />
    return (
        <div className="layout">
            {/* Navbar */}
            <div className="navbar-container shadow-sm">
                {notifications && messages ? <Navbar notifications={notifications} messages={messages} toggle={() => setShow(!show)} /> : null}
            </div>

            {/* Sidebar */}
            <div className={show ? "backdrop d-lg-none open-backdrop" : "backdrop d-lg-none"} onClick={() => setShow(false)}></div>

            <div className="sidebar-container">
                <div className={show ? "sidebar shadow open-sidebar" : "sidebar shadow"}>
                    <ul>

                        {/* Dashboard */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_dashboard} size={20} />Dashboard</NavLink>
                        </li>

                        {/* Website Links */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="website"
                                >
                                    <Icon icon={ic_language} size={20} />Website
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'website' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'website' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    {/* <NavLink
                                        exact
                                        to="/dashboard/website/theme"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Theme</NavLink> */}
                                    <NavLink
                                        exact
                                        to="/dashboard/website/banner"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Banner</NavLink>
                                      <NavLink
                                        exact
                                        to="/dashboard/website/blog"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Blog</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/website/story"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Success Story</NavLink>
                                     <NavLink
                                        exact
                                        to="/dashboard/website/about-us"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >About Us</NavLink>
                                     <NavLink
                                        exact
                                        to="/dashboard/website/contact-us"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Contact Us</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/website/terms-and-conditions"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Terms and Conditions</NavLink>
                                     <NavLink
                                        exact
                                        to="/dashboard/website/premium"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Premium</NavLink>
                                     <NavLink
                                        exact
                                        to="/dashboard/website/marriage-counselling"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Marriage Counselling</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/website/settings-field"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Setting Fields</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/website/settings-field/value"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Setting Fields Value</NavLink>
                                </div>
                            </div>
                        </li>

                        {/* Accounts Links */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="accounts"
                                >
                                    <Icon icon={ic_account_box} size={20} />Accounts
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'accounts' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'accounts' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to="/dashboard/accounts/account"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Account</NavLink>
                                    {/* <NavLink
                                        exact
                                        to="/dashboard/accounts/transaction"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Transaction</NavLink> */}
                                    <NavLink
                                        exact
                                        to="/dashboard/accounts/sales"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Package Sales</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/accounts/expense?page=1&limit=30"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Expense</NavLink>
                                    {/* <NavLink
                                        exact
                                        to="/dashboard/accounts/wallet"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Wallet management</NavLink> */}
                                </div>
                            </div>
                        </li>

                        {/* Pages */}
                        {/* <li>
                            <NavLink
                                exact
                                to="/dashboard/pages"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_description} size={20} />Pages</NavLink>
                        </li> */}

                        {/* Users */}
                        <li>
                            <NavLink
                                exact
                                to={`/dashboard/users?page=1&limit=50`}
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_people} size={20} />Users</NavLink>
                        </li>

                        {/* Branches */}
                        {/* <li>
                            <NavLink
                                exact
                                to="/dashboard/branches"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_domain} size={20} />Branches</NavLink>
                        </li> */}

                        {/* Payment & Billing */}
                        {/* <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="payment_billing"
                                >
                                    <Icon icon={ic_payment} size={20} />Payment & Billing
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'payment_billing' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'payment_billing' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to="/dashboard/bill"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Bill</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/invoice"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Invoice</NavLink>
                                </div>
                            </div>
                        </li> */}

                        {/* Proposal */}
                        {/* <li>
                            <NavLink
                                exact
                                to="/dashboard/proposal"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_loyalty} size={20} />Proposal</NavLink>
                        </li> */}

                        {/* Report & Complain */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/report-complain"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_bug_report} size={20} />Report / Complain</NavLink>
                        </li>

                        {/* Income & Member Report */}
                        {/* <li>
                            <NavLink
                                exact
                                to="/dashboard/income-member-report"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_library_add} size={20} />Income / Member Report</NavLink>
                        </li> */}

                        {/* Membership Packages */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/membership-packages"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_person_add} size={20} />Membership Packages</NavLink>
                        </li>

                        {/* Role */}
                        {/* <li>
                            <NavLink
                                exact
                                to="/dashboard/role-permission"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_no_encryption} size={20} />Role & Permission</NavLink>
                        </li> */}

                        {/* Marketing */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="marketing"
                                >
                                    <Icon icon={ic_work} size={20} />Marketing
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'marketing' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'marketing' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to={`/dashboard/marketing/sms?page=1&limit=50`}
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >SMS</NavLink>
                                    <NavLink
                                        exact
                                        to={`/dashboard/marketing/email?page=1&limit=50`}
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >E-mail</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/marketing/push-notification"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Push Notification</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/marketing/web-ad"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Web Ad</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/marketing/birthday-wish"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Birthday Wish</NavLink>
                                </div>
                            </div>
                        </li>

                        {/* HRM */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="hrm"
                                >
                                    <Icon icon={userTie} size={20} />HRM
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'hrm' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'hrm' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to="/dashboard/hrm/dashboard"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Dashboard</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/hrm/user"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >User</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/hrm/employee"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Employee</NavLink>
                                </div>
                            </div>
                        </li>

                        {/* Premium */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/premium"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={list} size={20} />Premium</NavLink>
                        </li>

                        {/* Agents */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="ghotok"
                                >
                                    <Icon icon={ic_pregnant_woman} size={20} />Agents
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'ghotok' ? "arrow down" : "arrow"} />
                                </button>
                                <div className={isMenu === 'ghotok' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to='/dashboard/agents/approved'
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Approved Agents</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/agents/pending"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Pending Agents</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/agents/reject"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Reject Agents</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/agents/sells"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Package Sells</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/agents/comission"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Comission</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/agents/withdraw"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Withdrow Requests</NavLink>
                                </div>
                            </div>
                            {/* <NavLink
                                exact
                                to="/dashboard/ghotok-media"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_pregnant_woman} size={20} />Ghotok Media</NavLink> */}
                        </li>

                        {/* Notice Board */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/notice-board"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_assignment} size={20} />Notice Board</NavLink>
                        </li>

                        {/* Logout */}
                        <li>
                            <button
                                type="button"
                                className="btn shadow-none"
                                onClick={doLogout}
                                disabled={loggingOut}
                            >
                                <Icon icon={standby} size={15} />
                                {loggingOut ? <span>Logging out...</span> : <span>Logout</span>}
                            </button>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Layout;