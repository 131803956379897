import React, { useState, useEffect, useCallback } from 'react'
import './style.scss'
import Axios from 'axios'
import { useMutation } from 'react-query'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import { useHistory, useParams } from 'react-router-dom'
// import Modal from 'react-bootstrap/Modal'

toast.configure({ autoClose: 2000 })
const EditHRMUsers = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const PropsEmail = props.match.params.email;

    const history = useHistory()
    const params = useParams()
    const [name, setName] = useState("");
    const [role, setRole] = useState("");
    const [target, setTarget] = useState(null);
    const [amountTarget, setAmountTarget] = useState(null);
    const [commission, setCommission] = useState(null)
    const [type, setType] = useState(null)
    
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const editRoles = async (data, header) => {
        const res = await Axios.put(`${api}auth/update/${PropsEmail}`, data, header)
        return res.data.message
    }

    const { mutate, isLoading, isError } = useMutation(editRoles, {
        onSuccess: (data) => {
            history.push('/dashboard/hrm/dashboard')
            toast.success(data)
        }
    })

    const getAdminData = useCallback(async (email) => {
        if (email) {
            await Axios.get(`${api}auth/index`, header)
                .then((response) => {
                    if (response.data) {
                        let arrayData = response.data.adminList;
                        let showData = arrayData.find((obj) => obj.email === email);
                        if (showData) {
                            setName(showData.name)
                            setRole(showData.role);
                            if(showData.role === 'manager'){
                                setType(showData.role)
                            }
                            if(showData.packageSellTarget){
                                setTarget(showData.packageSellTarget)
                                setAmountTarget(showData.amountSellTarget)
                                setCommission(showData.commission)
                            }
                        }
                    }
                })
        }
    }, [header])

    useEffect(() => {
        getAdminData(PropsEmail);
    }, [PropsEmail]);

    // Submit Form
    const onSubmit = async (data) => {
        try {
            mutate(data, header)
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    const handleSelect=(e)=>{
        setType(e.target.value);
      }

    if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Update Admin/Manager</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/hrm/dashboard"
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Name */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.name && errors.name.message ? (
                                                    <p className="text-danger">{errors.name && errors.name.message}</p>
                                                ) : <p>Name</p>}

                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    // defaultValue={props.data && props.data.name ? props.data.name : null}
                                                    defaultValue={name}
                                                    ref={register({ required: "Name is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Email */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.email && errors.email.message ? (
                                                    <p className="text-danger">{errors.email && errors.email.message}</p>
                                                ) : <p>Email</p>}

                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    // defaultValue={props.data && props.data.email ? props.data.email : null}
                                                    defaultValue={params.email}
                                                    ref={register({ required: "Email is required" })}
                                                    // onChange={(e) => setEmail(e.target.value)}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        {/* Password */}
                                        {/* <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.password && errors.password.message ? (
                                                        <p className="text-danger">{errors.password && errors.password.message}</p>
                                                    ) : <p>Password</p>}

                                                    <input
                                                        type="text"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        defaultValue={props.data && props.data.password ? props.data.password : null}
                                                        className={errors.password ? "form-control shadow-none error" : "form-control shadow-none"}
                                                        defaultValue={password}
                                                        ref={register({
                                                            required: "Password is required",
                                                            minLength: 8
                                                        })}
                                                    />
                                                </div>
                                            </div> */}

                                        {/* Role */}
                                        <div className="col-12 col-lg-6" >
                                            <div className="form-group mb-4">
                                                {errors.role && errors.role.message ? (
                                                    <p className="text-danger">{errors.role && errors.role.message}</p>
                                                ) : <p>Role</p>}

                                                <select
                                                    name="role"
                                                    defaultValue={role}
                                                    className={errors.role ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Role is required" })}
                                                    onChange={handleSelect}
                                                >
                                                    {/* <option name="role" value ="">{role}</option> */}
                                                    {role === "admin" ? (<>
                                                        <option value="admin" > Admin</option>
                                                        <option value="manager">Manager</option>
                                                    </>) : (<>
                                                        <option value="manager">Manager</option>
                                                        <option value="admin" > Admin</option>
                                                    </>)}

                                                </select>
                                            </div>
                                        </div>
                                        {
                                            type === 'manager' &&
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-4">
                                                        {errors.commission && errors.commission.message ? (
                                                            <p className="text-danger">{errors.commission && errors.commission.message}</p>
                                                        ) : <p>Commission %</p>}

                                                        <input
                                                            type="number"
                                                            name="commission"
                                                            placeholder="Enter commission"
                                                            defaultValue={commission}
                                                            className={errors.commission ? "form-control shadow-none error" : "form-control shadow-none"}
                                                            ref={register({
                                                                required: "commission is required"
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        {
                                            type === 'manager' &&
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-4">
                                                        {errors.packageSellTarget && errors.packageSellTarget.message ? (
                                                            <p className="text-danger">{errors.packageSellTarget && errors.packageSellTarget.message}</p>
                                                        ) : <p>Monthly package sell target</p>}

                                                        <input
                                                            type="number"
                                                            name="packageSellTarget"
                                                            placeholder="Enter package target"
                                                            defaultValue={target}
                                                            className={errors.packageSellTarget ? "form-control shadow-none error" : "form-control shadow-none"}
                                                            ref={register({
                                                                required: "package sell target is required"
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        {
                                            type === 'manager' &&
                                                <div className="col-12 col-lg-6">
                                                    <div className="form-group mb-4">
                                                        {errors.amountSellTarget && errors.amountSellTarget.message ? (
                                                            <p className="text-danger">{errors.amountSellTarget && errors.amountSellTarget.message}</p>
                                                        ) : <p>Monthly amount sell target</p>}

                                                        <input
                                                            type="number"
                                                            name="amountSellTarget"
                                                            defaultValue={amountTarget}
                                                            placeholder="Enter amount"
                                                            className={errors.amountSellTarget ? "form-control shadow-none error" : "form-control shadow-none"}
                                                            ref={register({
                                                                required: "amount sell target is required"
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        <div className="col-12 text-right">
                                            <br />
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Updating...' : 'Update'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default EditHRMUsers;