
import Logo from '../assets/static/logo.png'
import Logo2 from '../assets/static/logo2.png'
import FourOFour from '../assets/static/four_o_four.png'
import FakeUser from '../assets/static/fake-user.png'
import Empty from '../assets/static/empty.png'
import Avatar from '../assets/static/avatar.png'
import Image1 from '../assets/static/image-1.png'
import Image2 from '../assets/static/image-2.png'
import Image3 from '../assets/static/image-3.png'
import Image4 from '../assets/static/image-4.png'
import Image5 from '../assets/static/image-5.png'
import NoData from '../assets/static/no-data.png'
import PeopleSearch from '../assets/static/people_search.png'

export const Images = {
    Logo,
    Logo2,
    FourOFour,
    FakeUser,
    Empty,
    Avatar,
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    NoData,
    PeopleSearch
} 
