import React, { useCallback, useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import { optionContext } from '../../../utils/Context/optionContext'
import SingleSelect from '../../form/SingleSelect'

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const ReligiousBackground = ({ user, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [BackgroundEdit, setBackgroundEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    const [motherTongue, setMotherTongue] = useState(null)
    const [socialOrder, setSocialOrder] = useState(null)

    const valuesSetter = useCallback(() => {
        if (user.language.motherTongue) {
            setMotherTongue(user.language.motherTongue)
        }
        if (user.socialOrder) {
            setSocialOrder(user.socialOrder)
        }
    }, [user])

    useEffect(() => {
        valuesSetter()
    }, [valuesSetter])

    const [religion, setReligion] = useState({
        value: null,
        options: []
    })

    const [title, setTitle] = useState({
        value: null,
        options: []
    })

    const [Tongue, setTongue] = useState({
        value: null,
        options: []
    })

    useEffect(() => {
        setReligion({ ...religion, options: handleOptionToData("Religion") })
        setTitle({ ...title, options: handleOptionToData("Social title") })
        setTongue({ ...Tongue, options: handleOptionToData("Language") })
    }, [options])

    // on submit handler
    const onSubmit = async (data) => {
        let obj = {
            socialOrder: data.socialOrder ? data.socialOrder : null,
            religion: religion.value,
            title: title.value,
            motherTongue: Tongue.value
        }
        setBackgroundEdit({ ...BackgroundEdit, loading: true })
        const response = await User.UpdateReligiousBackground(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setBackgroundEdit({ show: false, loading: false })
        handleRefetch()
    }


    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0"> Religious Background </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setBackgroundEdit({ ...BackgroundEdit, show: !BackgroundEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        BackgroundEdit.show ? <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group mb-4">
                                <p>Mother Tongue</p>
                                <SingleSelect
                                    options={Tongue.options}
                                    data={motherTongue}
                                    value={(data) => setTongue({ ...religion, value: data })}
                                />
                            </div><div className="form-group mb-4">
                                <p>Family Title</p>
                                <SingleSelect
                                    options={title.options}
                                    data={title.value}
                                    value={(data) => setTitle({ ...title, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Religion</p>
                                <SingleSelect
                                    options={religion.options}
                                    data={religion.value}
                                    value={(data) => setReligion({ ...religion, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Community</p>
                                <input
                                    type="text"
                                    className="form-control shadow-none"
                                    defaultValue={socialOrder || ''}
                                    placeholder="sunni,shia ....etc"
                                    name='socialOrder'
                                    ref={register()}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={BackgroundEdit.loading}
                                >
                                    {BackgroundEdit.loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Mother Tongue</th>
                                                <td className="pl-5">:</td>
                                                <td>{motherTongue ? motherTongue : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Family Title</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.title ? user.title : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>

                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Religion</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.religion ? user.religion : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Community</th>
                                                <td className="pl-5">:</td>
                                                <td>{socialOrder ? socialOrder : "N/A"}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ReligiousBackground;