import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Requests from '../../../../utils/Requests/Index'

import SettingsFieldValueTable from '../../../../components/table/SettingsFieldValue'
import FieldValueForm from '../../../../components/form/SettingFieldValue'
import LoadingComponent from '../../../../components/loading/Index'

const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [options, setOptions] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.Settings.Index(header)
        if (data) {
            setItems(data)
            setLoading(false)
            setOptions(data && data.map(item => ({ label: item.title, value: item._id })))
        }
        setLoading(false)
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    if (isLoading) return <LoadingComponent />

    return (
        <div className="item-index pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="custom-card shadow-sm bg-white p-3 mb-3 mb-lg-4">
                            <h6 className="mb-3">Setting fields value</h6>
                            <FieldValueForm options={options} refetch={fetchData} />
                        </div>

                        <SettingsFieldValueTable items={items} refetch={fetchData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;