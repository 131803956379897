import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { api } from '../../../utils/api'
import 'react-toastify/dist/ReactToastify.css'
import { Images } from '../../../utils/Images'
import { x, loader, check } from 'react-icons-kit/feather'

toast.configure({ autoClose: 2000 })
const Message = (props) => {
    const [isLoading, setLoading] = useState(true)
    const [admins, setAdmins] = useState([])
    const [filteredAdmin, setFilteredAdmin] = useState(admins)
    const [isAssign, setAssign] = useState({ status: false, id: props.assigned.adminId || null })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })

    // Get Admin
    const getAdmin = useCallback(async () => {
        try {
            const response = await Axios.get(`${api}auth/index`, header)
            if (response.status === 200) {
                setAdmins(prevAdmin => ([...prevAdmin, ...response.data.adminList]))
                setFilteredAdmin(prevAdmin => ([...prevAdmin, ...response.data.adminList]))
                setLoading(false)
            }

        } catch (error) {
            if (error) {
                setLoading(false)
                toast.error(error.response.data.message)
            }
        }
    }, [header])

    useEffect(() => {
        getAdmin()
    }, [header, getAdmin])

    // handle Search
    const handleSearch = event => {
        const value = event.target.value
        const results = admins.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
        if (results && results.length > 0) setFilteredAdmin(results)
    }

    // Assign Admin
    const assignAdmin = async data => {
        try {
            const assignData = {
                id: props.assigned.userId,
                adminId: data
            }

            setAssign({ status: true, id: data })
            const response = await Axios.post(`${api}assigne/admin`, assignData, header)
            if (response.status === 201) {
                setAssign({ status: false, id: data })
                toast.success(response.data.message)
                props.refetch()
            }

        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal"
            >
                <Modal.Header>
                    <Modal.Title>Assign To Admin</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header>
                <Modal.Body className="p-3">

                    {/* Filter */}
                    <div className="form-group mb-3">
                        <input
                            placeholder="Search admin"
                            className="form-control shadow-none"
                            onChange={handleSearch}
                        />
                    </div>

                    {/* Suggested admin list */}
                    <div className="suggested-admin-list-container">
                        {isLoading ? <p className="text-center text-muted">Loading...</p> :

                            filteredAdmin && filteredAdmin.length ?
                                filteredAdmin.map((admin, i) =>
                                    <div className="suggested-admin d-flex" key={i}>
                                        {/* Image container */}
                                        <div className="img-container rounded-circle">
                                            <img src={admin.image || Images.Avatar} alt="..." />
                                        </div>

                                        {/* Content container */}
                                        <div className="content-container">
                                            <p className="text-capitalize">{admin.name}</p>
                                            <p className="text-lowercase">{admin.email}</p>
                                        </div>

                                        {/* Assign button */}
                                        <div className="button-container ml-auto">
                                            <button
                                                type="button"
                                                className="btn btn-sm shadow-none"
                                                disabled={isAssign.id === admin._id}
                                                onClick={() => assignAdmin(admin._id)}
                                            >
                                                {!isAssign.status && isAssign.id === admin._id ? <Icon icon={check} size={20} /> :
                                                    isAssign.status && isAssign.id === admin._id ? <Icon icon={loader} className="spin" size={20} /> : 'Assign'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                ) :
                                <div className="text-center py-3">
                                    <p className="mb-0">Admin not found !!</p>
                                </div>
                        }

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Message;