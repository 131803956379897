import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const ShortIndex = async (header) => {
    try {
        const response = await Axios.get(`${api}user/shortinfo`, header)
        if (response.status === 200) {
            return response.data.user
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const UserIndex = async (id, header) => {
    try {
        const response = await Axios.get(`${api}user/showbyid/${id}`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// user update step one
const UpdateStepByStep = async (step, email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/updatestepbystep/${email}/${step}`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// reset user password
const ResetPassword = async (email, newPassword, header) => {
    try {
        const response = await Axios.patch(`${api}user/changepassword/${email}`, newPassword, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// reset user password
const ResetPhone = async (email, newPhone, header) => {
    try {
        const response = await Axios.patch(`${api}user/changephone/${email}`, newPhone, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// reset user password
const ResetEmail = async (id, newEmail, header) => {
    try {
        const response = await Axios.patch(`${api}user/changeemail/${id}`, newEmail, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// user update
const UpdateShortDescription = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/shortdescription/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateBasicLifeStyle = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/basiclifestyle/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateReligiousBackground = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/religiousbackground/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateFamilyDetails = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/familydetails/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateEducationAndCareer = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/educationandcareer/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateLocationOfGroom = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/locationofgroom/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdatePersonalActivities = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/personalactivities/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// partner preference
const UpdatePartnerBasicInfo = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/partnerbasicinfo/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdatePartnerLocationDetails = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/partnerlocationdetails/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdatePartnerEducationCareer = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/partnereducationcareer/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdatePartnerOthers = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/partnerothers/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UpdateContactDetails = async (email, data, header) => {
    try {
        const response = await Axios.put(`${api}user/contactdetails/${email}/update`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const UploadUserImage = async (data, header) => {
    try {
        const response = await Axios.put(`${api}user/uploadimage`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const DeleteUserImage = async (data, header) => {
    try {
        const response = await Axios.put(`${api}user/deleteimage`, data, header);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const User = {
    ShortIndex,
    UpdateStepByStep,
    UserIndex,
    ResetPassword,
    ResetPhone,
    ResetEmail,
    UpdateShortDescription,
    UpdateBasicLifeStyle,
    UpdateReligiousBackground,
    UpdateFamilyDetails,
    UpdateEducationAndCareer,
    UpdateLocationOfGroom,
    UpdatePersonalActivities,
    UpdatePartnerBasicInfo,
    UpdatePartnerLocationDetails,
    UpdatePartnerEducationCareer,
    UpdatePartnerOthers,
    UpdateContactDetails,
    UploadUserImage,
    DeleteUserImage
}

export default User;