import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { plus, chevronLeft } from 'react-icons-kit/feather'

toast.configure({ autoClose: 2000 })
const Create = () => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [isActive, setActive] = useState(true)
    const [selectedFile, setSelectedFile] = useState({ value: null, error: false })
    const [isPreview, setPreview] = useState(null)
    const statusAll = [
        { label: 'Active', value: true },
        { label: 'Deactive', value: false }
    ]
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const [durationType, setDurationType] = useState('Day')

    // Select status
    const selectStatus = data => setActive(data)

    // handle image
    const handleImage = event => {
        const file = event.target.files[0]
        setPreview(URL.createObjectURL(file))
        setSelectedFile({ value: file, error: false })
    }

    const handleDurationType= event => {
        setDurationType(event.target.value)
    }
    // Submit Form
    const onSubmit = async (data, event) => {
        try {
            if (!selectedFile.value) return setSelectedFile({ error: true })

            let formData = new FormData()
            formData.append('title', data.title)
            formData.append('type', data.type)
            formData.append('currency', data.currency)
            formData.append('currencyType', data.currencyType)
            formData.append('durationType', data.durationType)
            formData.append('duration', data.duration)
            formData.append('totalLimit', data.totalProposalLimit)
            formData.append('contactViewLimit', data.contactViewLimit)
            // formData.append('messageLimit', data.messageLimit)
            formData.append('discountPercentage', data.discountPercentage)
            formData.append('status', isActive)
            formData.append('image', selectedFile.value)

            setLoading(true)
            const response = await Axios.post(`${api}/package/create`, formData, header)
            if (response.status === 201) {
                setLoading(false)
                toast.success(response.data.message)
                event.target.reset()
            }

        } catch (error) {
            if (error) {
                setLoading(false)
                toast.error(error.response.data.message)
            }
        }
    }

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Create package</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/membership-packages"
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Package title */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.title && errors.title.message ? (
                                                    <p className="text-danger">{errors.title && errors.title.message}</p>
                                                ) : <p>Package title</p>}

                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter title"
                                                    className={errors.title ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Title is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Package type */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.type && errors.type.message ? (
                                                    <p className="text-danger">{errors.type && errors.type.message}</p>
                                                ) : <p>Package type</p>}

                                                <input
                                                    type="text"
                                                    name="type"
                                                    placeholder="Enter type"
                                                    className={errors.type ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Type is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Currency */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.currency && errors.currency.message ? (
                                                    <p className="text-danger">{errors.currency && errors.currency.message}</p>
                                                ) : <p>Package Price( currency )</p>}

                                                <input
                                                    type="number"
                                                    name="currency"
                                                    placeholder="Enter currency"
                                                    className={errors.currency ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Currency is required",
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "Currency must be in the number."
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Currency type */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.currencyType && errors.currencyType.message ? (
                                                    <p className="text-danger">{errors.currencyType && errors.currencyType.message}</p>
                                                ) : <p>Currency type</p>}

                                                <select
                                                    name="currencyType"
                                                    className={errors.currencyType ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Currency is required" })}
                                                >
                                                    <option value="TK">TK</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Duration type */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.durationType && errors.durationType.message ? (
                                                    <p className="text-danger">{errors.durationType && errors.surationType.message}</p>
                                                ) : <p>Duration type</p>}

                                                <select
                                                    name="durationType"
                                                    onChange={handleDurationType}
                                                    className={errors.durationType ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Duration Type is required" })}
                                                >
                                                    <option value="Day">Day</option>
                                                    <option value="Month">Month</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Duration */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.duration && errors.duration.message ? (
                                                    <p className="text-danger">{errors.duration && errors.duration.message}</p>
                                                ) : <p>Duration (<small className="text-muted">{durationType}</small>)</p>}

                                                <input
                                                    type="number"
                                                    name="duration"
                                                    placeholder="Enter duration"
                                                    className={errors.currency ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Duration is required",
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "Duration must be in the number."
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Total proposal limit */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.totalProposalLimit && errors.totalProposalLimit.message ? (
                                                    <p className="text-danger">{errors.totalProposalLimit && errors.totalProposalLimit.message}</p>
                                                ) : <p>Total proposal limit</p>}

                                                <input
                                                    type="number"
                                                    name="totalProposalLimit"
                                                    placeholder="Enter limit"
                                                    className={errors.totalProposalLimit ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Total proposal limit is required",
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "Limit must be in the number."
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Contact view limit */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.contactViewLimit && errors.contactViewLimit.message ? (
                                                    <p className="text-danger">{errors.contactViewLimit && errors.contactViewLimit.message}</p>
                                                ) : <p>Contact view limit</p>}

                                                <input
                                                    type="number"
                                                    name="contactViewLimit"
                                                    placeholder="Enter limit"
                                                    className={errors.contactViewLimit ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Contact view limit is required",
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "Limit must be in the number."
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Message limit */}
                                        {/* <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.messageLimit && errors.messageLimit.message ? (
                                                    <p className="text-danger">{errors.messageLimit && errors.messageLimit.message}</p>
                                                ) : <p>Message limit</p>}

                                                <input
                                                    type="number"
                                                    name="messageLimit"
                                                    placeholder="Enter limit"
                                                    className={errors.messageLimit ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Message limit is required",
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "Limit must be in the number."
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div> */}

                                        {/* discountPercentage */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                            {errors.discountPercentage && errors.discountPercentage.message ? (
                                                    <p className="text-danger">{errors.discountPercentage && errors.discountPercentage.message}</p>
                                                ) : <p>Discount Percentage</p>}
                                            

                                                <input
                                                    type="number"
                                                    name="discountPercentage"
                                                    placeholder="Enter discount percentage"
                                                    className={errors.discountPercentage ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Discount Percentage required, ex: you can set 0",
                                                        pattern: {
                                                            value: /^\d+$/,
                                                            message: "Discount must be in the number."
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>


                                        {/* Activation status */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group my-4">
                                                {statusAll.map((item) => (
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        label={item.label}
                                                        type="checkbox"
                                                        value={item.value}
                                                        key={`custom-inline-${item.label}`}
                                                        id={`custom-inline-${item.label}-1`}
                                                        onChange={() => selectStatus(item.value)}
                                                        checked={isActive === item.value}
                                                    />
                                                ))}
                                            </div>
                                        </div>

                                        {/* Image upload & preview Container */}
                                        <div className="col-12">
                                            <div className="d-flex">
                                                <div className="img-container text-center">
                                                    {selectedFile.error ? <small className="text-danger">Image is required*</small> : null}
                                                    <div className="image border">
                                                        <input type="file" className="upload" onChange={handleImage} />
                                                        <div className="flex-center flex-column">
                                                            <Icon icon={plus} size={22} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* image preview */}
                                                {isPreview ?
                                                    <div className="img-container text-center">
                                                        <div className="image border">
                                                            <img src={isPreview} className="img-fluid" alt="..." />
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                        <div className="col-12 text-right">
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Creating...' : 'Create'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Create;