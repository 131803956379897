import React, { useState } from 'react'
import Icon from 'react-icons-kit'
import HtmlParser from 'react-html-parser'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../utils/Requests/User"
import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const About = ({ user, email, handleRefetch }) => {
    const [aboutEdit, setAboutEdit] = useState({ show: false, loading: false })
    const { handleSubmit, register } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const onSubmit = async (data) => {
        setAboutEdit({ ...aboutEdit, loading: true })
        const response = await User.UpdateShortDescription(email, data, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setAboutEdit({ show: false, loading: false })
        handleRefetch()
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">About </h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setAboutEdit({ ...aboutEdit, show: !aboutEdit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        aboutEdit.show ? 
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group mb-4">
                                <textarea
                                    type="text"
                                    className="form-control shadow-none"
                                    placeholder="Enter bio"
                                    defaultValue={user.shortDescription}
                                    name='description'
                                    ref={register({ required: true })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={aboutEdit.loading}
                                >
                                    {aboutEdit.loading ? 'Saving...' : 'Save '}
                                </button>
                            </div>
                        </form>
                        :
                        <p className="text-justify">{user.shortDescription ? HtmlParser(user.shortDescription) : "Not available"}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default About;