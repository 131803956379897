import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import Icon from 'react-icons-kit'
import { api } from '../../utils/api'
import { NavLink, useHistory } from 'react-router-dom'
import {
    ic_dashboard,
    ic_people,
    ic_account_box,
    ic_keyboard_arrow_right,
    ic_work
} from 'react-icons-kit/md'
import { standby } from 'react-icons-kit/iconic'
import Navbar from '../navbar/Index'

const Layout2 = () => {
    const history = useHistory()
    const [show, setShow] = useState(false)
    const [isMenu, setMenu] = useState(false)
    const [loggingOut, setLoggingOut] = useState(false)
    const [notifications] = useState([])
    const [messages] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })

    // Toggle menu
    const toggleMenu = event => {
        let current = event.target.getAttribute("data-value")

        if (isMenu === current) {
            setMenu(false)
        } else {
            setMenu(current)
        }
    }

    // Logout
    const doLogout = async () => {
        try {
            setLoggingOut(true)
            const response = await Axios.get(`${api}admin/auth/logout`, header)
            if (response.status === 200) {
                localStorage.clear()
                setTimeout(() => {
                    history.push('/')
                }, 2000)
            }
        } catch (error) {
            if (error) {
                localStorage.clear()
                setTimeout(() => {
                    history.push('/')
                }, 2000)
            }
        }
    }

    return (
        <div className='layout'>
            <div className="navbar-container shadow-sm">
                {notifications && messages ? <Navbar notifications={notifications} messages={messages} toggle={() => setShow(!show)} /> : null}
            </div>
            <div className={show ? "backdrop d-lg-none open-backdrop" : "backdrop d-lg-none"} onClick={() => setShow(false)}></div>
            <div className="sidebar-container">
                <div className={show ? "sidebar shadow open-sidebar" : "sidebar shadow"}>
                    <ul>

                        {/* Dashboard */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_dashboard} size={20} />Dashboard</NavLink>
                        </li>
                        {/* Accounts Links */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="accounts"
                                >
                                    <Icon icon={ic_account_box} size={20} />Accounts
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'accounts' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'accounts' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to="/dashboard/accounts/sales"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Package Sales</NavLink>
                                </div>
                            </div>
                        </li>
                        {/* Users */}
                        <li>
                            <NavLink
                                exact
                                to={`/dashboard/users?page=1&limit=20`}
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_people} size={20} />Users</NavLink>
                        </li>
                        {/* Marketing */}
                        <li>
                            <div className="sidebar-dropdown-container">
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    onClick={toggleMenu}
                                    data-value="marketing"
                                >
                                    <Icon icon={ic_work} size={20} />Marketing
                                    <Icon icon={ic_keyboard_arrow_right} size={25} className={isMenu === 'marketing' ? "arrow down" : "arrow"} />
                                </button>

                                <div className={isMenu === 'marketing' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                    <NavLink
                                        exact
                                        to={`/dashboard/marketing/sms?page=1&limit=50`}
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >SMS</NavLink>
                                    <NavLink
                                        exact
                                        to={`/dashboard/marketing/email?page=1&limit=50`}
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >E-mail</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/marketing/push-notification"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Push Notification</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/marketing/web-ad"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Web Ad</NavLink>
                                    <NavLink
                                        exact
                                        to="/dashboard/marketing/birthday-wish"
                                        activeClassName="isActive"
                                        type="button"
                                        className="btn shadow-none"
                                    >Birthday Wish</NavLink>
                                </div>
                            </div>
                        </li>
                        {/* Dashboard */}
                        <li>
                            <NavLink
                                exact
                                to="/dashboard/manager-notice-board"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><Icon icon={ic_dashboard} size={20} />Notice Board</NavLink>
                        </li>
                        {/* Logout */}
                        <li className='mt-auto'>
                            <button
                                type="button"
                                className="btn shadow-none"
                                onClick={doLogout}
                                disabled={loggingOut}
                            >
                                <Icon icon={standby} size={15} />
                                {loggingOut ? <span>Logging out...</span> : <span>Logout</span>}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Layout2;