import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { refreshCcw } from 'react-icons-kit/feather'
import Requests from '../../utils/Requests/Index'
import { dateFormate } from '../../utils/helpers'
import DeleteComponent from '../modal/delete/Index'
import { Link } from 'react-router-dom'

const Addsense = ({ items, refetch }) => {
    const [isDelete, setDelete] = useState({ value: null, status: null, loading: null })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Handle delete
    const handleDelete = async id => {
        setDelete({ ...isDelete, loading: true })
        await Requests.Addsense.Delete(id, header)
        refetch()
        setDelete({ value: null, status: null, loading: null })
    }

    return (
        <div className="content-image-table-container pb-4">
            <table className="table table-hover table-responsive-sm table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center">SL</td>
                        <td>Last Update</td>
                        <td>Image</td>
                        <td>For Page</td>
                        <td className="text-center">Update</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.map((item, i) =>
                        <tr className="border-bottom" key={i}>
                            <td className="text-center sl-td">{i + 1}</td>
                            <td>{dateFormate(item.updatedAt)}</td>
                            <td><img src={item.addsenseImage} className="img-fluid" alt="..." /></td>
                            <td>{item.page}</td>
                            <td className="button-td text-center">
                                {/* <button
                                    type="button"
                                    className="btn btn-sm shadow-none table-trash-btn"
                                    onClick={() => setDelete({ ...isDelete, value: item, status: true })}
                                >
                                    <Icon icon={trash} size={20} />
                                </button> */}

                                <Link
                                    type="button"
                                    className="btn btn-sm shadow-none table-trash-btn mx-2"
                                    to={`/dashboard/marketing/web-ad/update/${item._id}`}
                                >
                                    <Icon icon={refreshCcw} size={20} />
                                </Link>

                            </td>
                        </tr>
                    )}
                </tbody>
            </table>


            {/* Delete modal */}
            {isDelete.value && isDelete.status ?
                <DeleteComponent
                    title={'Addsense'}
                    image={isDelete.value.image}
                    data={isDelete.value._id}
                    show={isDelete.status}
                    loading={isDelete.loading}
                    delete={handleDelete}
                    onHide={() => setDelete({ ...isDelete, value: null, status: false })}
                />
                : null}

        </div>
    );
};

export default Addsense;