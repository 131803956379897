import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}/addsense/index`, header)
        if (response.status === 200) {
            return response.data.addsenseData
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// gei by id
const GetById = async (id, header) => {
    try {
        const response = await Axios.get(`${api}/addsense/${id}`, header)
        if (response.status === 200) {
            return response.data.addsenseData
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Store item
const Store = async (data, header) => {
    try {
        const response = await Axios.post(`${api}/addsense/create`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update item
const Update = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}/addsense/update/${id}`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Delete item
const Delete = async (id, header) => {
    try {
        const response = await Axios.delete(`${api}/addsense/delete/${id}`, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Addsense = {
    Index,
    GetById,
    Store,
    Update,
    Delete
}

export default Addsense