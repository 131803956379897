import React, { useCallback, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Icon from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/feather'
import Requests from '../../../../utils/Requests/Index'
import { dateFormate } from '../../../../utils/helpers'

import LoadingComponent from '../../../../components/loading/Index'

const Show = () => {
    const { id } = useParams()
    const [isLoading, setLoading] = useState(true)
    const [item, setItem] = useState(null)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.PagePremium.Show(id, header)
        if (data) {
            setItem(data)
            setLoading(false)
        }
        setLoading(false)
    }, [id, header])


    useEffect(() => {
        fetchData()
    }, [id, header, fetchData])


    if (isLoading) return <LoadingComponent />

    return (
        <div className="premium-story pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0">Show Premium</h6></div>
                                    <div className="ml-auto">
                                        <Link
                                            to="/dashboard/website/premium"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={chevronLeft} size={22} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-4">

                                <table className="table table-md table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>how_it_works</td>
                                            <td>{item.how_it_works || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_consultants</td>
                                            <td>{item.vip_consultants || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_matches</td>
                                            <td>{item.vip_matches || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_privacy</td>
                                            <td>{item.vip_privacy || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_review_title</td>
                                            <td>{item.vip_review_title || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_review_sub_title</td>
                                            <td>{item.vip_review_sub_title || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_review</td>
                                            <td>{item.vip_review || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>vip_review_image</td>
                                            <td>{item.vip_review_image ?
                                            <img src={item.vip_review_image} className="img-fluid" alt="..." /> 
                                            : 'N/A'}</td>
                                        </tr>

                                    </tbody>
                                </table>


                                <br />

                                {item.admin ?
                                    <div className="text-right">
                                        <h6><i>Created by</i></h6>
                                        <p className="mb-0">{item.admin.name}</p>
                                        <p className="mb-0">{item.admin.email}</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;