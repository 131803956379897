import React, { useState } from 'react'
// import './style.scss'
import Axios from 'axios'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import { useHistory } from 'react-router-dom'

toast.configure({ autoClose: 2000 })
const GhotokAccount = () => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoding] = useState(false)
    const [profileImage, setProfileImage] = useState(null);
    const [NIDImage, setNIDImage] = useState(null);
    const [anyBillCopy, setAnyBillCopyImage] = useState('');
    // const [password, setPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    
    const history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })


    // Submit Form
    const onSubmit = async (data) => {
        try {
            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("fbPageUrl", data.fbPageUrl);
            formData.append("email", data.email);
            formData.append("whatsappOrImo", data.whatsappOrImo);
            formData.append("phone", data.phone);
            formData.append("gender", data.gender);
            formData.append("mobileNumberFor", data.mobileNumberFor);
            formData.append("address", data.address);
            formData.append("referenceWay", data.referenceWay);
            formData.append("mobileBankingNumber", data.mobileBankingNumber);
            formData.append("password", data.password);
            formData.append("NIDNo", data.NIDNo);
            formData.append("profileImage", profileImage);
            formData.append("NIDImage", NIDImage);
            formData.append("anyBillCopy", anyBillCopy);
            // mutate(data,header)
            if (data.password!== data.confirmPassword) {
                setErrorMessage("Passwords don't match");
            } else {
                setLoding(true)
                const response = await Axios.post(`${api}ghotok/account/create`, formData, header)
                if (response.status === 201) {
                    let Id = response.data.GhotokId;
                    
                    setLoding(false)
                    history.push(`/dashboard/agents/${Id}/phone-verified`)
                }

            }
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    // if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Create Agents</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            onClick={goToPreviousPath}
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* Name */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.name && errors.name.message ? (
                                                    <p className="text-danger">{errors.name && errors.name.message}</p>
                                                ) : <p>Name</p>}

                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Name is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Facebook */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.fbPageUrl && errors.fbPageUrl.message ? (
                                                    <p className="text-danger">{errors.fbPageUrl && errors.fbPageUrl.message}</p>
                                                ) : <p>Facebook Id</p>}

                                                <input
                                                    type="text"
                                                    name="fbPageUrl"
                                                    placeholder="Enter Facebook Id"
                                                    className={errors.fbPageUrl ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Facebook Id is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Email */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.email && errors.email.message ? (
                                                    <p className="text-danger">{errors.email && errors.email.message}</p>
                                                ) : <p>Email</p>}

                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    className={errors.email ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Email is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* WhatsApp Or Imo*/}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.whatsappOrImo && errors.whatsappOrImo.message ? (
                                                    <p className="text-danger">{errors.whatsappOrImo && errors.whatsappOrImo.message}</p>
                                                ) : <p>WhatsApp Or Imo</p>}

                                                <input
                                                    type="text"
                                                    name="whatsappOrImo"
                                                    placeholder="Enter WhatsApp Or Imo Number"
                                                    className={errors.whatsappOrImo ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "WhatsApp Or Imo Number is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Phone Number*/}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.phone && errors.phone.message ? (
                                                    <p className="text-danger">{errors.phone && errors.phone.message}</p>
                                                ) : <p>Phone Number</p>}

                                                <input
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Enter Phone Number"
                                                    className={errors.phone ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Phone is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Address */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.address && errors.address.message ? (
                                                    <p className="text-danger">{errors.address && errors.address.message}</p>
                                                ) : <p>Address</p>}

                                                <input
                                                    type="text"
                                                    name="address"
                                                    placeholder="Enter Address"
                                                    className={errors.address ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Address is required" })}
                                                />
                                            </div>
                                        </div>

                                         {/* Gender */}
                                         <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.gender && errors.gender.message ? (
                                                    <p className="text-danger">{errors.gender && errors.gender.message}</p>
                                                ) : <p>Gender</p>}

                                                <select
                                                    name="gender"
                                                    className={errors.gender ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Gender is required" })}
                                                >
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div> 
                                        </div> 

                                        {/*Reference Way*/}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.referenceWay && errors.referenceWay.message ? (
                                                    <p className="text-danger">{errors.referenceWay && errors.referenceWay.message}</p>
                                                ) : <p>Reference Way</p>}

                                                <input
                                                    type="text"
                                                    name="referenceWay"
                                                    placeholder="Enter Reference Way"
                                                    className={errors.referenceWay ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Reference Way is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/*Mobile Banking Informations*/}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.mobileBankingNumber && errors.mobileBankingNumber.message ? (
                                                    <p className="text-danger">{errors.mobileBankingNumber && errors.mobileBankingNumber.message}</p>
                                                ) : <p>Mobile Banking Informations</p>}

                                                <input
                                                    type="text"
                                                    name="mobileBankingNumber"
                                                    placeholder="Enter Mobile Banking Informations"
                                                    className={errors.mobileBankingNumber ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Mobile Banking Informations is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Phone Number For */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.mobileNumberFor && errors.mobileNumberFor.message ? (
                                                    <p className="text-danger">{errors.mobileNumberFor && errors.mobileNumberFor.message}</p>
                                                ) : <p>Phone Number For</p>}

                                                <select
                                                    name="mobileNumberFor"
                                                    className={errors.mobileNumberFor ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Phone Number is required" })}
                                                >
                                                    <option value="Bkash">Bkash</option>
                                                    <option value="Nagad">Nagad</option>
                                                    <option value="Rocket">Rocket</option>
                                                </select>
                                            </div> 
                                        </div> 

                                        {/* Password */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.password && errors.password.message ? (
                                                    <p className="text-danger">{errors.password && errors.password.message}</p>
                                                ) : <p>Password</p>}

                                                <input
                                                    type="text"
                                                    name="password"
                                                    placeholder="Enter password"
                                                    className={errors.password ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Password is required",
                                                        // minLength: 8
                                                    })}
                                                />
                                            </div>
                                        </div>

                                       {/* Confirm Password */}
                                       <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.password && errors.password.message ? (
                                                    <p className="text-danger">{errors.password && errors.password.message}</p>
                                                ) : <p>Confirm Password</p>}

                                                <input
                                                    type="text"
                                                    name="confirmPassword"
                                                    placeholder="Enter Confirm Password"
                                                    className={errors.password ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({
                                                        required: "Confirm Password is required",
                                                        // minLength: 8
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* NID Number */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.NIDNo && errors.NIDNo.message ? (
                                                    <p className="text-danger">{errors.NIDNo && errors.NIDNo.message}</p>
                                                ) : <p>NID Number</p>}

                                                <input
                                                    type="text"
                                                    name="NIDNo"
                                                    placeholder="Enter NID Number"
                                                    className={errors.NIDNo ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "NID Number is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Profile Image */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.profileImage && errors.profileImage.message ? (
                                                    <p className="text-danger">{errors.profileImage && errors.profileImage.message}</p>
                                                ) : <p>Profile Image</p>}

                                                <input
                                                    type="file"
                                                    id="profileImage"
                                                    name="profileImage"
                                                    placeholder="Enter profile image"
                                                    className={errors.profileImage ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Profile Image is required" })}
                                                    onChange={(e) => setProfileImage(e.target.files[0])}
                                                />
                                            </div>
                                        </div>

                                        {/* NID Photo*/}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.NIDImage && errors.NIDImage.message ? (
                                                    <p className="text-danger">{errors.NIDImage && errors.NIDImage.message}</p>
                                                ) : <p>NID Image</p>}

                                                <input
                                                    type="file"
                                                    id="NIDImage"
                                                    name="NIDImage"
                                                    placeholder="Enter NID Image"
                                                    className={errors.NIDImage ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    onChange={(e) => setNIDImage(e.target.files[0])}
                                                    ref={register({ required: "NID Image is required" })}
                                                />
                                            </div>
                                        </div>

                                        {/* Any Bill Copy Image */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.anyBillCopy && errors.anyBillCopy.message ? (
                                                    <p className="text-danger">{errors.anyBillCopy && errors.anyBillCopy.message}</p>
                                                ) : <p>Any Bill Copy Image </p>}

                                                <input
                                                    type="file"
                                                    id="anyBillCopy"
                                                    name="anyBillCopy"
                                                    placeholder="Enter Any Bill Copy Image"
                                                    className={errors.anyBillCopy ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "Any Bill Copy Image is required" })}
                                                    onChange={(e) => setAnyBillCopyImage(e.target.files[0])}
                                                />
                                            </div>
                                        </div>

                                        


                                        <div className="col-12 text-right">
                                        <div><span style={{ color: "red" }}>{errorMessage}</span></div>
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Creating...' : 'Create'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GhotokAccount;