import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link, useParams } from 'react-router-dom'
import { chevronLeft } from 'react-icons-kit/feather'

import Requests from '../../../../utils/Requests/Index'
import { dateFormate, StringShort } from '../../../../utils/helpers'
import LoadingComponent from '../../../../components/loading/Index'

const Show = () => {
    const { id } = useParams()
    const [item, setItem] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const itemResult = await Requests.PackageSales.Show(id, header)
            if (itemResult) setItem(itemResult[0])
            setLoading(false)
        } catch (error) {
            if (error) setLoading(false)
        }
    }, [header, id])

    useEffect(() => {
        fetchData()
    }, [header, id, fetchData])

    if (isLoading) return <LoadingComponent />

    return (
        <div className="sales-show pb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header text-right p-3 p-lg-4 bg-white">
                                <Link
                                    to="/dashboard/accounts/sales"
                                    type="button"
                                    className="btn shadow-none rounded-circle"
                                >
                                    <Icon icon={chevronLeft} size={22} />
                                </Link>
                            </div>
                            <div className="card-body p-4">
                                <div className="d-sm-flex coupon-profile-container">
                                    {/* Name circle */}
                                    <div className="name-circle rounded-circle flex-center flex-column">
                                        <h1>{StringShort(item.userId.name)}</h1>
                                    </div>

                                    {/* Content container */}
                                    <div className="flex-fill content-container pt-sm-3 pl-sm-4">
                                        <table className="table-sm">
                                            <tbody>
                                                <tr>
                                                    <td className="title-td">Name</td>
                                                    <td>: {item.userId.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="title-td">Email</td>
                                                    <td className="text-lowercase">: {item.userId.email}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row coupon-items-container">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white border-0">
                                <h6 className="mb-0">Package Info.</h6>
                            </div>
                            <div className="card-body">
                                <div className="d-flex">
                                    <div style={{ width: 200 }}>
                                        <p>Transaction Date</p>
                                        <p>Transaction ID</p>
                                        <p>Bank Transaction ID</p>
                                        <p>Name</p>
                                        <p>Type</p>
                                        <p>Amount</p>
                                        {/* <p>Payment Org.</p> */}
                                        <p>Payment method</p>
                                        <p>Payment details</p>
                                        <p>Comment</p>
                                        <p>Expire Date</p>
                                        <p>Status</p>
                                    </div>
                                    <div>
                                        <p>: {dateFormate(item.createdAt)}</p>
                                        <p>: {item.transactionId}</p>
                                        <p>: {item.bankTransactionId}</p>
                                        <p>: {item.packageId.title}</p>
                                        <p>: {item.packageId.type}</p>
                                        <p>: {item.amount} {item.currency}</p>
                                        {/* <p>: {item.cardIssuer}</p> */}
                                        <p>: {item.issuer}</p>
                                        <p>: {item.paymentDetails ? item.paymentDetails : "--"}</p>
                                        <p>: {item.comment ? item.comment : "--"}</p>
                                        <p>: {dateFormate(item.endDate)}</p>
                                        <p>: {item.status}</p>
                                    </div>
                                </div>
                            </div>

                            {item.admin ?
                                <div className="card-footer bg-white text-right border-0">
                                    <p className="mb-1" style={{ fontSize: 14 }}><i><b>Created By</b></i></p>

                                    <div>
                                        <p className="text-capitalize mb-0" style={{ fontSize: 13 }}>{item.admin.name}</p>
                                        <small><i>{item.admin.email}</i></small>
                                    </div>
                                </div>
                                : null}
                        </div>
                        
                        {
                            item.file
                                ? <object width="100%" height="400px" data={`${item.file}`}></object>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;