import React from 'react'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { x } from 'react-icons-kit/feather'
import { dateFormate } from '../../../utils/helpers'

const Create = (props) => {
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal"
            >
                <Modal.Header className="p-4">
                    <div className="d-flex w-100">
                        <div>
                            <Modal.Title>Notice</Modal.Title>
                        </div>
                        <div className="ml-auto">
                            <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                                <Icon icon={x} size={22} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <p style={styles.textXSM} className="text-muted">{dateFormate(props.data.createdAt)}</p>
                    <p style={styles.textXSM} className="text-muted">Announced by <span className="text-info">{props.data.admin.name}</span></p>
                    <p style={styles.textXSM} className="text-muted">
                        {props.data.priority ? <span className="text-danger">High</span> : <span className="text-primary">Low</span>} Priority
                    </p>
                    <p style={styles.textXSM} className="text-muted">To : <span className="text-info">{props.data.to? props.data.to.name : 'All'}</span></p>
                    <h6 className="text-capitalize my-3" style={styles.textMd}>{props.data.title}</h6>
                    <p className="mb-3" style={styles.textSm}>{props.data.description}</p>
                    
                    <h6 style={styles.textMd}>Notice For ( <span style={styles.textSm} className="text-capitalize">{props.data.noticeFor}</span> )</h6>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Create;
const styles = {
    textMd: {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: '5px'
    },
    textSm: {
        fontSize: 13,
        fontWeight: 400
    },
    textXSM: {
        fontSize: 12,
        margin: 0,
        lineHeight: '18px'
    }
}