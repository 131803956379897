import React, { useState, useEffect } from 'react'
import Icon from 'react-icons-kit'
import Axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom'
import { api } from "../../../../../utils/api";
import { chevronsRight } from 'react-icons-kit/feather'
import ProfileCardList from '../../../../../components/users/profileCardList/Index'
import GhostProfileCard from '../../../../../components/ghostLoader/user/profileCardListLoader/Index'
toast.configure({ autoClose: 2000 });

const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const { id } = useParams()
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })
    const getUsers = async () => {
        try {
            const response = await Axios.get(
                `${api}account/favoritelist/${id}`,
                header
            );

            setLoading(false);

            return response.data.users;

        } catch (error) {
            if (error) {
                toast.warn(error.message);
            }
        }
    };
    const queryData = useQuery("users", getUsers, {
        retry: false,
        cacheTime: 600000,
        refetchOnWindowFocus: false,
        enabled: Boolean(isLoading),
    });


    // If loading to get data
    if (queryData.isLoading) return <GhostProfileCard />;
    return (
        <div>
            <div className="title-container pl-1">
                <div className="d-flex">
                    <div>
                        <Icon icon={chevronsRight} size={25} />
                    </div>
                    <div><h5 className="mb-3">Favourite Profiles</h5></div>
                </div>
            </div>
            {isLoading ?
                <GhostProfileCard /> :
                <ProfileCardList
                    users={queryData.data}
                    header={header}
                    refetch={getUsers}
                />
            }
        </div>
    );
}

export default Index;