import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import User from "../../../../utils/Requests/User"
import SingleSelect from '../../../form/SingleSelect'
import { optionContext } from '../../../../utils/Context/optionContext'
import { HealthInfo } from "../../../OptionsData/HealthInfo/HealthInfo"
import { Diet } from "../../../OptionsData/Diet/Diet"

// import './style.scss'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

const Others = ({ user, email, handleRefetch }) => {
    const { options, handleOptionToData } = useContext(optionContext)
    const [Edit, setEdit] = useState({ show: false, loading: false })
    const { handleSubmit } = useForm()
    const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })

    const [bloodGroup, setBloodGroup] = useState({
        value: null,
        options: []
    })

    const [healthInformation, setHealthInformation] = useState({
        value: null,
        options: HealthInfo
    })

    const [diet, setDiet] = useState({
        value: null,
        options: Diet
    })

    useEffect(() => {
        setBloodGroup({ ...bloodGroup, options: handleOptionToData("Blood Group") })
    }, [options])

    const onSubmit = async () => {
        let obj = {
            diet: diet.value,
            healthInformation: healthInformation.value,
            bloodGroup: bloodGroup.value
        }
        setEdit({ ...Edit, loading: true })
        const response = await User.UpdatePartnerOthers(email, obj, header)
        if (response.status) {
            toast.success('Successfully saved your Bio')
        }
        setEdit({ show: false, loading: false })
        handleRefetch()
    }

    return (
        <div className="detailInformations" >
            <div className="card shadow mb-4" >
                <div className="card-header px-4 pt-3 pb-0">
                    <div className="d-flex">
                        <div><h5 className="mb-0">Partner others</h5></div>
                        <div className="ml-auto">
                            <button className="btn rounded-circle shadow"
                                onClick={() => setEdit({ ...Edit, show: !Edit.show })}
                            >
                                <Icon icon={edit2} size={20} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    {
                        Edit.show ? <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group mb-4">
                                <p>Blood Group</p>
                                <SingleSelect
                                    options={bloodGroup.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.bloodGroup
                                        && user.partnerPreference.bloodGroup.length
                                        ? user.partnerPreference.bloodGroup[0]
                                        : ""}
                                    value={(data) => setBloodGroup({ ...bloodGroup, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Health Information</p>
                                <SingleSelect
                                    options={healthInformation.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.healthInformation
                                        && user.partnerPreference.healthInformation.length
                                        ? user.partnerPreference.healthInformation[0]
                                        : ''}
                                    value={(data) => setHealthInformation({ ...healthInformation, value: data })}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <p>Diet</p>
                                <SingleSelect
                                    options={diet.options}
                                    data={user.partnerPreference
                                        && user.partnerPreference.diet
                                        && user.partnerPreference.diet.length
                                        ? user.partnerPreference.diet[0]
                                        : ''}
                                    value={(data) => setDiet({ ...diet, value: data })}
                                />
                            </div>

                            <div className="text-right pt-3">
                                <button
                                    type="submit"
                                    className="btn shadow-none save-btn"
                                    disabled={Edit.loading}
                                >
                                    {Edit.loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                            :
                            <div className="row" >
                                <div className=" col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Profile created by</th>
                                                <td className="pl-5">:</td>
                                                <td>self</td>
                                            </tr>
                                            <tr>
                                                <th>Blood Group</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.bloodGroup
                                                    && user.partnerPreference.bloodGroup.length
                                                    ? user.partnerPreference.bloodGroup[0]
                                                    : 'Dosent matter'}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Diet</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.diet
                                                    && user.partnerPreference.diet.length
                                                    ? user.partnerPreference.diet[0]
                                                    : 'Dosent matter'}</td>
                                            </tr>
                                            <tr>
                                                <th>Health Information</th>
                                                <td className="pl-5">:</td>
                                                <td>{user.partnerPreference
                                                    && user.partnerPreference.healthInformation
                                                    && user.partnerPreference.healthInformation.length
                                                    ? user.partnerPreference.healthInformation[0]
                                                    : 'Dosent matter'}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Others;