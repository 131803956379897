import React, { useState } from 'react'
// import './style.scss'
import Axios from 'axios'
// import { useMutation } from 'react-query'
import { Icon } from 'react-icons-kit'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import { chevronLeft } from 'react-icons-kit/feather'
import { useHistory, useParams } from 'react-router-dom'

toast.configure({ autoClose: 2000 })
const OTP = () => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoding] = useState(false)
    const { id } = useParams()
    const history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })


    // Submit Form
    const onSubmit = async (data) => {
        try {
            let otp = {otp: data.otp}
            // mutate(data,header)
                setLoding(true)
                const response = await Axios.put(`${api}ghotok/${id}/otp`, otp, header)
                if (response.status === 200) {
                    setLoding(false)
                    history.push('/dashboard/agents/pending')
                }

            
        } catch (error) {
            if (error) {
                toast.error(error.response.data.message)
            }
        }
    }

    // if (isError) return 'Loading'

    return (
        <div className="create-membership-package">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding mb-2">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white p-md-4">
                                <div className="d-flex">
                                    <div><h5 className="mt-2 mb-0">Phone verified</h5></div>
                                    <div className="ml-auto">
                                        <Link
                                            onClick={goToPreviousPath}
                                            type="button"
                                            className="btn shadow-none back-btn"
                                        ><Icon icon={chevronLeft} size={18} />Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-md-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* OTP */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group mb-4">
                                                {errors.otp && errors.otp.message ? (
                                                    <p className="text-danger">{errors.otp && errors.otp.message}</p>
                                                ) : <p>OTP</p>}

                                                <input
                                                    type="text"
                                                    name="otp"
                                                    placeholder="Enter otp"
                                                    className={errors.name ? "form-control shadow-none error" : "form-control shadow-none"}
                                                    ref={register({ required: "OTP is required" })}
                                                />
                                            </div>
                                        </div>


                                      <div className="col-6 text-right">
                                            <button type="submit" className="btn shadow-none text-white" disabled={isLoading}>{isLoading ? 'Submit...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OTP;