import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}banner`, header)
        if (response.status === 200) {
            return response.data.bannerData
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Search an item
const Search = async (data, header) => {
    try {
        const response = await Axios.post(`${api}income/search`, data, header)
        if (response.status === 200) {
            return response.data.results
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


const Income = {
    Index,
    Search
}

export default Income