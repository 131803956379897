import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import "./style.scss";
import queryString from 'query-string'
import { api } from '../../utils/api';
import Axios from 'axios';

import Select from 'react-select'
import Icon from 'react-icons-kit'
import { search, loader } from 'react-icons-kit/feather'
import DateRangePicker from "rsuite/DateRangePicker";


const UserFilterForm = ({ submitSearch, onChangeClearSearch, handleRange, handleRangeClean, defaultValueHandler, handleOrderBy, handleProfileType, handleMaterialStatus, handleWorkStatus, handleQualification, handleGender, handleStatus, handleOnlineStatus, searching }) => {
    const { register, handleSubmit, errors } = useForm()
    const [workingWith, setWorkingWith] = useState(null)
    const [qualification, setQualification] = useState(null)
    const location = useLocation()
    const query = queryString.parse(location.search)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    })

    // Get working and qualifications options
    const getOptions = useCallback(async () => {
        const res = await Axios.get(`${api}settingFields`, header)
        if (res.status === 200) {
            let option = res.data.settingFiled
            for (let i = 0; i < option.length; i++) {
                if (option[i].label === 'Working with') {
                    setWorkingWith(option[i].value)
                }
                else if (option[i].label === 'Qualification') {
                    setQualification(option[i].value)
                }
            }
        }
    }, [])

    useEffect(() => {
        getOptions()
    }, getOptions)

    // Order by options
    const orderByOptions = [
        { value: '1', label: 'ASC' },
        { value: '-1', label: 'DESC' }
    ]

    // Profile type options
    const profileTypeOptions = [
        { value: 'Free', label: 'Free' },
        { value: 'Paid', label: 'Paid' },
        { value: 'null', label: 'All' },
    ]

    // Material status options
    const materialStatusOptions = [
        { value: 'never married', label: 'Never Married' },
        { value: 'divorced', label: 'Divorced' },
        { value: 'annulled', label: 'Annulled' },
        { value: 'widowed', label: 'Widowed' },
        { value: 'married', label: 'Married' },
        { value: 'null', label: 'All' },

    ]

    // Work status options
    const workStatusOptions = [
        { value: 'employee', label: 'Employee' },
        { value: 'non Employee', label: 'Non Employee' },
        { value: 'own Business', label: 'Own Business' },
        { value: 'student', label: 'Student' },
    ]

    // Gender options
    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'null', label: 'All' }
    ]

    // Status options
    const profileStatusOptions = [
        { value: "activated", label: "Activated" },
        { value: "deactivated", label: "Deactivated" },
        { value: "null", label: "All" },
    ];

    // Active Status options
    const onlineStatusOptions = [
        { value: "online", label: "On-Line" },
        { value: "offline", label: "Off-Line" },
        { value: "null", label: "All" },
    ];
    return (
        <>
            {/* User filter section */}
            <div className="justify-content-end filter-section">

                {/* Search */}
                <div className="filter-item">
                    <div className="item-body">
                        <span>Search users</span>
                        <form onSubmit={handleSubmit(submitSearch)}>
                            <div className="form-group m-0">
                                <input
                                    type="text"
                                    name="query"
                                    className={errors.query ? "form-control rounded-0 shadow-sm border-danger" : "form-control rounded-0 shadow-sm"}
                                    placeholder="Search"
                                    ref={register({ required: true })}
                                    disabled={searching}
                                    onChange={onChangeClearSearch}
                                />
                                <Icon icon={searching ? loader : search} className={searching ? "icon spin primary-color" : "icon"} size={18} />
                            </div>
                        </form>
                    </div>
                </div>

                {/* Filter by date range */}
                <div className="filter-item">
                    <div className="item-body shadow-sm rangepicker">
                        <span>Filter by Date</span>
                        <div className="form-group m-0">
                            <DateRangePicker
                                onChange={handleRange}
                                onClean={handleRangeClean}
                                block
                            />
                        </div>
                    </div>
                </div>

                {/* Order By */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Order By</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={orderByOptions}
                            defaultValue={defaultValueHandler(query.orderBy, orderByOptions)}
                            onChange={(event) => handleOrderBy(event.value)}
                        />
                    </div>
                </div>

                {/* Profile Type */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Profile Type</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={profileTypeOptions}
                            defaultValue={defaultValueHandler(query.profileType, profileTypeOptions)}
                            onChange={(event) => handleProfileType(event.value)}
                        />
                    </div>
                </div>

                {/* Material status */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Material Status</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={materialStatusOptions}
                            defaultValue={defaultValueHandler(query.materialStatus, materialStatusOptions)}
                            onChange={(event) => handleMaterialStatus(event.value)}
                        />
                    </div>
                </div>

                {/* Work status */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Working Status</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={workingWith}
                            defaultValue={defaultValueHandler(query.workStatus, workStatusOptions)}
                            onChange={(event) => handleWorkStatus(event.value)}
                        />
                    </div>
                </div>

                {/* Qualifications */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Qualification</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={qualification}
                            defaultValue={defaultValueHandler(query.workStatus, qualification)}
                            onChange={(event) => handleQualification(event.value)}
                        />
                    </div>
                </div>

                {/* Gender */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Gender</span>
                        <Select
                            classNamePrefix="custom-select"
                            styles={customStyles}
                            placeholder={'Select'}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={genderOptions}
                            defaultValue={defaultValueHandler(query.gender, genderOptions)}
                            onChange={(event) => handleGender(event.value)}
                        />
                    </div>
                </div>

                {/* Status  */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Profile Status</span>
                        <Select
                            classNamePrefix="custome-select"
                            styles={customStyles}
                            placeholder={"Select"}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            options={profileStatusOptions}
                            defaultValue={defaultValueHandler(
                                query.status,
                                profileStatusOptions
                            )}
                            onChange={(event) => handleStatus(event.value)}
                        />
                    </div>
                </div>

                {/* online Status  */}
                <div className="filter-item">
                    <div className="item-body shadow-sm">
                        <span>Active Status</span>
                        <Select
                            classNamePrefix="custome-select"
                            styles={customStyles}
                            placeholder={"Select"}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            options={onlineStatusOptions}
                            defaultValue={defaultValueHandler(
                                query.status,
                                onlineStatusOptions
                            )}
                            onChange={(event) => handleOnlineStatus(event.value)}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

export default UserFilterForm;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: '#000',
        minWidth: 170,
        boxShadow: 'none', '&:hover': { borderColor: '1px solid #ced4da' },
        border: state.isFocused ? '1px solid #dfdfdf' : '1px solid #ced4da',
        borderRadius: 0
    })
}