import React, { useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'
import Requests from '../../utils/Requests/Index'
import DeleteComponent from '../modal/delete/Index'
import { eye, edit2, trash } from 'react-icons-kit/feather'

const PagePremium = ({ items, refetch }) => {
    const [isDelete, setDelete] = useState({ value: null, show: false, loading: false })
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const handleDelete = async id => {
        setDelete({ ...isDelete, loading: true })
        await Requests.PagePremium.Delete(id, header)
        refetch()
        setDelete({ value: null, status: null, loading: null })
    }

    return (
        <div className="content-image-table-container pb-4">
            <table className="table table-hover table-responsive-sm table-borderless">
                <thead>
                    <tr className="border-bottom">
                    <td className="text-center sl-td">SL</td>
                        <td>Image</td>
                        <td>VIP Review Title</td>      
                        <td>How it Works</td>
                        <td>Admin</td>
                        <td className="text-td text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom" key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td>
                                    {item.vip_review_image ? <img src={item.vip_review_image} className="img-fluid" alt="..." /> : 'N/A'}
                                </td>
                                <td className="text-td">{item.vip_review_title ? item.vip_review_title : 'N/A'}</td>
                                <td className="text-td">{item.how_it_works ? item.how_it_works.slice(0, 150) + "....." : 'N/A'}</td>
                                <td className="text-td">
                                    {item.admin ? <p className="text-capitalize mb-0">{item.admin.name}</p> : 'N/A'}
                                    {item.admin ? <small className="text-lowercase mb-0"><i>{item.admin.email}</i></small> : 'N/A'}
                                </td>
                                <td className="button-td text-center">
                                    <Link
                                        type="button"
                                        to={`/dashboard/website/premium/${item._id}/show`}
                                        className="btn btn-sm shadow-none table-show-btn"
                                    >
                                        <Icon icon={eye} size={20} />
                                    </Link>
                                    <Link
                                        type="button"
                                        to={`/dashboard/website/premium/${item._id}/edit`}
                                        className="btn btn-sm shadow-none table-edit-btn"
                                    >
                                        <Icon icon={edit2} size={20} />
                                    </Link>
                                    {/* <button
                                        type="button"
                                        className="btn btn-sm shadow-none table-trash-btn"
                                        onClick={() => setDelete({ value: item, show: true, loading: false })}
                                    >
                                        <Icon icon={trash} size={20} />
                                    </button> */}
                                </td>
                            </tr>
                        ) : null}
                </tbody>
            </table>

            {/* Delete modal */}
            {isDelete.value && isDelete.show ?
                <DeleteComponent
                    title={'Page premium'}
                    image={isDelete.value.image}
                    data={isDelete.value._id}
                    show={isDelete.show}
                    loading={isDelete.loading}
                    delete={handleDelete}
                    onHide={() => setDelete({ ...isDelete, value: null, status: false })}
                />
                : null}

        </div>
    )
};

export default PagePremium;