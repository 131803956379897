import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/account/index`, header)
        if (response.status === 200) {
            return response.data.GhotokMediaList
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Approved Ghotok Index
const ApprovedGhotokIndex = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/index/approved`, header)
        if (response.status === 200) {
            return response.data.GhotokList
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
//Reject Ghotok
const RejectGhotok = async (id,header ) => {
    const res = await Axios.put(`${api}ghotok/${id}/reject`, header)
        if(res.status === 201){
            return true 
        }
}
// Search an item
const ApprovedGhotokSearch = async (data, header) => {
    try {
        const response = await Axios.get(`${api}ghotok/approved`, data, header)
        if (response.status === 200) {
            return response.data.results
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Pending Ghotok Index
const PendingGhotokIndex = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/index/pending`, header)
        if (response.status === 200) {
            return response.data.GhotokList
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Reject Ghotok Index
const RejectGhotokIndex = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/index/reject`, header)
        if (response.status === 200) {
            return response.data.GhotokList
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// Update specific item
const Update = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/contentupdate`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update Profile item image
const UpdateProfileImage = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/profile/imageupdate`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update NID item image
const UpdateNIDImage = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/nid/imageupdate`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update AnyBillCopy item image
const UpdateAnyBillCopyImage = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/${id}/anybillcopy/imageupdate`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const withdrawAgentSearch = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/withdraw/search`, header)
        if (response.status === 200) {
            return response.data.WithdrawList
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

const Story = {
    Index,
    ApprovedGhotokIndex,
    RejectGhotokIndex,
    ApprovedGhotokSearch,
    PendingGhotokIndex,
    RejectGhotok,
    Update,
    UpdateProfileImage,
    UpdateNIDImage,
    UpdateAnyBillCopyImage,
    withdrawAgentSearch
   
}

export default Story