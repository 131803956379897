import React from 'react'
import './style.scss'

const SettingsField = ({ items }) => {
    return (
        <div className="content-image-table-container pb-4">
            <table className="table table-hover table-responsive-sm table-borderless">
                <thead>
                    <tr className="border-bottom">
                        <td className="text-center">SL</td>
                        <td>Title</td>
                        <td>Created by</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length ?
                        items.map((item, i) =>
                            <tr className="border-bottom" key={i}>
                                <td className="text-center sl-td">{i + 1}</td>
                                <td>{item.title}</td>
                                <td>
                                    {item.admin ? <p className="text-capitalize mb-0">{item.admin.name}</p> : null}
                                    {item.admin ? <small className="text-lowercase mb-0"><i>{item.admin.email}</i></small> : null}
                                </td>
                            </tr>
                        ) : null}
                </tbody>
            </table>
        </div>
    );
};

export default SettingsField;