import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

const Index = () => {
    const refs = useRef()
    return (
        <div className="list-loader-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding pb-0">
                        <div className="card border-0 shadow-sm">
                            <div className="card-body p-xl-4">
                                
                                <div className="flex-fill px-2" ref={refs}>
                                    <Skeleton height={160} width={refs.innerWidth} />
                                </div>

                                <div className="row mt-5">
                                    {[...Array(4).keys()].map((i) => {
                                        return (
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={i} ref={refs}>
                                                <div className="mb-4">
                                                    <Skeleton height={5} width={refs.innerWidth} />
                                                    <Skeleton height={5} width={220} />
                                                    <Skeleton height={5} width={refs.innerWidth} />
                                                    <Skeleton height={5} width={220} />
                                                </div>
                                                <div className="mb-4">
                                                    <Skeleton height={5} width={refs.innerWidth} />
                                                    <Skeleton height={5} width={220} />
                                                    <Skeleton height={5} width={refs.innerWidth} />
                                                    <Skeleton height={5} width={220} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Index;