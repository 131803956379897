import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import Requests from '../../../utils/Requests/Index'
import LoadingComponent from '../../../components/loading/Index'
import SearchComponent from '../../../components/search/Index'
import PendingGhotokTable from '../../../components/table/ghotok/PackageSell'
toast.configure({ autoClose: 2000 })
const Index = () => {
    const [items, setItems] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [searching, setSearching] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    // Fetch data
    const fetchData = useCallback(async () => {
        const data = await Requests.PackageSales.PackageSellIndex(header)
        if (data) setItems(data)
        if (!data) setItems([])
        setLoading(false)
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])

    // Handle search
    const handleSearch = async data => {
        try {
            setSearching(true)
        
            const response = await Axios.get(`${api}ghotok/packagesell/search?query=${data.query}`, header)
            
            if (response.data.status === true)
            {
                setItems(response.data.Results)
            }
            if (response.status === 404){
                setItems()
            }
            setSearching(false)
        } catch (error) {
            if (error) setSearching(false)
        }
    }
    // const { data, isError } = useQuery("GhotokList", data, {
    //     retry: false,
    //     cacheTime: 600000
    // })
    if (isLoading) return <LoadingComponent />
    return (
        <div className="income-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header p-3 p-lg-4 bg-white">
                                <div className="d-flex">
                                    <div><h6 className="mb-0 d-inline">All Sell Packages</h6></div>
                                    <div className="ml-auto pr-2">
                                        <SearchComponent
                                            placeholder="Phone or agent id"
                                            search={handleSearch}
                                            loading={searching}
                                            clear={fetchData}
                                        />
                                    </div>
                                    {/* <div>
                                        <Link
                                            to="/dashboard/agents/transection"
                                            type="button"
                                            className="btn shadow-none rounded-circle"
                                        >
                                            <Icon icon={plus} size={22} />
                                        </Link>
                                    </div> */}
                                </div>
                            </div>

                            {/* Data table component for show data */}
                            <div className="card-body p-0">
                                <PendingGhotokTable items={items} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;