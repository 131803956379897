import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { api } from '../../../../utils/api'
import LoadingComponent from '../../../../components/loading/Index'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import NumberFormat from 'react-number-format';
import { DateSpliter } from '../../../../utils/helpers'
import DateRangePicker from "rsuite/DateRangePicker"
import queryString from "query-string"

const Index = () => {
    const history = useHistory();
    const location = useLocation()
    const [pageReload, setPageReload] = useState(true)
    const query = queryString.parse(location.search)

    const [queryParams, setQueryParams] = useState({
        date: query.date || null
    });
    // const user = useSelector(state => state.user.userData)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const resultToUser = async () => {
        const res = await Axios.get(`${api}account/index/${location.search}`, header)
        setPageReload(false)
        return res.data && res.data.output
    }

    const { data, isLoading, isError } = useQuery("users", resultToUser, {
        retry: false,
        cacheTime: 600000,
        enabled: Boolean(pageReload),
    })

    const handleRangeClean = (e) => {
        e.preventDefault();
        setQueryParams({ ...queryParams, date: null })
        setPageReload(true);
        history.push(`/dashboard/accounts/account?date=null`)
    }

    const handleDate = (data) => {
        if (data) {
            let from = DateSpliter(data[0])
            let to = DateSpliter(data[1])
            setQueryParams({ ...queryParams, date: `${from}~${to}` })
            setPageReload(true)
            history.push(`/dashboard/accounts/account?date=${`${from}~${to}`}`)
        }
    }

    if (!data) return <LoadingComponent />
    if (isLoading) return <LoadingComponent />
    if (isError) return 'Something went wrong'

    return (
        <div className="ghotok-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className='card border-0 shadow-sm'>
                            <div className="d-lg-flex">
                                <div className="flex-fill border-0 px-lg-4 mb-4 mb-xl-0 activity-card">

                                    <div className="justify-content-end filter-section">
                                        {/* Filter by date range */}
                                        <div className="filter-item">
                                            <div className="item-body shadow-sm rangepicker">
                                                <span>Filter by Date</span>
                                                <div className="form-group m-0">
                                                    <DateRangePicker
                                                        onChange={handleDate}
                                                        onClean={handleRangeClean}
                                                        block
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body px-0 ">
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=male`)}>
                                                <h6>Total package sold</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.soldCount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    /></h4>
                                                <span className="angle"
                                                />
                                            </div>
                                        </div><div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=male`)}>
                                                <h6>Total amount sold</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.totalSell}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle"
                                                />
                                            </div>
                                        </div><div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=male`)}>
                                                <h6>Package sold by Manager</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.manager && data.manager.soldCount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                </h4>
                                                <span className="angle"
                                                />
                                            </div>
                                        </div><div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=male`)}>
                                                <h6>Amount sold by Manager</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.manager && data.manager.totalSell}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle"
                                                />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=male`)}>
                                                <h6>Package sold by Agent</h6>
                                                <h4>{data && data.agent && data.agent.soldCount}</h4>
                                                <span className="angle"
                                                />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50&dateRange=null&status=null&assignTo=null&orderBy=null&profileType=null&materialStatus=null&workStatus=null&qualification=null&gender=female`)}>
                                                <h6>Amount sold by Agent</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.agent && data.agent.totalSell}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle" />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50`)}>
                                                <h6>Total manager commission</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.manager && data.manager.comission}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle" />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50`)}>
                                                <h6>Total agent commission</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.agent && data.agent.comission}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle" />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50`)}>
                                                <h6>Total comission expences</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.agent && data.agent.comission + data && data.manager && data.manager.comission}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle" />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow" type="button" onClick={() => history.push(`/dashboard/users?page=1&limit=50`)}>
                                                <h6>Total Expense</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.expense}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle" />
                                            </div>
                                        </div>
                                        <div className="content-card">
                                            <div className="body shadow">
                                                <h6>Cash in handle</h6>
                                                <h4>
                                                    <NumberFormat
                                                        value={data && data.totalSell - data && data.agent && data.agent.comission - data && data.manager && data.manager.comission - data && data.expense}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        suffix={' TK'}
                                                    />
                                                </h4>
                                                <span className="angle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;