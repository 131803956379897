import Axios from 'axios'
import { api } from '../api'
import { toast } from 'react-toastify'
import { errorHandeller } from './Error'
import 'react-toastify/dist/ReactToastify.css'
toast.configure({ autoClose: 2000 })

// Index of items
const Index = async (data,header) => {
    try {
        const response = await Axios.get(`${api}package/sell${data}`, header)
        if (response.status === 200) {
            return response.data
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Store item
const Store = async (data, header) => {
    try {
        const response = await Axios.post(`${api}package/sell`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Show specific items
const Show = async (id, header) => {
    try {
        const response = await Axios.get(`${api}package/sell/${id}`, header)
        if (response.status === 200) {
            return response.data.invoice
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}

// Update item
const Update = async (id, data, header) => {
    try {
        const response = await Axios.put(`${api}package/sell/${id}`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}


//SurjoRequest
const SurjoRequest = async (data, header) => {
    try {
        const response = await Axios.post(`${api}payment/checkout-request`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
// PackageSell Index
const PackageSellIndex = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/package/sell`, header)
        if (response.status === 200) {
            return response.data.Results
        }
        return []
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}
const PackageSales = {
    Index,
    Store,
    Show,
    Update,
    // SSLRequest,
    PackageSellIndex,
    SurjoRequest
}

export default PackageSales